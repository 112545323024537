import {GeneralState} from '../../types/state';
import {createSlice} from '@reduxjs/toolkit';

const userRoleString = localStorage.getItem('userRole');
const userRole = userRoleString ? parseInt(userRoleString) : 0;

const initialState: GeneralState = {
	license: 'Загрузка...',
	role: userRole
}

const generalSlice = createSlice({
	name: 'general',
	initialState,
	reducers: {
		updateLicense: (state, action) => {
			state.license = action.payload;
		},
		updateRole: (state, action) => {
			state.role = action.payload;
			localStorage.setItem('userRole', action.payload.toString());
		},
		resetRole: (state) => {
			state.role = 0
		}
	}
});

export const {updateLicense, updateRole, resetRole} = generalSlice.actions;
export default generalSlice.reducer;
