import styled from "styled-components";
import {Color, Resolution} from "../../const";
import {ButtonContainer} from "../../components/styled/button-container";
import {Label} from "../../components/styled/label";

type LegendProps = {
	disabled?: boolean
}

type FieldsetProps = {
	width?: string
}

export const CardText = styled.p`
	margin: 30px 16px 12px;

	font-size: 14px;
	line-height: 21px;
	color: ${Color.DarkText};
`;

export const CardButtonContainer = styled(ButtonContainer)`
	padding: 0 16px;
`;

export const CardLabel = styled(Label)`
	margin: -7px 0 -7px;
	width: 135px;

	position: initial;

	& span {
		font-weight: 500;
	}
`;

export const FlexWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 27px;
	padding-left: 12px;
	margin-bottom: 16px;
`;

export const CheckboxWrapper = styled.div`
	width: calc(100% / 2 - 10px);
	display: inline-block;
	margin-right: 6px
`;

export const Separator = styled.svg`
	margin: 12px -14px 16px;
`;

export const Fieldset = styled.fieldset<FieldsetProps>`
	border: none;
	padding: 0;
	margin: 16px 0 0;
	width: ${({width}) => width ? width : 'fit-content'};
	display: inline-block;

	@media (max-width: ${Resolution.LG}) {
		width: 100%;
	}
`;

export const Legend = styled.legend<LegendProps>`
	margin-bottom: 8px;

	font-size: 14px;
	line-height: 20px;
	color: ${Color.DarkText};

	opacity: ${({disabled}) => disabled ? '0.4' : '1'};
`;

export const FormWrapper = styled.form`
	display: flex;
	flex-wrap: wrap;
	gap: 6px 12px;
	width: 1236px;
	max-width: 100%;
`;

export const TitleLegend = styled.legend`
	position: relative;
	top: 26px;

	color: ${Color.DarkText};
	font-size: 14px;
	font-weight: 700;
	line-height: 20px;
`;

export const DialogText = styled.p`
	margin: 16px 0 4px;

	font-size: 14px;
	line-height: 21px;
	color: ${Color.DarkText};
`;
