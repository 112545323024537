import { Company } from "../../types/company";
import { Manager } from "../../types/manager";
import CustomCheckbox from "../custom-checkbox/custom-checkbox";
import { MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { TableList } from "../styled/table-list";
import { AddButton } from "../styled/add-button";
import VisuallyHidden from "../styled/visually-hidden";
import { getElementsByIds } from "../../utils/utils";
import { ActionButton } from "../styled/action-button";
import { ActionsWrapper } from "../styled/action-wrapper";
import { ButtonVariant, Color } from "../../const";
import { GreyWrapper } from "../styled/grey-wrapper";
import CustomAutocomplete from "../custom-autocomplete/custom-autocomplete";
import { ControlsFlexWrapper } from "../styled/controls-flex-wrapper";
import Button from "../styled/button";
import CustomDialog from "../custom-dialog/custom-dialog";
import { Option } from "../../types/option";
import { toast } from "react-toastify";
import { NotificationsForm } from "./style";
import CustomPopover from "../custom-popover/custom-popover";
import { CustomLink } from "../styled/custom-link";
import axios from "axios";
import {
	addManagerToCompany,
	blockManager,
	removeManagerFromCompany,
	removeManager,
	unlockManager,
} from "../../store/managers/manager";

type TableRowManagersProps = {
	manager: Manager;
	index: number;
	companies: Company[] | null | undefined;
};

function TableRowManagers({
	manager,
	index,
	companies
}: TableRowManagersProps): JSX.Element {
	const { id, isBlocked, fullName, companyIds, isChecked } = manager;
	const dispatch = useDispatch<AppDispatch>();
	const [isAddCompanyOpen, setIsAddCompanyOpen] = useState(false);
	const [isAcceptBlockManagerOpen, setIsAcceptBlockManagerOpen] =
		useState(false);
	const [isAcceptRemoveManagerOpen, setIsAcceptRemoveManagerOpen] =
		useState(false);
	const [isAcceptRemoveComponyOpen, setIsAcceptRemoveComponyOpen] =
		useState(false);
	const [idCompanyToAdd, setIdCompanyToAdd] = useState(0);
	const [idCompanyToRemove, setIdCompanyToRemove] = useState(0);
	const [companyToAdd, setCompanyToAdd] = useState<Option | null>(null);
	const [notificationsAnchor, setNotificationsAnchor] =
		useState<HTMLButtonElement | null>(null);
	const [isCompaniesListOpen, setIsCompaniesListOpen] = useState(false);

	const managerCompanies = getElementsByIds<Company>(companyIds, companies);
	const allCompanyIds = companies?.map((company) => company.id);
	const companyIdsOutOfManager = allCompanyIds
		?.filter((id) => !companyIds.includes(id))
		.concat(companyIds.filter((id) => !allCompanyIds.includes(id)));
	const companyOptions = companyIdsOutOfManager?.map((id) => {
		return {
			value: String(id),
			label: {
				main: String(
					companies?.find((company) => company.id === id)?.name
				),
				extra: String(
					companies?.find((company) => company.id === id)?.innerNumber
				),
			},
		};
	});

	/*function checkboxChangeHandler() {
		dispatch(setIsChecked({id, isChecked: !isChecked}));
	}*/

	const addCompanyClickHandler = async (
		evt: MouseEvent<HTMLButtonElement>
	) => {
		evt.preventDefault();

		try {
			const response = await axios.post("/spa/companies/attach", {
				user_id: idCompanyToAdd,
				company_id: companyToAdd?.value,
			});
			dispatch(
				addManagerToCompany({
					managerId: idCompanyToAdd,
					companyId: Number(companyToAdd?.value),
				})
			);
			setIsAddCompanyOpen(false);
			setCompanyToAdd(null);
			toast("Менеджер успешно добавлен в компанию", { type: "success" });
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	};

	const removeCompany = async (evt: MouseEvent<HTMLButtonElement>) => {
		evt.preventDefault();
		try {
			const result = await axios.post("/spa/companies/detach", {
				company_id: idCompanyToRemove,
				user_id: id,
			});
			dispatch(
				removeManagerFromCompany({
					managerId: id,
					companyId: idCompanyToRemove,
				})
			);
			setIsAcceptRemoveComponyOpen(false);
			toast("Менеджер успешно удален из компанию", { type: "success" });
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	};

	const deleteManager = async (
		evt: MouseEvent<HTMLButtonElement>,
		managerId: number
	) => {
		evt.preventDefault();
		try {
			const result = await axios.delete(`/spa/users/${managerId}/delete`);
			dispatch(removeManager(managerId));
			setIsAcceptRemoveManagerOpen(false);
			setCompanyToAdd(null);
			toast("Менеджер успешно удален", { type: "success" });
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	};

	async function blockManagerClickHandler() {
		try {
			const result = await axios.post(`/spa/users/${id}/block`);
			dispatch(blockManager(id));
			setIsAcceptBlockManagerOpen(false);
			toast(
				isChecked
					? "Выбранные менеджеры успешно заблокированы"
					: "Менеджер успешно заблокирован",
				{ type: "success" }
			);
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	}

	async function unlockManagerClickHandler() {
		try {
			const result = await axios.post(`/spa/users/${id}/unblock`);
			dispatch(unlockManager(id));
			setIsAcceptBlockManagerOpen(false);
			toast(
				isChecked
					? "Выбранные менеджеры успешно разблокированы"
					: "Менеджер успешно разблокирован",
				{ type: "success" }
			);
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	}

	// function removeManagerClickHandler() {
	// 	dispatch(resetManager(id));
	// 	dispatch(removeManager(id));

	// 	setIsAcceptRemoveManagerOpen(false);
	// 	toast(
	// 		isChecked ? 'Выбранные менеджеры успешно удалены' : 'Менеджер успешно удалён',
	// 		{type: 'success'}
	// 	);
	// }

	function sendNotificationClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		setNotificationsAnchor(null);
		toast("Уведомления не отправлены", { type: "error" });
	}

	function renderCompanies(managerCompanies: (Company | undefined)[]) {
		return managerCompanies.map((company) => (
			<li key={company?.id}>
				{company?.name}

				<ActionButton
					type="button"
					title="Удалить"
					aria-label="Удалить"
					marginLeft="auto"
					color={Color.DarkText}
					onClick={() => {
						if (company) {
							setIdCompanyToRemove(company?.id);
						}
						setIsAcceptRemoveComponyOpen(true);
					}}
				>
					<svg width="10" height="10" viewBox="0 0 10 10">
						<use xlinkHref="/img/sprite.svg#cross"></use>
					</svg>
				</ActionButton>
			</li>
		));
	}

	return (
		<>
			<tr key={id} className={isBlocked ? "blocked" : ""}>
				{/*<td className="checkbox">
					<CustomCheckbox
						ariaLabel="Выбрать менеджера"
						checked={isChecked}
						onChange={checkboxChangeHandler}
					/>
				</td>*/}

				<td>{index}</td>
				<td className="table-name">{fullName}</td>

				<td className="table-companies">
					<CustomLink
						as="button"
						type="button"
						onClick={() => setIsCompaniesListOpen(true)}
					>
						Компании: {companyIds.length}
					</CustomLink>
				</td>

				<td className="table-actions">
					<ActionsWrapper>
						{/*<ActionButton
							type="button"
							title="Уведомления"
							onClick={(evt) => setNotificationsAnchor(evt.currentTarget)}
						>
							<svg width="17" height="17" viewBox="0 0 17 17">
								<use xlinkHref="/img/sprite.svg#envelope"></use>
							</svg>
						</ActionButton>*/}

						<ActionButton
							type="button"
							title={
								manager.isBlocked
									? "Разблокировать"
									: "Заблокировать"
							}
							color={manager.isBlocked ? Color.Orange : ""}
							onClick={() => setIsAcceptBlockManagerOpen(true)}
						>
							<svg width="17" height="17" viewBox="0 0 17 17">
								<use xlinkHref="/img/sprite.svg#prohibit"></use>
							</svg>
						</ActionButton>

						<ActionButton
							type="button"
							title="Удалить"
							onClick={() => setIsAcceptRemoveManagerOpen(true)}
						>
							<svg width="16" height="16" viewBox="0 0 22 22">
								<use xlinkHref="/img/sprite.svg#remove"></use>
							</svg>
						</ActionButton>
					</ActionsWrapper>
				</td>
			</tr>

			<CustomDialog
				open={isAddCompanyOpen}
				onClose={() => setIsAddCompanyOpen(false)}
				title="Добавить компанию"
			>
				<GreyWrapper as="form">
					{companyOptions?.length ? (
						<>
							<CustomAutocomplete
								id="companies-to-add"
								label="Компании"
								options={companyOptions}
								width="400px"
								widthSm="100%"
								value={companyToAdd}
								onChange={(evt, newValue) =>
									setCompanyToAdd(newValue)
								}
							/>

							<ControlsFlexWrapper>
								<Button
									type="submit"
									variant={ButtonVariant.Main}
									onClick={addCompanyClickHandler}
									disabled={!companyToAdd}
								>
									Добавить
								</Button>
							</ControlsFlexWrapper>
						</>
					) : (
						<p>Нет доступных компаний для добавления</p>
					)}
				</GreyWrapper>
			</CustomDialog>

			<CustomDialog
				open={isAcceptBlockManagerOpen}
				onClose={() => setIsAcceptBlockManagerOpen(false)}
				title={`Вы хотите ${
					isBlocked ? "разблокировать" : "заблокировать"
				} ${isChecked ? "выбранных менеджеров" : "менеджера"}?`}
			>
				<Button
					type="button"
					variant={ButtonVariant.Main}
					onClick={
						isBlocked
							? unlockManagerClickHandler
							: blockManagerClickHandler
					}
				>
					{isBlocked ? "Разблокировать" : "Заблокировать"}
				</Button>
			</CustomDialog>

			<CustomDialog
				open={isAcceptRemoveManagerOpen}
				onClose={() => setIsAcceptRemoveManagerOpen(false)}
				title={`Вы хотите удалить ${
					isChecked ? "выбранных менеджеров" : "менеджера"
				}?`}
			>
				<Button
					type="button"
					variant={ButtonVariant.Main}
					onClick={(evt) => deleteManager(evt, id)}
				>
					Удалить
				</Button>
			</CustomDialog>

			<CustomDialog
				open={isAcceptRemoveComponyOpen}
				onClose={() => setIsAcceptRemoveComponyOpen(false)}
				title={"Вы хотите удалить команию ?"}
			>
				<Button
					type="button"
					variant={ButtonVariant.Main}
					onClick={removeCompany}
				>
					Удалить
				</Button>
			</CustomDialog>

			<CustomDialog
				open={isCompaniesListOpen}
				onClose={() => setIsCompaniesListOpen(false)}
				title="Список компаний"
			>
				<GreyWrapper>
					{!!companyIds.length && (
						<>
							{/* <CustomTextField
								id="company-name"
								label="Компания"
								width="250px"
								marginBottom="24px"
							/> */}

							<TableList>
								{renderCompanies(managerCompanies)}
							</TableList>
						</>
					)}
					{!isBlocked && (
						<AddButton
							type="button"
							title="Добавить"
							onClick={() => {
								setIdCompanyToAdd(id);
								setIsAddCompanyOpen(true);
							}}
						>
							<VisuallyHidden>Добавить</VisuallyHidden>
						</AddButton>
					)}
				</GreyWrapper>
			</CustomDialog>

			<CustomPopover
				id={notificationsAnchor ? "notifications" : undefined}
				open={!!notificationsAnchor}
				anchorEl={notificationsAnchor}
				onClose={() => setNotificationsAnchor(null)}
			>
				<NotificationsForm>
					<CustomCheckbox label="Прибытие ЗТК" />
					<CustomCheckbox label="На СВХ" />
					<CustomCheckbox label="ДТ Подана" />
					<CustomCheckbox label="ДТ Выпущена" />
					<CustomCheckbox label="Убытие ЗТК" />

					<Button
						type="submit"
						variant={ButtonVariant.Main}
						onClick={sendNotificationClickHandler}
					>
						Отправить
					</Button>
				</NotificationsForm>
			</CustomPopover>
		</>
	);
}

export default TableRowManagers;
