import {combineReducers} from '@reduxjs/toolkit';
import general from './general/general';
import user from './user/user';
import companies from './companies/companies';
import employees from './employees/employees';
import managers from './managers/manager';
import vehicles from './vehicles/vehicles';
import orders from './orders/orders';
import vehiclesDocuments from './vehicles/vehiclesDocuments';

export enum NameSpace {
	Companies = 'COMPANIES',
	Employees = 'EMPLOYEES',
	General = 'GENERAL',
	Managers = 'MANAGERS',
	User = 'USER',
	Vehicles = 'VEHICLES',
	VehiclesDocuments = 'VEHICLESDOCUMENTS',
	Orders = 'ORDERS',
}

export const rootReducer = combineReducers({
	[NameSpace.Companies]: companies,
	[NameSpace.Employees]: employees,
	[NameSpace.Managers]: managers,
	[NameSpace.General]: general,
	[NameSpace.User]: user,
	[NameSpace.Vehicles]: vehicles,
	[NameSpace.VehiclesDocuments]: vehiclesDocuments,
	[NameSpace.Orders]: orders,
});

export type RootState = ReturnType<typeof rootReducer>;
