import Header from '../../components/header/header';
import Navigation from '../../components/navigation/navigation';
import {Main} from '../../components/styled/main';
import {MainContainer} from '../../components/styled/main-container';
import {DesktopWrapper} from '../../components/styled/desktop-wrapper';
import {MainWrapper} from '../../components/styled/main-wrapper';
import {ScreenTitle} from '../../components/styled/screen-title';
import {AppRoute} from '../../const';
import ScreenControlsEmployees from '../../components/screen-controls-employees/screen-controls-employees';
import TableEmployees from '../../components/table-employees/table-employees';
import Footer from '../../components/footer/footer';
import useTitle from '../../hooks/use-title/use-title';

function EmployeesScreen(): JSX.Element {
	useTitle('Сотрудники | Терминал');

	return <>
		<Header ScreenControls={ScreenControlsEmployees} navigation={<Navigation currentRoute={AppRoute.Employees}/>}/>

		<Main>
			<MainContainer>
				<DesktopWrapper>
					<Navigation currentRoute={AppRoute.Employees}/>
				</DesktopWrapper>

				<MainWrapper>
					<ScreenTitle>Сотрудники</ScreenTitle>

					<DesktopWrapper>
						<ScreenControlsEmployees/>
					</DesktopWrapper>

					<TableEmployees/>
				</MainWrapper>
			</MainContainer>
		</Main>

		<Footer/>
	</>;
}

export default EmployeesScreen;
