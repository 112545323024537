import {muiTheme} from '../../const';
import {Popover} from '@mui/material';
import {ReactNode} from 'react';

type CustomPopoverProps = {
	id: string | undefined,
	open: boolean,
	anchorEl: Element | null,
	onClose: () => void,
	children: ReactNode
}

function CustomPopover({id, open, anchorEl, onClose, children}: CustomPopoverProps): JSX.Element {
	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
			sx={{
				'& .MuiPopover-paper': {
					border: '1px solid rgba(171, 167, 175, 0.64)',
					borderRadius: '5px',
					boxShadow: 'none',
					[muiTheme.breakpoints.up('sm')]: {
						transform: 'translateX(-24px) !important'
					}
				}
			}}
		>
			{children}
		</Popover>
	);
}

export default CustomPopover;
