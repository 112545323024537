import {IDocuments, State} from '../../types/state';
import {NameSpace} from '../root-reducer';
import { IVehiclesState } from './vehicles';

export const getVechiles = (state: State): IVehiclesState[] | null | undefined  | null => state[NameSpace.Vehicles].vehicles;
export const getOldVechiles = (state: State): IVehiclesState[] | null | undefined  | null => state[NameSpace.Vehicles].oldVehicles;
export const newRequestsCountVehicles = (state: State): number => state[NameSpace.Vehicles].newVehiclesCount;
export const getDocuments = (state: State): IDocuments[] | null | undefined  | null => state[NameSpace.Vehicles].documents;
export const getSearchNumberCar = (state: State): string => state[NameSpace.Vehicles].searchByNumberCar;
export const getSearchNumberTrailer = (state: State): string => state[NameSpace.Vehicles].searchBynumberTrailer;
export const getSearchStatus = (state: State): string => state[NameSpace.Vehicles].sortBy;
export const getSearchStatusVechiles = (state: State): string => state[NameSpace.Vehicles].status;
export const getAllDocumentsVehicles = (state: State): IDocuments[] | null | undefined => state[NameSpace.Vehicles].documents;
export const getIndexCurrentOpenCurd = (state: State): number | null => state[NameSpace.Vehicles].indexCurrentOpenCart
export const getCurrentCompanyName = (state: State): string => state[NameSpace.Vehicles].currentCompanyName
export const getCurrentCompanyId = (state: State): string => state[NameSpace.Vehicles].currentCompanyId


