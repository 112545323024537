import {Autocomplete, FilterOptionsState, TextField} from '@mui/material';
import {Option} from '../../types/option';
import {matchSorter} from 'match-sorter';
import {Color, muiTheme} from '../../const';

type CustomAutocompleteProps = {
	id: string,
	options: Option[],
	label?: string,
	ariaLabel?: string,
	width?: string,
	widthSm?: string,
	widthMd?: string
	value?: Option | null,
	onChange?: (evt: any, newValue: Option | null) => void,
	marginTop?: string,
	marginTopSm?: string,
	alternateView?: boolean
}

function CustomAutocomplete(props: CustomAutocompleteProps): JSX.Element {
	const {
		id,
		options,
		label,
		ariaLabel,
		width,
		widthSm,
		widthMd,
		value,
		onChange,
		marginTop,
		marginTopSm,
		alternateView
	} = props;
	const filterOptions = (options: Option[], { inputValue }: FilterOptionsState<Option>) =>
		matchSorter(options, inputValue, {keys: ['label.main', 'label.extra']});

	return (
		<Autocomplete
			id={id}
			options={options}
			value={value}
			onChange={onChange}
			filterOptions={filterOptions}
			getOptionLabel={(option) => option.label.main}
			clearText="Очистить"
			closeText="Закрыть"
			openText="Открыть"
			noOptionsText="Список пуст"
			isOptionEqualToValue={(option, value) => option.value === value.value}
			sx={{
				'& fieldset': {
					borderColor: alternateView ? '#FFFFFF' : '#DCDCDC'
				},
				'&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
					borderWidth: '1px',
					borderColor: Color.Orange
				},
				'& .MuiOutlinedInput-root .MuiAutocomplete-input': {
					padding: '3px'
				},
				'& .MuiAutocomplete-inputRoot': {
					fontFamily: 'inherit',
					fontWeight: 'inherit',
					color: alternateView ? '#FFFFFF' : 'inherit',
					fontSize: '14px'
				},
				'& .MuiAutocomplete-popper': {
					fontFamily: 'inherit',
					fontWeight: 'inherit',
					color: 'red',
				},
				flexGrow: alternateView ? '1' : 'initial',
				marginTop: marginTop,
				marginLeft: alternateView ? '36px' : '0',
				marginRight: alternateView ? '36px' : '0',
				[muiTheme.breakpoints.down('sm')]: {
					order: alternateView ? '4' : 'initial',
					flexBasis: alternateView ? '80%' : 'initial',
					marginTop: marginTopSm,
					marginRight: alternateView ? '9px' : '0',
					marginBottom: alternateView ? '9px' : '0',
				},
				[muiTheme.breakpoints.between('sm', 'md')]: {
					marginLeft: alternateView ? '10px' : '0',
					marginRight: alternateView ? '10px': '0'
				}
			}}
			renderInput={(params) =>
				<TextField
					{...params}
					label={label}
					aria-label={ariaLabel}
					InputLabelProps={{
						sx: {
							'&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
								transform: 'translate(12px, 12px)',
							},
							'&.Mui-focused': {
								color: Color.Orange
							},
							fontFamily: 'inherit',
							fontWeight: 'inherit',
							color: 'inherit',
							fontSize: '14px'
						}
					}}
					sx={{
						fontFamily: 'inherit',
						fontWeight: 'inherit',
						color: 'inherit',
						fontSize: '14px',
						backgroundColor: alternateView ? '#F68A59' : '#FFFFFF',
						width: width,
						[muiTheme.breakpoints.down('sm')]: {
							width: widthSm
						},
						[muiTheme.breakpoints.between('sm', 'md')]: {
							width: widthMd
						}
					}}
				/>
			}
			ListboxProps={{
				sx: {
					fontFamily: 'Montserrat, Arial, sans-serif',
					fontWeight: 'inherit',
					color: 'inherit',
					fontSize: '14px',
				}
			}}
		/>
	);
}

export default CustomAutocomplete;
