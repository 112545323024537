import {
	Account,
	AccountName,
	BurgerButton,
	FilterButton,
	HeaderContainer,
	IconButton as HeaderIconButton,
	License,
	LicenseNumber,
	LogoLink,
	StyledHeader,
} from "../../components/header/style";
import {
	AppRoute,
	ButtonVariant,
	HeaderButtonIcon,
	Resolution,
} from "../../const";
import Button from "../../components/styled/button";
import VisuallyHidden from "../../components/styled/visually-hidden";
import { Link, useNavigate } from "react-router-dom";
import { MouseEvent, useEffect, useState } from "react";
import { Main } from "../../components/styled/main";
import { MainContainer } from "../../components/styled/main-container";
import { DesktopWrapper } from "../../components/styled/desktop-wrapper";
import {
	NavigationIcon,
	NavigationLink,
	StyledNavigation,
} from "../../components/navigation/style";
import { ScreenTitle } from "../../components/styled/screen-title";
import { MainWrapper } from "../../components/styled/main-wrapper";
import CustomTextField from "../../components/custom-text-field/custom-text-field";
import { StyledScreenControls } from "../../components/styled/styled-screen-controls";
import { IconButton } from "../../components/styled/icon-button";
import "dayjs/locale/ru";
import CustomDatePicker from "../../components/custom-date-picker/custom-date-picker";
import CustomTimePicker from "../../components/custom-time-picker/custom-time-picker";
import { StyledTable } from "../../components/styled/styled-table";
import Footer from "../../components/footer/footer";
import useTitle from "../../hooks/use-title/use-title";
import { WrapControlsForm } from "../../components/styled/controls-form";
import {
	DateFakeLegend,
	DateFieldset,
} from "../../components/styled/date-fieldset";
import { CustomLink } from "../../components/styled/custom-link";
import { useSelector } from "react-redux";
import { getUserInfo } from "../../store/user/selectors";
import { getLicense } from "../../store/general/selectors";
import axios from "axios";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import {
	IPropsOrder,
	addOrders,
	changeOrderNameCompanyValue,
	changeOrderNumberCarValue,
	incrementNewRequestsCount,
	removeRequestByIndex,
	resetOrderFilters,
} from "../../store/orders/orders";
import {
	getAllOrders,
	getOrderNumberCar,
	getOrdersCompanyName,
	newRequestsCountOrders,
} from "../../store/orders/selector";
import { Loader } from "../../components/Loader/Loader";
import CustomDialog from "../../components/custom-dialog/custom-dialog";
import { GreyWrapper } from "../../components/styled/grey-wrapper";
import { ControlsFlexWrapper } from "../../components/styled/controls-flex-wrapper";
import { updateLicense } from "../../store/general/general";
import { toast } from "react-toastify";
import './orders-screen.css'
import CustomAutocomplete from "../../components/custom-autocomplete/custom-autocomplete";
import { Option } from "../../types/option";
import { ICompaniesState } from "../../store/companies/companies";
import { IEmployeesState } from "../../store/employees/employees";


function OrdersScreen(): JSX.Element {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const license = useSelector(getLicense);
	const [orderType, setOrderType] = useState("Все");
	const [loading, setLoading] = useState(true);
	const userInfo = useSelector(getUserInfo);
	const searchOrderCompanyName = useSelector(getOrdersCompanyName);
	const searchOrderNumberCar = useSelector(getOrderNumberCar);
	const orders = useSelector(getAllOrders);				
	const newRequestsCounts = useSelector(newRequestsCountOrders);
	const [currentOrders, setCurrentOrders] = useState<IPropsOrder[]>([]);
	const [oldOrders, setOldOrders] = useState<IPropsOrder[]>([]);
	useTitle(`${newRequestsCounts.length > 0 ? `(${newRequestsCounts.length})` : ''}` + ' ' + "Заявки от компаний | Терминал");

	const MyClickableToast = (companyName : string) => (
		<div
			style={{ cursor: 'pointer' }}
			onClick={() => {
				navigate('/orders');
			}}
			>
			У вас новая заявка. {companyName}
		</div>
	);

	useEffect(() => {
		setLoading(true);
		const fetchOrders = async () => {
			try {
				const result = orderType === "Все"
					? await axios.get("/spa/orders")
					: await axios.get("/spa/orders", {
						params: {
						type: orderType === "Выгрузка" ? "v" : orderType === "Досмотр" ? "d" : "o,a",
						},
					});
				setOldOrders(result.data.data)
				dispatch(addOrders(result.data.data));
				setLoading(false);

				} catch (error: any) {
				if (error.response && error.response.status === 401) {
						navigate(AppRoute.Login);
					}
				}
		}

		fetchOrders();
	}, [])

	useEffect(() => {

		const fetchOrders = async () => {
			try {
				const result = orderType === "Все"
					? await axios.get("/spa/orders")
					: await axios.get("/spa/orders", {
						params: {
						type: orderType === "Выгрузка" ? "v" : orderType === "Досмотр" ? "d" : "o,a",
						},
					});
				setCurrentOrders(result.data.data);

				const newRequestsCount = currentOrders.length - oldOrders?.length;

				if(currentOrders && newRequestsCount > 0) {
					dispatch(incrementNewRequestsCount(newRequestsCount));
					dispatch(addOrders(result.data.data));
					setOldOrders(result.data.data);
					for (let i = 1; i <= newRequestsCount; i++) {
						toast(MyClickableToast(currentOrders[currentOrders.length - i].company_name), { type: "success", autoClose: false });
					}
				}

				} catch (error: any) {
				if (error.response && error.response.status === 401) {
						navigate(AppRoute.Login);
					}
				}
		}
		const intervalId = setInterval(fetchOrders, 10000);

		return () => {
		clearInterval(intervalId);
		};
	}, [currentOrders])

	useEffect(() => {
		async function fetchLicense() {
			const result = await axios.get("/spa/license").then((response) => {
				dispatch(updateLicense(response.data.data.license));
			}).catch((error: any) => {
				if (error.response) {
					if (
						error.response.status === 401
					) {
						navigate(AppRoute.Login);
					}
				}
			});
		}
		fetchLicense()
	}, []);

	const employee = useSelector(getUserInfo);
	const [companies, setCompanies] = useState<ICompaniesState[] | []>([]);
	const [company, setCompany] = useState<Option | null>({
		value: "",
		label: {
			main: "",
		},
	});
	const [numberCar, setNumberCar] = useState("");
	const [newLicense, setNewLicense] = useState(license);
	const [isEditLicenseOpen, setIsEditLicenseOpen] = useState(false);
	const [selectedTimeFrom, setSelectedTimeFrom] = useState<any>(null);
	const [selectedTimeTo, setSelectedTimeTo] = useState<any>(null);
	const [selectedDateFrom, setSelectedDateFrom] = useState<any>(null);
	const [selectedDateTo, setSelectedDateTo] = useState<any>(null);
	const [user, setUser] = useState<IEmployeesState | null>(null);

	useEffect(() => {
		async function fetchUser(id: number | undefined) {
			const result = await axios
				.get(`/spa/users/${id}`)
				.then((response) => {
					setUser(response.data.data);
					dispatch(changeOrderNameCompanyValue(company?.label.main));
				})
				.catch((error: any) => {
					if (error.response) {
						if (
							error.response.status === 401 ||
							error.response.status === "401"
						) {
							navigate(AppRoute.Login);
						}
					}
				});
		}
		if (employee) {
			fetchUser(employee?.id);
		}
	}, []);

	useEffect(() => {
		if (user) {
			user.companyIds.forEach(async (id, i) => {
				const response = await axios.get(`/spa/companies/${id}`);
				// if(i === 0) {
				// 	setCompany({
				// 		value: String(response.data.data.id),
				// 		label: {
				// 			main: response.data.data.name,
				// 		},
				// 	});
				// }
				setCompanies((prevCompanies) => [
					...prevCompanies,
					response.data.data,
				]);
			});
		}
	}, [user]);

	function sortOrdersByDate(orders: IPropsOrder[] | null | undefined): IPropsOrder[] | null {
		if (orders) {
			const sortedOrderByDate = [...orders].sort((a: IPropsOrder, b: IPropsOrder) => {
			return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
		});
		return sortedOrderByDate;
		} else {
			return null;
		}
	}
	const sortedOrdersDate = sortOrdersByDate(orders);

	const sortedOrders = (
		sortedOrdersDate: IPropsOrder[] | undefined | null
	): IPropsOrder[] | null | undefined => {
		return sortedOrdersDate
			?.filter((item) => {
				if (company?.label.main !== "") {
					return item.company_name.toLowerCase() === company?.label.main.toLowerCase();
				} else {
					return item;
				}
			}).filter((item) => {
				if(orderType !== "Все") {
					return orderType === "Выгрузка" ? item.type === "v" : orderType === "Досмотр" ? item.type === "d" : item.type === "a" || item.type ==='o'
				} else {
					return item
				}
			}).filter((item) => {
				if (searchOrderNumberCar !== "") {
					return item.numbercar
						.toLowerCase()
						.includes(searchOrderNumberCar.toLowerCase()) || item.numbertrailer.toLowerCase().includes(searchOrderNumberCar.toLowerCase());
				} else {
					return item;
				}
			})
			.filter((item) => {
				if (selectedDateFrom !== null && selectedDateTo !== null) {
					return (
						new Date(item.created_at).getTime() >=
							new Date(selectedDateFrom).getTime() &&
						new Date(item.created_at).getTime() <=
							new Date(selectedDateTo).getTime()
					);
				} else {
					return item;
				}
			})
			.filter((item) => {
				if (selectedTimeFrom !== null && selectedTimeTo !== null) {
					const currenttime =
						new Date(item.created_at).getHours() * 60 +
						new Date(item.created_at).getMinutes();
					return (
						currenttime >=
							selectedTimeFrom.$H * 60 + selectedTimeFrom.$m &&
						currenttime <=
							selectedTimeTo.$H * 60 + selectedTimeTo.$m
					);
				} else {
					return item;
				}
			});
	};

	function submitClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();

		dispatch(changeOrderNameCompanyValue(company?.label.main ? company?.label.main : ''));
		dispatch(changeOrderNumberCarValue(numberCar));
	}

	const userView = (e: MouseEvent<HTMLAnchorElement>, index: number): void => {
		
		const trElement = (e.target as HTMLElement).closest('tr');
		if (trElement) {
		trElement.classList.remove('no-view');
		}
		dispatch(removeRequestByIndex(index));

	}

	function handleReset(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		dispatch(resetOrderFilters());
		setCompany({
			value: "",
			label: {
				main: "",
			}
		});
		setOrderType("Все");
		setNumberCar("");
		setSelectedDateFrom(null);
		setSelectedDateTo(null);
		setSelectedTimeFrom(null);
		setSelectedTimeTo(null);
	}

	const createDate = (data: string): string => {
		if (data) {
			const arrivalDate = new Date(data);
			const day = arrivalDate.getDate();
			const month = arrivalDate.getMonth() + 1;
			const year = arrivalDate.getFullYear();

			const formattedDate = `${day < 10 ? "0" + day : day}.${
				month < 10 ? "0" + month : month
			}.${year}`;

			const hours = arrivalDate.getHours();
			const minutes = arrivalDate.getMinutes();
			const formattedTime = `${hours < 10 ? "0" + hours : hours}:${
				minutes < 10 ? "0" + minutes : minutes
			}`;

			return formattedDate + " / " + formattedTime;
		} else {
			return "";
		}
	};

	function editLicenseClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		refreshLicense();
	}

	async function refreshLicense() {
		try {
			const result = await axios.post("/spa/license/create", {
				license: newLicense,
			});
			setNewLicense(result.data.data.license);
			dispatch(updateLicense(newLicense));
			setIsEditLicenseOpen(false);
			toast("Лицензия успешно обновлена", { type: "success" });
		} catch (error) {
			toast("Что-то пошло не так. Перезагрузите страницу", {
				type: "error",
			});
		}
	}

	return (
		<>
			<StyledHeader>
				<HeaderContainer>
					<LogoLink to={AppRoute.Main} aria-label="На главную">
						<picture>
							<source
								media={`(max-width: ${Resolution.SM})`}
								srcSet="/img/logo-mobile.svg"
							/>

							<img
								src="/img/logo.svg"
								width={193}
								height={45}
								alt='Группа компаний "Терминал"'
								onClick={() => setIsEditLicenseOpen(true)}
							/>
						</picture>
					</LogoLink>

					<License>
						Лицензия <LicenseNumber>{license}</LicenseNumber>
					</License>

					<HeaderIconButton
						type="button"
						title="Редактировать"
						icon={HeaderButtonIcon.Edit}
						marginRight="auto"
						onClick={() => setIsEditLicenseOpen(true)}

					>
						<VisuallyHidden>Редактировать</VisuallyHidden>
					</HeaderIconButton>

					<Account marginLeft="auto">
						Менеджер <AccountName>{userInfo?.name}</AccountName>
					</Account>

					<HeaderIconButton
						as={Link}
						to={AppRoute.Logout}
						icon={HeaderButtonIcon.Logout}
						title="Выйти"
					>
						<VisuallyHidden>Выйти</VisuallyHidden>
					</HeaderIconButton>

					<BurgerButton>
						<VisuallyHidden>Открыть меню</VisuallyHidden>
					</BurgerButton>

					<FilterButton>
						<VisuallyHidden>Фильтры</VisuallyHidden>
					</FilterButton>
				</HeaderContainer>
			</StyledHeader>

			<Main>
				<MainContainer>
					<DesktopWrapper>
						<StyledNavigation>
							<NavigationLink to={AppRoute.Orders} $active>
								<NavigationIcon
									width="20"
									height="22"
									viewBox="0 0 20 22"
								>
									<use xlinkHref="/img/sprite.svg#chalkboard-teacher"></use>
								</NavigationIcon>

								Заявки {newRequestsCounts.length > 0 && `(${newRequestsCounts.length})`}
							</NavigationLink>

							<NavigationLink to={AppRoute.Documents}>
								<NavigationIcon
									width="19"
									height="20"
									viewBox="0 0 19 20"
								>
									<use xlinkHref="/img/sprite.svg#download"></use>
								</NavigationIcon>
								Документы
							</NavigationLink>
						</StyledNavigation>
					</DesktopWrapper>

					<MainWrapper>
						<ScreenTitle>Заявки от компаний</ScreenTitle>

						<DesktopWrapper>
							<StyledScreenControls>
								<VisuallyHidden as="h2">
									Управление
								</VisuallyHidden>

								<WrapControlsForm>
									{/* <CustomTextField
										id="company-name"
										label="Название компании"
										width="175px"
										widthSm="100%"
										widthMd="130px"
										marginTopSm="8px"
										value={companyName}
										onChange={(evt) =>
											setCompanyName(evt.target.value)
										}
									/> */}

								<CustomAutocomplete
									id="company"
									label="Компания"
									width="250px"
									widthSm="100%"
									widthMd="130px"
									options={companies.map((item) => {
										return {
											value: String(item.id),
											label: {
												main: item.name,
											},
										};
									})}
									value={company}
									onChange={(evt, newValue) =>
										setCompany(newValue)
									}
								/>

									<CustomTextField
										id="order-type"
										label="Тип заявки"
										width="175px"
										widthSm="100%"
										widthMd="130px"
										marginTopSm="8px"
										select
										value={orderType}
										options={[
											{
												value: "Все",
												label: {
													main: "Все",
												},
											},
											{
												value: "Досмотр",
												label: {
													main: "Досмотр",
												},
											},
											{
												value: "Выгрузка",
												label: {
													main: "Выгрузка",
												},
											},
											{
												value: "Осмотр",
												label: {
													main: "Осмотр",
												},
											},
										]}
										onChange={(evt) =>
											setOrderType(evt.target.value)
										}
									/>

									<CustomTextField
										onChange={(evt) =>
											setNumberCar(evt.target.value)
										}
										id="vehicle-number"
										label="Номер ТС / ДТ"
										width="175px"
										widthSm="100%"
										widthMd="130px"
										marginTopSm="8px"
										value={numberCar}
									/>

									<DateFieldset>
										<VisuallyHidden as="legend">
											Дата
										</VisuallyHidden>
										<DateFakeLegend aria-hidden={true}>
											Дата
										</DateFakeLegend>

										<CustomDatePicker
											value={selectedDateFrom}
											onChange={(newValue) =>
												setSelectedDateFrom(newValue)
											}
											label="От"
											width="131px"
											order={1}
										/>

										<CustomDatePicker
											value={selectedDateTo}
											onChange={(newValue) =>
												setSelectedDateTo(newValue)
											}
											label="До"
											width="131px"
											order={3}
										/>
									</DateFieldset>

									<DateFieldset>
										<VisuallyHidden as="legend">
											Время
										</VisuallyHidden>
										<DateFakeLegend aria-hidden={true}>
											Время
										</DateFakeLegend>

										<CustomTimePicker
											value={selectedTimeFrom}
											onChange={(newValue) =>
												setSelectedTimeFrom(newValue)
											}
											label="От"
											width="91px"
											order={1}
										/>

										<CustomTimePicker
											value={selectedTimeTo}
											onChange={(newValue) =>
												setSelectedTimeTo(newValue)
											}
											label="До"
											width="91px"
											order={3}
										/>
									</DateFieldset>

									<IconButton
										onClick={submitClickHandler}
										type="submit"
										variant={ButtonVariant.Main}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<use xlinkHref="/img/sprite.svg#check-circle"></use>
										</svg>

										<span>Применить</span>
									</IconButton>

									<IconButton
										onClick={handleReset}
										type="reset"
										variant={ButtonVariant.Secondary}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<use xlinkHref="/img/sprite.svg#cross-circle"></use>
										</svg>

										<span>Сбросить</span>
									</IconButton>
								</WrapControlsForm>
							</StyledScreenControls>
						</DesktopWrapper>

						<StyledTable>
							<thead>
								<tr>
									<th>Название заявки</th>
									<th>Дата / Время</th>
									<th>Название компании</th>
									<th>Номер ТС / Номер ДТ</th>
									<th>Статус ТС</th>
								</tr>
							</thead>

							<tbody>
								{loading ? (
									<Loader />
								) : (
									sortedOrders(sortedOrdersDate)?.length !== 0 ? sortedOrders(sortedOrdersDate)?.map((item, index:number) => (
										<tr className={(newRequestsCounts.length > 0 && newRequestsCounts.includes(index + 1)) ? 'no-view' : ''} key={item.id}>
											<td>
												<CustomLink
													onClick={(e) => userView(e, index + 1)}
													href={item.link}
													target="_blank"
													download={
														item.type === "v"
															? `Заявка № ЛК-ВОХ ${item.id}` 
															: item.type === "d" ?  `Заявка № ЛК-ДОХ ${item.id}`: `Заявка № ЛК-ООХ ${item.id}`
													}
												>
													{
														item.type === "v"
															? `Заявка № ЛК-ВОХ ${item.id}` 
															: item.type === "d" ?  `Заявка № ЛК-ДОХ ${item.id}`: `Заявка № ЛК-ООХ ${item.id}`
													}
												</CustomLink>
											</td>
											<td>
												{createDate(item.created_at)}
											</td>
											<td className="table-name">
												{item.company_name}
											</td>
											<td>{item.numbercar && `${item.numbercar} / ${item.numbertrailer}`}</td>
											<td>{item.locationcar}</td>
										</tr>
									)) : (<div>Список пуст</div>)
								)}
							</tbody>
							<CustomDialog
								open={isEditLicenseOpen}
								onClose={() => setIsEditLicenseOpen(false)}
								title="Редактирование лицензии"
							>
								<GreyWrapper as="form">
									<CustomTextField
										id="new-license"
										label="Лицензия"
										width="400px"
										value={newLicense}
										onChange={(evt) =>
											setNewLicense(evt.target.value)
										}
										autoFocus
									/>

									<ControlsFlexWrapper>
										<Button
											type="submit"
											variant={ButtonVariant.Main}
											onClick={editLicenseClickHandler}
											disabled={!newLicense}
										>
											Редактировать
										</Button>
									</ControlsFlexWrapper>
								</GreyWrapper>
							</CustomDialog>
						</StyledTable>
					</MainWrapper>
				</MainContainer>
			</Main>

			<Footer />
		</>
	);
}

export default OrdersScreen;
