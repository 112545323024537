import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDocuments } from "../../types/state";
import { IEmployeesState } from "../employees/employees";

export interface ICompaniesState {
    id: number,
    innerNumber: string,
    employeeIds: number[],
    name: string,
    isBlocked: boolean,
    isChecked: boolean
}

export interface CompaniesInitialState {
    companies: ICompaniesState[] | [],
    employeesForCompany: IEmployeesState[],
    companiesDocuments: IDocuments[],
    searchByINN: string,
    searchByName: string,
    searchByEmployee: string,
    sortBy: string
}

const initialState: CompaniesInitialState = {
    companies: [],
    employeesForCompany: [],
    companiesDocuments: [],
    searchByINN: '',
    searchByName: '',
    searchByEmployee: '',
    sortBy: 'all'
}


const companiesSlice = createSlice({
    name: 'companies',
    initialState,
    reducers: {
        setAllcompanies: (state, action) => {
            state.companies = action.payload
        },
        setAllEmployeesForCompany: (state, action) => {
            state.employeesForCompany = action.payload
        },
        setAllDocuments: (state, action) => {
            state.companiesDocuments = action.payload
        },
        addDocumentForCompany: (state, action: PayloadAction<IDocuments>) => {
            state.companiesDocuments.push(action.payload);
        },
        removeCompany: (state, action: PayloadAction<number>) => {
            state.companies = state.companies.filter(company => company.id !== action.payload);
        },
        removeDocumentFromCompany: (state, action: PayloadAction<string>) => {
            state.companiesDocuments = state.companiesDocuments.filter(document => document.id !== action.payload)
        },
        addEmployeeToCompany: (state, action: PayloadAction<{employeeId: number, companyId: number}>) => {
            const employeeId = action.payload.employeeId;
            const companyId = action.payload.companyId;

            const company = state.companies.find((company) => company.id === companyId);
            if (company) {
                company.employeeIds.push(employeeId);
            }
        },
        removeEmployeeFromCompany: (state, action: PayloadAction<{employeeId: number | undefined, companyId: number}>) => {
            const employeeId = action.payload.employeeId;
            const companyId = action.payload.companyId;

            const company = state.companies.find((company) => company.id === companyId);

            if(company) {
                company.employeeIds = company.employeeIds.filter((id) => id !== employeeId);
            }            
        },
        blockCompany: (state, action) => {
			const company = state.companies?.slice().find((company) => company.id === action.payload);

			if (company) {
				if (company.isChecked) {
					state.companies?.filter((company) => company.isChecked).forEach((company) => company.isBlocked = true);
				} else {
					company.isBlocked = true;
				}
			}
		},
		unlockCompany: (state, action) => {
			const company = state.companies?.slice().find((company) => company.id === action.payload);

			if (company) {
				if (company.isChecked) {
					state.companies?.filter((company) => company.isChecked).forEach((company) => company.isBlocked = false);
				} else {
					company.isBlocked = false;
				}
			}
		},
        updateSearchByINN: (state, action: PayloadAction<string>) => {
            state.searchByINN = action.payload;
        },
        updateSearchByName: (state, action: PayloadAction<string>) => {
        state.searchByName = action.payload;
        },
        updateSearchByEmployee: (state, action: PayloadAction<string>) => {
        state.searchByEmployee = action.payload;
        },
        updateSearchBySort: (state, action: PayloadAction<string>) => {
            state.sortBy = action.payload;
            },
        resetSearchCompany: (state) => {
            state.searchByEmployee = '';
            state.searchByINN = '';
            state.searchByName = '';
            state.sortBy =  'all'
        }
    }
})

export const {
    setAllEmployeesForCompany,
    addDocumentForCompany,
    removeDocumentFromCompany,
    setAllDocuments,
    updateSearchBySort,
    resetSearchCompany,
    updateSearchByINN,
    updateSearchByName,
    updateSearchByEmployee,
    setAllcompanies,
    removeCompany,
    addEmployeeToCompany,
    removeEmployeeFromCompany,
    blockCompany,
    unlockCompany
} = companiesSlice.actions
export default companiesSlice.reducer;