import styled from 'styled-components';
import {Resolution} from '../../const';

export const ActionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 18px;

	@media (max-width: ${Resolution.SM}) {
		flex-direction: row;
	}
`;
