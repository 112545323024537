import { Company } from "../../types/company";
import { getElementsByIds, getSurnameWithInitials } from "../../utils/utils";
import { Employee } from "../../types/employee";
import VisuallyHidden from "../styled/visually-hidden";
import { ButtonVariant, Color } from "../../const";
import { MouseEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import CustomDialog from "../custom-dialog/custom-dialog";
import Button from "../styled/button";
import { toast } from "react-toastify";
import { GreyWrapper } from "../styled/grey-wrapper";
import { ControlsFlexWrapper } from "../styled/controls-flex-wrapper";
import { TableList } from "../styled/table-list";
import { ActionButton } from "../styled/action-button";
import { AddButton } from "../styled/add-button";
import { ActionsWrapper } from "../styled/action-wrapper";
import CustomAutocomplete from "../custom-autocomplete/custom-autocomplete";
import { Option } from "../../types/option";
import axios from "axios";
import {
	addEmployeeToCompany,
	blockCompany,
	removeCompany,
	removeEmployeeFromCompany,
	unlockCompany,
} from "../../store/companies/companies";
import {
	IEmployeesState,
} from "../../store/employees/employees";

type TableRowCompaniesProps = {
	company: Company;
	index: number;
	employees: Employee[] | null | undefined;
};

function TableRowCompanies({
	company,
	index,
	employees,
}: TableRowCompaniesProps): JSX.Element {
	const { id, isBlocked, innerNumber, name, employeeIds, isChecked } =
		company;
	const dispatch = useDispatch<AppDispatch>();
	const [isAcceptRemoveEmployeeOpen, setIsAcceptRemoveEmployeeOpen] =
		useState(false);
	const [currentEmployeeId, setCurrentEmployeeId] = useState<
		number | undefined
	>(0);
	const [isAddEmployeeOpen, setIsAddEmployeeOpen] = useState(false);
	const [employeeToAdd, setEmployeeToAdd] = useState<Option | null>(null);
	const [idCompanyToAdd, setIdCompanyToAdd] = useState(0);
	const [isAcceptBlockCompanyOpen, setIsAcceptBlockCompanyOpen] =
		useState(false);
	const [isAcceptRemoveCompanyOpen, setIsAcceptRemoveCompanyOpen] =
		useState(false);
	

	const allEmployeeIds = employees?.map((employee) => employee.id);
	const employeeIdsOutOfCompany = allEmployeeIds
		?.filter((id) => !employeeIds.includes(id))
		.concat(employeeIds.filter((id) => !allEmployeeIds.includes(id)));

	const employeeOptions = employeeIdsOutOfCompany?.map((id) => {
		return {
			value: String(id),
			label: {
				main: String(
					employees?.find((employee) => employee.id === id)?.fullName
				),
			},
		};
	});

	const companyEmployees = getElementsByIds<IEmployeesState>(
		employeeIds,
		employees
	);

	/*function checkboxChangeHandler() {
		dispatch(setIsChecked({id, isChecked: !isChecked}));
	}*/

	// function removeEmployeeClickHandler(id: number | undefined) {
	// 	if (id) {
	// 		setIsAcceptRemoveEmployeeOpen(true);
	// 		setCurrentEmployeeId(id);
	// 	}
	// }

	async function removeClientFromCompany(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		try {
			const result = await axios.post("/spa/companies/detach", {
				company_id: id,
				user_id: currentEmployeeId,
			});
			dispatch(
				removeEmployeeFromCompany({
					employeeId: currentEmployeeId,
					companyId: id,
				})
			);
			setIsAcceptRemoveEmployeeOpen(false);
			setCurrentEmployeeId(0);
			toast("Сотрудник успешно удалён из компании", { type: "success" });
		} catch (error) {
			toast(`Ой, что-то пошло не так. Перезагрузите страницу`, {
				type: "error",
			});
		}
	}

	
	const deleteCompany = async (
		evt: MouseEvent<HTMLButtonElement>,
		companyId: number
	) => {
		evt.preventDefault();
		try {
			const result = await axios.delete(
				`/spa/companies/${companyId}/delete`
			);
			dispatch(removeCompany(companyId));
			setIsAcceptRemoveCompanyOpen(false);
			setCurrentEmployeeId(0);
			toast("Компания успешно удален", { type: "success" });
		} catch (error) {
			toast(`Ой, что-то пошло не так. Перезагрузите страницу`, {
				type: "error",
			});
		}
	};



	const addEmployeeClickHandler = async (
		evt: MouseEvent<HTMLButtonElement>
	) => {
		evt.preventDefault();

		try {
			const response = await axios.post("/spa/companies/attach", {
				company_id: idCompanyToAdd,
				user_id: employeeToAdd?.value,
			});
			dispatch(
				addEmployeeToCompany({
					companyId: idCompanyToAdd,
					employeeId: Number(employeeToAdd?.value),
				})
			);
			setIsAddEmployeeOpen(false);
			setEmployeeToAdd(null);
			toast("Сотрудник успешно добавлен", { type: "success" });
		} catch (error) {
			toast(`Ой, что-то пошло не так. Перезагрузите страницу`, {
				type: "error",
			});
		}
	};

	// function editCompanyClickHandler(evt: MouseEvent<HTMLButtonElement>) {
	// 	evt.preventDefault();
	// 	dispatch(updateCompany({ companyId: id, newName: newCompanyName }));
	// 	setIsEditCompanyOpen(false);
	// 	toast("Компания успешно отредактирована", { type: "success" });
	// }

	async function blockCompanyClickHandler() {
		try {
			const result = await axios.post(`/spa/companies/${id}/block`);
			dispatch(blockCompany(id));
			setIsAcceptBlockCompanyOpen(false);
			toast(
				isChecked
					? "Выбранные компании успешно заблокированы"
					: "Компания успешно заблокирована",
				{ type: "success" }
			);
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	}

	async function unlockCompanyClickHandler() {
		try {
			const result = await axios.post(`/spa/companies/${id}/unblock`);
			dispatch(unlockCompany(id));
			setIsAcceptBlockCompanyOpen(false);
			toast(
				isChecked
					? "Выбранные компании успешно разблокированы"
					: "Компания успешно разблокирована",
				{ type: "success" }
			);
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	}

	// function removeCompanyClickHandler() {
	// 	dispatch(resetCompany(id));
	// 	dispatch(removeCompany(id));

	// 	companyEmployees.forEach((employee) => {
	// 		dispatch(removeCompanyFromEmployee({companyId: id, employeeId: employee?.id}));
	// 	});

	// 	setIsAcceptRemoveCompanyOpen(false);
	// 	toast(
	// 		isChecked ? 'Выбранные компании успешно удалены' : 'Компания успешно удалена',
	// 		{type: 'success'}
	// 	);
	// }

	function renderEmployees(companyEmployees: any) {
		return companyEmployees?.map((employee: IEmployeesState) => (
			<li key={employee?.id}>
				{getSurnameWithInitials(employee?.fullName)}

				<ActionButton
					type="button"
					title="Удалить"
					aria-label="Удалить"
					marginLeft="auto"
					color={Color.DarkText}
					onClick={() => {
						setCurrentEmployeeId(employee?.id);
						setIsAcceptRemoveEmployeeOpen(true);
					}}
				>
					<svg width="10" height="10" viewBox="0 0 10 10">
						<use xlinkHref="/img/sprite.svg#cross"></use>
					</svg>
				</ActionButton>
			</li>
		));
	}

	return (
		<>
			<tr key={id} className={isBlocked ? "blocked" : ""}>
				{/*<td className="checkbox">
					<CustomCheckbox
						ariaLabel="Выбрать компанию"
						checked={isChecked}
						onChange={checkboxChangeHandler}
					/>
				</td>*/}

				<td>{index}</td>
				<td>{innerNumber}</td>
				<td className="table-name">{name}</td>

				<td className="table-employees">
					{!!employeeIds.length && (
						<TableList>
							{renderEmployees(companyEmployees)}
						</TableList>
					)}
					{!isBlocked && (
						<AddButton
							type="button"
							title="Добавить"
							onClick={() => {
								setIdCompanyToAdd(id);
								setIsAddEmployeeOpen(true);
							}}
						>
							<VisuallyHidden>Добавить</VisuallyHidden>
						</AddButton>
					)}
				</td>

				<td className="table-actions">
					<ActionsWrapper>
						{/*<ActionButton type="button" title="Редактировать" onClick={() => setIsEditCompanyOpen(true)}>
							<svg width="15" height="15" viewBox="0 0 15 15">
								<use xlinkHref="/img/sprite.svg#gear"></use>
							</svg>
						</ActionButton>*/}

						<ActionButton
							type="button"
							title={
								company.isBlocked
									? "Разблокировать"
									: "Заблокировать"
							}
							color={company.isBlocked ? Color.Orange : ""}
							onClick={() => setIsAcceptBlockCompanyOpen(true)}
						>
							<svg width="17" height="17" viewBox="0 0 17 17">
								<use xlinkHref="/img/sprite.svg#prohibit"></use>
							</svg>
						</ActionButton>

						<ActionButton
							type="button"
							title="Удалить"
							onClick={() => setIsAcceptRemoveCompanyOpen(true)}
						>
							<svg width="16" height="16" viewBox="0 0 22 22">
								<use xlinkHref="/img/sprite.svg#remove"></use>
							</svg>
						</ActionButton>
					</ActionsWrapper>
				</td>
			</tr>

			<CustomDialog
				open={isAcceptRemoveEmployeeOpen}
				onClose={() => setIsAcceptRemoveEmployeeOpen(false)}
				title="Вы хотите удалить сотрудника?"
			>
				<Button
					type="button"
					variant={ButtonVariant.Main}
					onClick={removeClientFromCompany}
				>
					Удалить
				</Button>
			</CustomDialog>

			<CustomDialog
				open={isAddEmployeeOpen}
				onClose={() => setIsAddEmployeeOpen(false)}
				title="Добавить сотрудника"
			>
				<GreyWrapper as="form">
					{employeeOptions?.length ? (
						<>
							<CustomAutocomplete
								id="employees-to-add"
								label="Сотрудники"
								options={employeeOptions}
								width="400px"
								widthSm="100%"
								value={employeeToAdd}
								onChange={(evt, newValue) =>
									setEmployeeToAdd(newValue)
								}
							/>

							<ControlsFlexWrapper>
								<Button
									type="submit"
									variant={ButtonVariant.Main}
									onClick={addEmployeeClickHandler}
									disabled={!employeeToAdd}
								>
									Добавить
								</Button>
							</ControlsFlexWrapper>
						</>
					) : (
						<p>Нет доступных сотрудников для добавления</p>
					)}
				</GreyWrapper>
			</CustomDialog>

			{/* <CustomDialog
				open={isEditCompanyOpen}
				onClose={() => setIsEditCompanyOpen(false)}
				title="Редактирование компании"
			>
				<GreyWrapper as="form">
					<CustomTextField
						id="new-company-name"
						label="Название компании"
						width="400px"
						widthSm="100%"
						value={newCompanyName}
						onChange={(evt) => setNewCompanyName(evt.target.value)}
						autoFocus
					/>

					<ControlsFlexWrapper>
						<Button
							type="submit"
							variant={ButtonVariant.Main}
							onClick={editCompanyClickHandler}
							disabled={!newCompanyName}
						>
							Редактировать
						</Button>
					</ControlsFlexWrapper>
				</GreyWrapper>
			</CustomDialog> */}

			<CustomDialog
				open={isAcceptBlockCompanyOpen}
				onClose={() => setIsAcceptBlockCompanyOpen(false)}
				title={`Вы хотите ${
					isBlocked ? "разблокировать" : "заблокировать"
				} ${isChecked ? "выбранные компании" : "компанию"}?`}
			>
				<Button
					type="button"
					variant={ButtonVariant.Main}
					onClick={
						isBlocked
							? unlockCompanyClickHandler
							: blockCompanyClickHandler
					}
				>
					{isBlocked ? "Разблокировать" : "Заблокировать"}
				</Button>
			</CustomDialog>

			<CustomDialog
				open={isAcceptRemoveCompanyOpen}
				onClose={() => setIsAcceptRemoveCompanyOpen(false)}
				title={`Вы хотите удалить ${
					isChecked ? "выбранные компании" : "компанию"
				}?`}
			>
				<Button
					type="button"
					variant={ButtonVariant.Main}
					onClick={(evt) => deleteCompany(evt, id)}
				>
					Удалить
				</Button>
			</CustomDialog>
		</>
	);
}

export default TableRowCompanies;
