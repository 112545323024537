import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider, TimePicker} from '@mui/x-date-pickers';
import {Color, muiTheme} from '../../const';

type CustomTimePickerProps = {
	value?: any,
	label: string,
	width?: string,
	widthLg?: string,
	order?: number,
	marginTop?: string,
	marginLeft?: string,
	marginLeftLg?: string,
	onChange?: (arg:any) => void
}

function CustomTimePicker(props: CustomTimePickerProps): JSX.Element {
	const {value, label, width, widthLg, order, marginTop, marginLeft, marginLeftLg, onChange} = props;

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
			<TimePicker
				value={value}
				label={label}
				onChange={onChange}
				sx={{
					backgroundColor: '#FFFFFF',
					width: width,
					order: order,
					marginTop: marginTop,
					marginLeft: marginLeft,
					'& .MuiInputBase-input': {
						padding: '12px 0 12px 12px',
						fontFamily: 'inherit',
						fontWeight: 'inherit',
						color: 'inherit',
						fontSize: '14px',
					},
					'& fieldset': {
						borderColor: '#DCDCDC'
					},
					'& .Mui-focused .MuiOutlinedInput-notchedOutline': {

						borderWidth: '1px',
						borderColor: Color.Orange
					},
					'& .Mui-focused.MuiFormLabel-root': {
						color: Color.Orange
					},
					'& .MuiFormLabel-root': {
						'&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
							transform: 'translate(12px, 12px)',
						},
						'&.Mui-focused': {
							color: Color.Orange
						},
						fontFamily: 'inherit',
						fontWeight: 'inherit',
						color: 'inherit',
						fontSize: '14px'
					},
					'& .MuiInputBase-root': {
						paddingRight: '12px',
						fontFamily: 'inherit',
						fontWeight: 'inherit',
						color: 'inherit',
						fontSize: '14px'
					},
					'& .MuiInputAdornment-root': {
						marginLeft: '0'
					},
					'& .MuiSvgIcon-root': {
						width: '20px',
						height: '20px',
						color: Color.Orange
					},
					[muiTheme.breakpoints.down('lg')]: {
						width: widthLg,
						marginLeft: marginLeftLg
					}
				}}
			/>
		</LocalizationProvider>
	);
}

export default CustomTimePicker;
