import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { MouseEvent, useState } from "react";
import { toast } from "react-toastify";
import { Employee } from "../../types/employee";
import { getElementsByIds } from "../../utils/utils";
import { ButtonVariant, Color } from "../../const";
import VisuallyHidden from "../styled/visually-hidden";
import CustomDialog from "../custom-dialog/custom-dialog";
import Button from "../styled/button";
import { GreyWrapper } from "../styled/grey-wrapper";
import CustomTextField from "../custom-text-field/custom-text-field";
import { ControlsFlexWrapper } from "../styled/controls-flex-wrapper";
import { Company } from "../../types/company";
import { TableList } from "../styled/table-list";
import { AddButton } from "../styled/add-button";
import { ActionsWrapper } from "../styled/action-wrapper";
import { ActionButton } from "../styled/action-button";
import CustomAutocomplete from "../custom-autocomplete/custom-autocomplete";
import axios from "axios";
import {
	addCompanyToEmployee,
	blockEmployee,
	removeCompanyFromEmployee,
	removeEmployee,
	unlockEmployee,
	updateEmployee,
} from "../../store/employees/employees";
import {
	ICompaniesState,
	addEmployeeToCompany,
} from "../../store/companies/companies";

type TableRowEmployeesProps = {
	employee: Employee;
	index: number;
	companies: ICompaniesState[] | null | undefined;
};
function TableRowEmployees({
	employee,
	index,
	companies
}: TableRowEmployeesProps): JSX.Element {
	const { id, isBlocked, fullName, companyIds, isChecked } = employee;
	const dispatch = useDispatch<AppDispatch>();
	const [isAddCompanyOpen, setIsAddCompanyOpen] = useState(false);
	const [companyToAdd, setCompanyToAdd] = useState<any>(null);
	const [isEditEmployeeOpen, setIsEditEmployeeOpen] = useState(false);
	const [idCompanyToRemove, setIdCompanyToRemove] = useState<
		number | undefined
	>(0);
	const [newFullName, setNewFullName] = useState(fullName);
	const [isAcceptBlockEmployeeOpen, setIsAcceptBlockEmployeeOpen] =
		useState(false);
	const [isAcceptRemoveEmployeeOpen, setIsAcceptRemoveEmployeeOpen] =
		useState(false);
	const [isAcceptRemoveCompanyeOpen, setIsAcceptRemoveCompanyOpen] =
		useState(false);
	const [isSendMessageOpen, setIsSendMessageOpen] = useState(false);
	const [message, setMessage] = useState("");

	const allCompanyIds = companies?.map((company) => company.id);
	const companyIdsOutOfEmployee = allCompanyIds
		?.filter((id) => !companyIds.includes(id))
		.concat(companyIds.filter((id) => !allCompanyIds.includes(id)));
	const companyOptions = companyIdsOutOfEmployee?.map((id) => {
		return {
			value: String(id),
			label: {
				main: String(
					companies?.find((company) => company.id === id)?.name
				),
				extra: String(
					companies?.find((company) => company.id === id)?.innerNumber
				),
			},
		};
	});
	const employeeCompanies = getElementsByIds<Company>(companyIds, companies);

	/*function checkboxChangeHandler() {
		dispatch(setIsChecked({id, isChecked: !isChecked}));
	}*/

	async function deleteCompanyFromEmployee(
		evt: MouseEvent<HTMLButtonElement>
	) {
		evt.preventDefault();
		try {
			const result = await axios.post("/spa/companies/detach", {
				company_id: idCompanyToRemove,
				user_id: id,
			});
			dispatch(
				removeCompanyFromEmployee({
					employeeId: id,
					companyId: Number(idCompanyToRemove),
				})
			);
			setIsAcceptRemoveCompanyOpen(false);
			setIdCompanyToRemove(0);
			toast("Компания успешно удалена из сотрудника", {
				type: "success",
			});
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	}

	const deleteEmployee = async (
		evt: MouseEvent<HTMLButtonElement>,
		userId: number
	) => {
		evt.preventDefault();
		try {
			const result = await axios.delete(`/spa/users/${userId}/delete`);
			dispatch(removeEmployee(userId));
			setIsAcceptRemoveEmployeeOpen(false);
			toast("Сотрудник успешно удален", { type: "success" });
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	};

	async function addCompanyClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		try {
			const response = await axios.post("/spa/companies/attach", {
				company_id: companyToAdd?.value,
				user_id: id,
			});
			dispatch(
				addEmployeeToCompany({
					companyId: Number(companyToAdd?.value),
					employeeId: id,
				})
			);
			dispatch(
				addCompanyToEmployee({
					companyId: Number(companyToAdd?.value),
					employeeId: id,
				})
			);
			setIsAddCompanyOpen(false);
			setCompanyToAdd(null);
			toast("Сотрудник успешно добавлен в компанию", { type: "success" });
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	}

	async function editEmployeeClickHandler(
		evt: MouseEvent<HTMLButtonElement>,
		id: number
	) {
		evt.preventDefault();
		try {
			const result = await axios.post(`/spa/users/${id}`, {
				id: id,
				fullName: newFullName,
				companyIds: companyIds,
				isBlocked: isBlocked,
				isChecked: isChecked,
			});
			dispatch(
				updateEmployee({ emloyeeId: id, newFullName: newFullName })
			);
			setIsEditEmployeeOpen(false);
			toast("Сотрудник успешно отредактирован", { type: "success" });
		} catch (error) {
			toast("Что-то пошло не так. Перезагрузите страницу", {
				type: "error",
			});
		}
	}

	async function blockEmployeeClickHandler() {
		try {
			const result = await axios.post(`/spa/users/${id}/block`);
			dispatch(blockEmployee(id));
			setIsAcceptBlockEmployeeOpen(false);
			toast(
				isChecked
					? "Выбранные сотрудники успешно заблокированы"
					: "Сотрудник успешно заблокирован",
				{ type: "success" }
			);
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	}

	async function unlockEmployeeClickHandler() {
		try {
			const result = await axios.post(`/spa/users/${id}/unblock`);
			dispatch(unlockEmployee(id));
			setIsAcceptBlockEmployeeOpen(false);
			toast(
				isChecked
					? "Выбранные сотрудники успешно разблокированы"
					: "Сотрудник успешно разблокирован",
				{ type: "success" }
			);
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	}

	// function removeEmployeeClickHandler() {
	// 	dispatch(resetEmployee(id));
	// 	dispatch(removeEmployee(id));

	// employeeCompanies.forEach((company) => {
	// 	dispatch(removeEmployeeFromCompany({companyId: company?.id, employeeId: id}));
	// });

	// 	setIsAcceptRemoveEmployeeOpen(false);
	// 	toast(
	// 		isChecked ? 'Выбранные сотрудники успешно удалены' : 'Сотрудник успешно удалён',
	// 		{type: 'success'}
	// 	);
	// }

	function sendMessageClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		setIsSendMessageOpen(false);
		toast("Сообщение не доставлено", { type: "error" });
	}

	function renderCompanies(employeeCompanies: (Company | undefined)[]) {
		return employeeCompanies.map((company) => (
			<li key={company?.id}>
				{company?.name}

				<ActionButton
					type="button"
					title="Удалить"
					aria-label="Удалить"
					marginLeft="auto"
					color={Color.DarkText}
					onClick={() => {
						setIdCompanyToRemove(company?.id);
						setIsAcceptRemoveCompanyOpen(true);
					}}
				>
					<svg width="10" height="10" viewBox="0 0 10 10">
						<use xlinkHref="/img/sprite.svg#cross"></use>
					</svg>
				</ActionButton>
			</li>
		));
	}

	return (
		<>
			<tr key={id} className={isBlocked ? "blocked" : ""}>
				{/*<td className="checkbox">
					<CustomCheckbox
						ariaLabel="Выбрать сотрудника"
						checked={isChecked}
						onChange={checkboxChangeHandler}
					/>
				</td>*/}

				<td>{index}</td>
				<td className="table-name">{fullName}</td>

				<td className="table-companies">
					{!!companyIds.length && (
						<TableList>
							{renderCompanies(employeeCompanies)}
						</TableList>
					)}
					{!isBlocked && (
						<AddButton
							type="button"
							title="Добавить"
							onClick={() => {
								setIsAddCompanyOpen(true);
							}}
						>
							<VisuallyHidden>Добавить</VisuallyHidden>
						</AddButton>
					)}
				</td>

				<td className="table-actions">
					<ActionsWrapper>
						{/*<ActionButton type="button" title="Отправить сообщение" onClick={() => setIsSendMessageOpen(true)}>
							<svg width="17" height="17" viewBox="0 0 17 17">
								<use xlinkHref="/img/sprite.svg#envelope"></use>
							</svg>
						</ActionButton>*/}

						{/* <ActionButton type="button" title="Редактировать" onClick={() => setIsEditEmployeeOpen(true)}>
							<svg width="15" height="15" viewBox="0 0 15 15">
								<use xlinkHref="/img/sprite.svg#gear"></use>
							</svg>
						</ActionButton> */}

						<ActionButton
							type="button"
							title={
								employee.isBlocked
									? "Разблокировать"
									: "Заблокировать"
							}
							color={employee.isBlocked ? Color.Orange : ""}
							onClick={() => setIsAcceptBlockEmployeeOpen(true)}
						>
							<svg width="17" height="17" viewBox="0 0 17 17">
								<use xlinkHref="/img/sprite.svg#prohibit"></use>
							</svg>
						</ActionButton>

						<ActionButton
							type="button"
							title="Удалить"
							onClick={() => setIsAcceptRemoveEmployeeOpen(true)}
						>
							<svg width="16" height="16" viewBox="0 0 22 22">
								<use xlinkHref="/img/sprite.svg#remove"></use>
							</svg>
						</ActionButton>
					</ActionsWrapper>
				</td>
			</tr>

			<CustomDialog
				open={isAddCompanyOpen}
				onClose={() => setIsAddCompanyOpen(false)}
				title="Добавить компанию"
			>
				<GreyWrapper as="form">
					{companyOptions?.length ? (
						<>
							<CustomAutocomplete
								id="companies-to-add"
								label="Компании"
								options={companyOptions}
								width="400px"
								widthSm="100%"
								value={companyToAdd}
								onChange={(evt, newValue) =>
									setCompanyToAdd(newValue)
								}
							/>

							<ControlsFlexWrapper>
								<Button
									type="submit"
									variant={ButtonVariant.Main}
									onClick={addCompanyClickHandler}
									disabled={!companyToAdd}
								>
									Добавить
								</Button>
							</ControlsFlexWrapper>
						</>
					) : (
						<p>Нет доступных компаний для добавления</p>
					)}
				</GreyWrapper>
			</CustomDialog>

			<CustomDialog
				open={isEditEmployeeOpen}
				onClose={() => setIsEditEmployeeOpen(false)}
				title="Редактирование сотрудника"
			>
				<GreyWrapper as="form">
					<CustomTextField
						id="new-full-name"
						label="ФИО сотрудника"
						width="400px"
						widthSm="100%"
						value={newFullName}
						onChange={(evt) => setNewFullName(evt.target.value)}
						autoFocus
					/>

					<ControlsFlexWrapper>
						<Button
							type="submit"
							variant={ButtonVariant.Main}
							onClick={(evt) => editEmployeeClickHandler(evt, id)}
							disabled={!newFullName}
						>
							Редактировать
						</Button>
					</ControlsFlexWrapper>
				</GreyWrapper>
			</CustomDialog>

			<CustomDialog
				open={isAcceptRemoveCompanyeOpen}
				onClose={() => setIsAcceptRemoveCompanyOpen(false)}
				title="Вы хотите удалить сотрудника?"
			>
				<Button
					type="button"
					variant={ButtonVariant.Main}
					onClick={deleteCompanyFromEmployee}
				>
					Удалить
				</Button>
			</CustomDialog>

			<CustomDialog
				open={isAcceptBlockEmployeeOpen}
				onClose={() => setIsAcceptBlockEmployeeOpen(false)}
				title={`Вы хотите ${
					isBlocked ? "разблокировать" : "заблокировать"
				} ${isChecked ? "выбранных сотрудников" : "сотрудника"}?`}
			>
				<Button
					type="button"
					variant={ButtonVariant.Main}
					onClick={
						isBlocked
							? unlockEmployeeClickHandler
							: blockEmployeeClickHandler
					}
				>
					{isBlocked ? "Разблокировать" : "Заблокировать"}
				</Button>
			</CustomDialog>

			<CustomDialog
				open={isAcceptRemoveEmployeeOpen}
				onClose={() => setIsAcceptRemoveEmployeeOpen(false)}
				title={`Вы хотите удалить ${
					isChecked ? "выбранных сотрудников" : "сотрудника"
				}?`}
			>
				<Button
					type="button"
					variant={ButtonVariant.Main}
					onClick={(evt) => deleteEmployee(evt, id)}
				>
					Удалить
				</Button>
			</CustomDialog>

			<CustomDialog
				open={isSendMessageOpen}
				onClose={() => setIsSendMessageOpen(false)}
				title="Отправка сообщения сотруднику"
			>
				<GreyWrapper as="form">
					<CustomTextField
						id="message"
						label="Сообщение"
						width="400px"
						widthSm="100%"
						value={message}
						onChange={(evt) => setMessage(evt.target.value)}
						autoFocus
						multiline
						rows={2}
					/>

					<ControlsFlexWrapper>
						<Button
							type="submit"
							variant={ButtonVariant.Main}
							onClick={sendMessageClickHandler}
							disabled={!message}
						>
							Отправить
						</Button>
					</ControlsFlexWrapper>
				</GreyWrapper>
			</CustomDialog>
		</>
	);
}

export default TableRowEmployees;
