import React, {InputHTMLAttributes} from 'react';
import {Color, muiTheme} from '../../const';
import {MenuItem, TextField} from '@mui/material';
import {Option} from '../../types/option';
import useTextFieldValidation from '../../hooks/use-text-field-validation/use-text-field-validation';

type CustomTextFieldProps = {
	secondValue?: string,
	ref?: any,
	id: string,
	label?: string,
	ariaLabel?: string,
	value?: string,
	defaultValue?: string
	onChange?: (evt: React.ChangeEvent<HTMLInputElement>) => void,
	select?: boolean,
	options?: Option[],
	width?: string,
	widthSm?: string,
	widthMd?: string,
	widthLg?: string,
	marginTop?: string,
	marginBottom?: string,
	marginTopSm?: string,
	marginLeft?: string
	marginLeftSm?: string,
	marginLeftLg?: string,
	autoFocus?: boolean,
	multiline?: boolean,
	rows?: number,
	type?: InputHTMLAttributes<unknown>['type']
	required?: boolean,
	error?: boolean,
	helperText?: string
	validate?: boolean,
	setInvalid?: (isInvalid: boolean | null) => void,
	alternateView?: boolean,
	placeholder?: string,
	disabled?: boolean
}

function CustomTextField(props: CustomTextFieldProps): JSX.Element {
	const {
		secondValue = '',
		ref,
		id,
		label,
		ariaLabel,
		value,
		defaultValue,
		onChange,
		select,
		options,
		width,
		widthSm,
		widthMd,
		widthLg,
		marginTop,
		marginBottom,
		marginTopSm,
		marginLeft,
		marginLeftSm,
		marginLeftLg,
		autoFocus,
		multiline,
		rows,
		type,
		required,
		error,
		helperText,
		validate,
		setInvalid,
		alternateView,
		placeholder,
		disabled
	} = props;

	const [isError, innerHelperText] = useTextFieldValidation(
		validate,
		value,
		error,
		helperText,
		setInvalid,
		required,
		type,
		id,
		secondValue,
	);

	return (
		<TextField
			ref={ref}
			id={id}
			label={label}
			variant="outlined"
			value={value}
			defaultValue={defaultValue}
			onChange={onChange}
			select={select}
			aria-label={ariaLabel}
			autoFocus={autoFocus}
			focused={autoFocus}
			multiline={multiline}
			rows={rows}
			type={type}
			required={required}
			error={isError}
			helperText={innerHelperText}
			placeholder={placeholder}
			disabled={disabled}
			InputProps={{
				sx: {
					'& .MuiInputBase-input': {
						padding: '12px'
					},
					'& fieldset': {
						borderColor: alternateView ? '#FFFFFF' : '#DCDCDC'
					},
					'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderWidth: '1px',
						borderColor: Color.Orange
					},
					fontFamily: 'inherit',
					fontWeight: 'inherit',
					color: alternateView ? '#FFFFFF' : 'inherit',
					fontSize: '14px',
				}
			}}
			InputLabelProps={{
				sx: {
					'&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
						transform: 'translate(12px, 12px)',
					},
					'&.Mui-focused': {
						color: Color.Orange
					},
					fontFamily: 'inherit',
					fontWeight: 'inherit',
					color: 'inherit',
					fontSize: '14px'
				}
			}}
			sx={{
				marginTop: marginTop,
				marginBottom: marginBottom,
				marginLeft: alternateView ? '36px' : marginLeft,
				marginRight: alternateView ? '36px' : '0',
				fontFamily: 'inherit',
				fontWeight: 'inherit',
				color: 'inherit',
				fontSize: '14px',
				backgroundColor: alternateView ? '#F68A59' : '#FFFFFF',
				width: width,
				flexGrow: alternateView ? '1' : 'initial',
				'& .Mui-disabled': {
					opacity: '0.4'
				},
				[muiTheme.breakpoints.down('sm')]: {
					width: widthSm,
					marginTop: marginTopSm,
					marginLeft: alternateView ? '0' : marginLeftSm,
					marginRight: alternateView ? '9px' : '0',
					marginBottom: alternateView ? '9px' : '0',
					order: alternateView ? '4' : 'initial',
					flexBasis: alternateView ? '80%' : 'initial'
				},
				[muiTheme.breakpoints.between('sm', 'md')]: {
					width: widthMd,
					marginLeft: alternateView ? '10px' : marginLeftSm || marginLeft,
					marginRight: alternateView ? '10px': '0'
				},
				[muiTheme.breakpoints.down('lg')]: {
					width: widthLg,
					marginLeft: marginLeftLg
				}
			}}
			SelectProps={{
				IconComponent: (props) => (
					<svg
						width="14"
						height="14"
						viewBox="0 0 14 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						{...props}
					>
						<path
							d="M1.68 4.15334C1.39417 4.43918 1.39417 4.90001 1.68 5.18584L6.58583 10.0917C6.6398 10.1458 6.7039 10.1887 6.77447 10.2179C6.84504 10.2472 6.92068 10.2623 6.99708 10.2623C7.07348 10.2623 7.14913 10.2472 7.2197 10.2179C7.29026 10.1887 7.35437 10.1458 7.40833 10.0917L12.32 5.18001C12.6 4.90001 12.6 4.43334 12.32 4.15334C12.2523 4.08544 12.1718 4.03156 12.0832 3.9948C11.9946 3.95804 11.8997 3.93912 11.8037 3.93912C11.7078 3.93912 11.6129 3.95804 11.5243 3.9948C11.4357 4.03156 11.3552 4.08544 11.2875 4.15334L7 8.43501L2.7125 4.14751C2.42667 3.86751 1.96583 3.86751 1.68 4.15334Z"
							fill={alternateView ? "#FFFFFF" : "#ABA7AF"}/>
					</svg>
				)
			}}
		>
			{options && options.map((option) => (
				<MenuItem
					key={option.value}
					value={option.value}
					selected={value === option.value  || value === undefined}
					sx={{
						fontFamily: 'inherit',
						fontWeight: 'inherit',
						color: 'inherit',
						fontSize: '14px',
						'&.Mui-selected, &.Mui-selected:hover, &.Mui-focusVisible': {
							backgroundColor: Color.Orange,
							color: '#FFFFFF'
						},
					}}>
					{option.label.main}
				</MenuItem>
			))}
		</TextField>
	);
}

export default CustomTextField;
