import Header from "../../components/header/header";
import Navigation from "../../components/navigation/navigation";
import ScreenControlsCompanies from "../../components/screen-controls-companies/screen-controls-companies";
import TableCompanies from "../../components/table-companies/table-companies";
import Footer from "../../components/footer/footer";
import { Main } from "../../components/styled/main";
import { MainContainer } from "../../components/styled/main-container";
import { DesktopWrapper } from "../../components/styled/desktop-wrapper";
import { MainWrapper } from "../../components/styled/main-wrapper";
import { ScreenTitle } from "../../components/styled/screen-title";
import { AppRoute } from "../../const";
import useTitle from "../../hooks/use-title/use-title";

function CompaniesScreen(): JSX.Element {

	useTitle("Компании | Терминал");

	return (
		<>
			<Header
				ScreenControls={ScreenControlsCompanies}
				navigation={<Navigation currentRoute={AppRoute.Companies} />}
			/>

			<Main>
				<MainContainer>
					<DesktopWrapper>
						<Navigation currentRoute={AppRoute.Companies} />
					</DesktopWrapper>

					<MainWrapper>
						<ScreenTitle>Компании</ScreenTitle>

						<DesktopWrapper>
							<ScreenControlsCompanies />
						</DesktopWrapper>

						<TableCompanies />
					</MainWrapper>
				</MainContainer>
			</Main>

			<Footer />
		</>
	);
}

export default CompaniesScreen;
