import styled from 'styled-components';
import {Resolution} from "../../const";

type GreyWrapperProps = {
	width?: string,
	widthSm?: string
}

export const GreyWrapper = styled.div<GreyWrapperProps>`
	padding: 22px 14px;
	margin: 0;
	border: 0;
	width: ${({width}) => width ? width : '100%'};

	background-color: rgba(220, 220, 220, 0.42);
	border-radius: 5px;

	@media (max-width: ${Resolution.SM}) {
		width: ${({widthSm}) => widthSm};
	}
`;
