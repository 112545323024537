import {InputHTMLAttributes, useEffect, useState} from 'react';

const EMPTY_FIELD_TEXT = 'Необходимо заполнить это поле';
const EMAIL_MISMATCH_TEXT = 'Необходимо ввести корректный email';
const PASSWORD_LOW_LENGTH = 'Длина пароля должна быть не менее 8 символов';
const PASSWORDS_NOT_EQUAL = 'Пароли не совпадают';
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

type ReturnType = [boolean | undefined, string | undefined];

function useTextFieldValidation(
	validate: boolean | undefined,
	value: string | undefined,
	error: boolean | undefined,
	helperText: string | undefined,
	setInvalid: ((isInvalid: boolean | null) => void) | undefined,
	required: boolean | undefined,
	type: InputHTMLAttributes<unknown>['type'] | undefined,
	id: string,
	secondValue: string
): ReturnType {
	const [isError, setIsError] = useState(error);
	const [innerHelperText, setInnerHelperText] = useState(helperText);

	useEffect(() => {
		if (required && !value) {
			if (validate) {
				setIsError(true);
				setInnerHelperText(EMPTY_FIELD_TEXT);
			}

			if (setInvalid) {
				setInvalid(true);
			}
		} else {
			if (validate) {
				setIsError(error);
				setInnerHelperText(helperText);
			}

			if (setInvalid) {
				setInvalid(false);
			}
		}

		if (type === 'email' && value) {
			if (!value.match(EMAIL_REGEX)) {
				if (validate) {
					setIsError(true);
					setInnerHelperText(EMAIL_MISMATCH_TEXT);
				}

				if (setInvalid) {
					setInvalid(true);
				}
			} else {
				if (validate) {
					setIsError(error);
					setInnerHelperText(helperText);
				}

				if (setInvalid) {
					setInvalid(false);
				}
			}
		}

		if(id === 'new-pass' && value) {
			if(!(value?.length >= 8)) {
				if (validate) {
					setIsError(true);
					setInnerHelperText(PASSWORD_LOW_LENGTH);
				}

				if (setInvalid) {
					setInvalid(true);
				}
			} else {
				if (validate) {
					setIsError(error);
					setInnerHelperText(helperText);
				}

				if (setInvalid) {
					setInvalid(false);
				}
			}
		}

		if(id === 'new-repeat-pass' && value && secondValue) {
			if(!(value === secondValue)) {
				if (validate) {
					setIsError(true);
					setInnerHelperText(PASSWORDS_NOT_EQUAL);
				}

				if (setInvalid) {
					setInvalid(true);
				}
			} else {
				if (validate) {
					setIsError(error);
					setInnerHelperText(helperText);
				}

				if (setInvalid) {
					setInvalid(false);
				}
			}
		}
	}, [validate, value, error, helperText]);

	return [isError, innerHelperText];
}

export default useTextFieldValidation;
