import styled from "styled-components";
import {Label} from "../../components/styled/label";

export const NumberLabel = styled(Label)`
	width: 33px;

	top: unset;
	bottom: 8px;
	right: 49px;
`;
