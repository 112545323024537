import React from 'react';
import {AppRoute} from '../../const';
import {NavigationIcon, NavigationLink, StyledNavigation} from './style';

type NavigationProps = {
	currentRoute?: AppRoute
}

function Navigation({currentRoute}: NavigationProps): JSX.Element {
	return (
		<StyledNavigation>
			<NavigationLink to={AppRoute.Companies} $active={currentRoute === AppRoute.Companies}>
				<NavigationIcon width="20" height="22" viewBox="0 0 20 22">
					<use xlinkHref="/img/sprite.svg#buildings"></use>
				</NavigationIcon>

				Компании
			</NavigationLink>

			<NavigationLink to={AppRoute.Employees} $active={currentRoute === AppRoute.Employees}>
				<NavigationIcon width="19" height="20" viewBox="0 0 19 20">
					<use xlinkHref="/img/sprite.svg#user-gear"></use>
				</NavigationIcon>

				Сотрудники
			</NavigationLink>

			<NavigationLink to={AppRoute.Managers} $active={currentRoute === AppRoute.Managers}>
				<NavigationIcon width="19" height="20" viewBox="0 0 19 20">
					<use xlinkHref="/img/sprite.svg#user-gear"></use>
				</NavigationIcon>

				Менеджеры
			</NavigationLink>
		</StyledNavigation>
	);
}

export default Navigation;
