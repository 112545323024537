import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import {
	Account,
	AccountName,
	BurgerButton,
	FilterButton,
	HeaderContainer,
	IconButton as HeaderIconButton,
	License,
	LicenseNumber,
	LogoLink,
	StyledHeader,
} from "../../components/header/style";
import {
	AppRoute,
	ButtonVariant,
	Color,
	DropdownCardTagIcon,
	HeaderButtonIcon,
	Resolution,
	VehicleStatus,
} from "../../const";
import VisuallyHidden from "../../components/styled/visually-hidden";
import CustomTextField from "../../components/custom-text-field/custom-text-field";
import { Link, useNavigate } from "react-router-dom";
import { DesktopWrapper } from "../../components/styled/desktop-wrapper";
import {
	NavigationIcon,
	NavigationLink,
	StyledNavigation,
} from "../../components/navigation/style";
import { Main } from "../../components/styled/main";
import { MainContainer } from "../../components/styled/main-container";
import { MainWrapper } from "../../components/styled/main-wrapper";
import useTitle from "../../hooks/use-title/use-title";
import { ButtonContainer } from "../../components/styled/button-container";
import Button from "../../components/styled/button";
import { StyledScreenControls } from "../../components/styled/styled-screen-controls";
import { WrapControlsForm } from "../../components/styled/controls-form";
import {
	DateFakeLegend,
	DateFieldset,
} from "../../components/styled/date-fieldset";
import CustomDatePicker from "../../components/custom-date-picker/custom-date-picker";
import CustomTimePicker from "../../components/custom-time-picker/custom-time-picker";
import { IconButton } from "../../components/styled/icon-button";
import {
	MarginMainGrid,
} from "../../components/styled/main-grid";
import DropdownCard from "../../components/dropdown-card/dropdown-card";
import Footer from "../../components/footer/footer";
import { HeaderTag } from "../../components/dropdown-card/style";
import { Option } from "../../types/option";
import CustomAutocomplete from "../../components/custom-autocomplete/custom-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getLicense } from "../../store/general/selectors";
import { IEmployeesState } from "../../store/employees/employees";
import axios from "axios";
import { getUserInfo } from "../../services/user-info";
import { ICompaniesState } from "../../store/companies/companies";
import CustomDialog from "../../components/custom-dialog/custom-dialog";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import { AppDispatch } from "../../store/store";
import {
	IVehiclesState,
	addDocumentForVehicles,
	addDocumentsVehicles,
	addVehicles,
	removeDocumentFromVehicles,
	resetAllSearch,
	updateSearchByNumberCar,
	updateSearchBynumbertrailer,
	updateStatus,
} from "../../store/vehicles/vehicles";
import {
	getAllDocumentsVehicles,
	getSearchNumberCar,
	getSearchNumberTrailer,
	getSearchStatusVechiles,
	getVechiles,
} from "../../store/vehicles/selector";
import { NumberLabel } from "./style";
import { IDocuments } from "../../types/state";
import { StripedTable } from "../../components/styled/striped-table";
import { CustomLink } from "../../components/styled/custom-link";
import { ActionButton } from "../../components/styled/action-button";
import { Loader } from "../../components/Loader/Loader";
import { updateLicense } from "../../store/general/general";
import { GreyWrapper } from "../../components/styled/grey-wrapper";
import { ControlsFlexWrapper } from "../../components/styled/controls-flex-wrapper";

function DocumentsVehicleScreen(): JSX.Element {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const userInfo = useSelector(getUserInfo);
	const license = useSelector(getLicense);

	const allVehiclesDocuments = useSelector(getAllDocumentsVehicles);
	const [isEditLicenseOpen, setIsEditLicenseOpen] = useState(false);
	const [isAcceptRemoveDocumentOpen, setIsAcceptRemoveDocumentOpen] =
		useState(false);
	const [newLicense, setNewLicense] = useState(license);
	const [loading, setloading] = useState(true);
	const [isAddDocumentOpen, setIsAddDocumentOpen] = useState(false);
	const [file, setFile] = useState<any>(null);
	const [vehicleId, setVehiclesId] = useState<any>("");
	const [vehiclesIdToRemove, setVehiclesIdToRemove] = useState<string>("");
	const [numberCarVehicles, setNumberCarVehicles] = useState("");
	const [numberTrailerVehicles, setNumberTrailerVehicles] = useState("");
	const [statusVehicles, setStatusVehicles] = useState("");
	const [selectedTimeFrom, setSelectedTimeFrom] = useState<any>(null);
	const [selectedTimeTo, setSelectedTimeTo] = useState<any>(null);
	const [selectedDateFrom, setSelectedDateFrom] = useState<any>(null);
	const [selectedDateTo, setSelectedDateTo] = useState<any>(null);
	const [user, setUser] = useState<IEmployeesState | null>(null);
	const [open, setOpen] = useState<string | false>(false);
	const [companies, setCompanies] = useState<ICompaniesState[] | []>([]);
	const [company, setCompany] = useState<Option | null>({
		value: "",
		label: {
			main: "Загрузка...",
		},
	});

	const employee = useSelector(getUserInfo);

	const handleUnauthorizedError = () => {
		navigate(AppRoute.Login);
	};

	useEffect(() => {
		setloading(true);
		async function fetchVehicles(id: string | undefined) {
			const result = await axios.get(`/spa/vehicles?company=${id}`);
			dispatch(addVehicles(result.data.data));
			setloading(false);
		}

		if (company?.value !== "") {
			fetchVehicles(company?.value);
		}
	}, [company]);

	useEffect(() => {
		async function fetchLicense() {
			const result = await axios.get("/spa/license").then((response) => {
				dispatch(updateLicense(response.data.data.license));
			}).catch((error: any) => {
				if (error.response) {
					if (
						error.response.status === 401
					) {
						navigate(AppRoute.Login);
					}
				}
			});
		}
		fetchLicense()
	}, []);

	useEffect(() => {
		async function fetchUser(id: number | undefined) {
			const result = await axios
				.get(`/spa/users/${id}`)
				.then((response) => {
					setUser(response.data.data);
				})
				.catch((error: any) => {
					if (error.response) {
						if (
							error.response.status === 401 ||
							error.response.status === "401"
						) {
							handleUnauthorizedError();
						}
					}
				});
		}
		if (employee) {
			fetchUser(employee?.id);
		}
	}, []);

	useEffect(() => {
		if (user) {
			user.companyIds.forEach(async (id, i) => {
				const response = await axios.get(`/spa/companies/${id}`);
				if(i === 0) {
					setCompany({
						value: String(response.data.data.id),
						label: {
							main: response.data.data.name,
						},
					});
				}
				setCompanies((prevCompanies) => [
					...prevCompanies,
					response.data.data,
				]);
			});
		}
	}, [user]);

	useEffect(() => {
		const fetchDocuments = async () => {
			const result = await axios.get("/spa/documents", {
				params: { for: "vechile" },
			});
			dispatch(addDocumentsVehicles(result.data.data));
		};
		try {
			fetchDocuments();
		} catch (error) {
			toast("Что-то пошло не так. Перезагрузите страницу", { type: "error" });
		}
	}, []);

	const vechilesCurrent = useSelector(getVechiles);
	const searchNumberCar = useSelector(getSearchNumberCar);
	const searchNumberTrailer = useSelector(getSearchNumberTrailer);
	const searchStatus = useSelector(getSearchStatusVechiles);

	function sortVechilesByDate(vechiles: IVehiclesState[] | null | undefined): IVehiclesState[] | null {
		if (vechiles) {
			const sortedOrderByDate = [...vechiles].sort((a: IVehiclesState, b: IVehiclesState) => {
			return new Date(b.arrivaldate).getTime() - new Date(a.arrivaldate).getTime();
		});
		return sortedOrderByDate;
		} else {
			return null;
		}
	}
	const vechiles = sortVechilesByDate(vechilesCurrent);

	function filterVehicles(
		vechiles: IVehiclesState[] | null | undefined,
		searchNumberCar: string,
		searchNumberTrailer: string,
		selectedDateFrom: string,
		selectedDateTo: string,
		selectedTimeFrom: any,
		selectedTimeTo: any,
		searchStatus: string
	): IVehiclesState[] | undefined {
		return vechiles
			?.filter((vehicle) => {
				if (searchNumberCar !== "") {
					return vehicle.numbercar.includes(String(searchNumberCar));
				} else {
					return vehicle;
				}
			})
			.filter((item) => {
				if (searchNumberTrailer !== "") {
					return item.numbertrailer.includes(
						String(searchNumberTrailer)
					);
				} else {
					return item;
				}
			})
			.filter((item) => {
				if (searchStatus !== "") {
					return (
						item.locationcar === searchStatus + "а" ||
						item.locationcar === searchStatus
					);
				} else {
					return item;
				}
			})
			.filter((item) => {
				if (selectedDateFrom !== null && selectedDateTo !== null) {
					return (
						new Date(item.arrivaldate).getTime() >=
							new Date(selectedDateFrom).getTime() &&
						new Date(item.arrivaldate).getTime() <=
							new Date(selectedDateTo).getTime()
					);
				} else {
					return item;
				}
			})
			.filter((item) => {
				if (selectedTimeFrom !== null && selectedTimeTo !== null) {
					const currenttime =
						new Date(item.arrivaldate).getHours() * 60 +
						new Date(item.arrivaldate).getMinutes();
					return (
						currenttime >=
							selectedTimeFrom.$H * 60 + selectedTimeFrom.$m &&
						currenttime <=
							selectedTimeTo.$H * 60 + selectedTimeTo.$m
					);
				} else {
					return item;
				}
			});
	}

	const sortedDocumentsByVehicles = (
		documents: IDocuments[] | null | undefined,
		vehicles_id: string | undefined
	): IDocuments[] | null | undefined => {
		return documents?.filter((item) => {
			return item.owner_id === String(vehicles_id);
		});
	};

	const filtredVehicles = filterVehicles(
		vechiles,
		searchNumberCar,
		searchNumberTrailer,
		selectedDateFrom,
		selectedDateTo,
		selectedTimeFrom,
		selectedTimeTo,
		searchStatus
	);

	const vehicleRender =
		searchNumberCar ||
		searchNumberTrailer ||
		selectedDateFrom ||
		selectedDateTo ||
		selectedTimeFrom ||
		selectedTimeTo ||
		searchStatus
			? filtredVehicles
			: vechiles;

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if(e.target.files) {
			const selectedFile = e.target.files[0];
			if(selectedFile && selectedFile.type === 'application/pdf') {
				setFile(selectedFile);
			} else {
				toast("Для загрузки допускаются только файлы в формате pdf", {
					type: "warning",
				});
				e.target.value = '';
			}
		}
	};

	function editLicenseClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		refreshLicense();
	}

	async function refreshLicense() {
		try {
			const result = await axios.post("/spa/license/create", {
				license: newLicense,
			});
			setNewLicense(result.data.data.license);
			dispatch(updateLicense(newLicense));
			setIsEditLicenseOpen(false);
			toast("Лицензия успешно обновлена", { type: "success" });
		} catch (error) {
			toast("Что-то пошло не так. Перезагрузите страницу", {
				type: "error",
			});
		}
	}

	function submitClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();

		dispatch(updateSearchByNumberCar(numberCarVehicles));
		dispatch(updateSearchBynumbertrailer(numberTrailerVehicles));
		dispatch(updateStatus(statusVehicles));
	}

	function handleReset(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		dispatch(resetAllSearch());
		setNumberCarVehicles("");
		setNumberTrailerVehicles("");
		setStatusVehicles("");
		setSelectedDateFrom(null);
		setSelectedDateTo(null);
		setSelectedTimeFrom(null);
		setSelectedTimeTo(null);
	}

	async function uploadFileHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		try {
			const formData = new FormData();
			formData.append("for", "vehicle");
			formData.append("id", vehicleId);
			formData.append("file", file);

			const response = await axios.post(
				"/spa/documents/upload",
				formData
			);

			dispatch(
				addDocumentForVehicles({
					created_at: new Date().toISOString(),
					name: file.name,
					owner_id: String(vehicleId),
				} as IDocuments)
			);
			setIsAddDocumentOpen(false);
			toast("Документ успешно добавлен", { type: "success" });
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	}

	const handleDownload = (link: string | undefined, name: string) => {
		// Создание ссылки на файл
		const fileUrl = `${link}`;
		const currentLink = document.createElement("a");
		currentLink.href = fileUrl;
		currentLink.download = `${name}`;

		currentLink.click();
	};

	const deleteDocument = async (
		evt: MouseEvent<HTMLButtonElement>,
		vehiclesID: string
	) => {
		evt.preventDefault();
		try {
			const result = await axios.delete(`/spa/documents/${vehiclesID}`);
			dispatch(removeDocumentFromVehicles(String(vehiclesID)));
			setIsAcceptRemoveDocumentOpen(false);
			toast("Документ успешно удален", { type: "success" });
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	};

	const createDate = (data: string): string => {
		if (data) {
			const arrivalDate = new Date(data);
			const day = arrivalDate.getDate();
			const month = arrivalDate.getMonth() + 1;
			const year = arrivalDate.getFullYear();

			const formattedDate = `${day < 10 ? "0" + day : day}.${
				month < 10 ? "0" + month : month
			}.${year}`;

			const hours = arrivalDate.getUTCHours() ;
			const minutes = arrivalDate.getMinutes();
			const formattedTime = `${hours < 10 ? "0" + hours : hours}:${
				minutes < 10 ? "0" + minutes : minutes
			}`;

			return formattedDate + " / " + formattedTime;
		} else {
			return "";
		}
	};

	function changeCardHandler(card: string, isOpen: boolean) {
		setOpen(isOpen ? card : false);
	}

	useTitle("Документы по транспорту | Терминал");

	return (
		<>
			<StyledHeader>
				<HeaderContainer>
					<LogoLink to={AppRoute.Main} aria-label="На главную">
						<picture>
							<source
								media={`(max-width: ${Resolution.SM})`}
								srcSet="/img/logo-mobile.svg"
							/>

							<img
								src="/img/logo.svg"
								width={193}
								height={45}
								alt='Группа компаний "Терминал"'
							/>
						</picture>
					</LogoLink>

					<License>
						Лицензия <LicenseNumber>{license}</LicenseNumber>
					</License>

					<HeaderIconButton
					type="button"
					title="Редактировать"
					icon={HeaderButtonIcon.Edit}
					marginRight="auto"
					onClick={() => setIsEditLicenseOpen(true)}
				>
					<VisuallyHidden>Редактировать</VisuallyHidden>
				</HeaderIconButton>

					<Account marginLeft="auto">
						Менеджер <AccountName>{userInfo?.name}</AccountName>
					</Account>

					<HeaderIconButton
						as={Link}
						to={AppRoute.Logout}
						icon={HeaderButtonIcon.Logout}
						title="Выйти"
					>
						<VisuallyHidden>Выйти</VisuallyHidden>
					</HeaderIconButton>

					<BurgerButton>
						<VisuallyHidden>Открыть меню</VisuallyHidden>
					</BurgerButton>

					<FilterButton>
						<VisuallyHidden>Фильтры</VisuallyHidden>
					</FilterButton>
				</HeaderContainer>
			</StyledHeader>

			<Main>
				<MainContainer>
					<DesktopWrapper>
						<StyledNavigation>
							<NavigationLink to={AppRoute.Orders}>
								<NavigationIcon
									width="20"
									height="22"
									viewBox="0 0 20 22"
								>
									<use xlinkHref="/img/sprite.svg#chalkboard-teacher"></use>
								</NavigationIcon>
								Заявки
							</NavigationLink>

							<NavigationLink to={AppRoute.Documents} $active>
								<NavigationIcon
									width="19"
									height="20"
									viewBox="0 0 19 20"
								>
									<use xlinkHref="/img/sprite.svg#download"></use>
								</NavigationIcon>
								Документы
							</NavigationLink>
						</StyledNavigation>
					</DesktopWrapper>

					<MainWrapper>
						<VisuallyHidden as="h1">
							Документы по транспорту
						</VisuallyHidden>

						<ButtonContainer>
							<CustomAutocomplete
								id="company"
								label="Компания"
								width="250px"
								options={companies.map((item) => {
									return {
										value: String(item.id),
										label: {
											main: item.name,
										},
									};
								})}
								value={company}
								onChange={(evt, newValue) =>
									setCompany(newValue)
								}
							/>

							<Button
								as={Link}
								to={AppRoute.DocumentsCompany}
								variant={ButtonVariant.Secondary}
							>
								Документы компаний
							</Button>

							<Button
								as={Link}
								to={AppRoute.DocumentsVehicle}
								variant={ButtonVariant.Main}
							>
								Документы по ТС
							</Button>
						</ButtonContainer>

						<DesktopWrapper>
							<StyledScreenControls>
								<VisuallyHidden as="h2">
									Управление
								</VisuallyHidden>

								<WrapControlsForm>
									<CustomTextField
										id="vehicle-number"
										label="Номер ТС"
										width="110px"
										widthSm="100%"
										widthMd="130px"
										marginTopSm="8px"
										value={numberCarVehicles}
										onChange={(e) =>
											setNumberCarVehicles(e.target.value)
										}
									/>

									<CustomTextField
										id="declaration-number"
										label="Номер ДТ"
										width="110px"
										widthSm="100%"
										widthMd="130px"
										marginTopSm="8px"
										value={numberTrailerVehicles}
										onChange={(e) =>
											setNumberTrailerVehicles(
												e.target.value
											)
										}
									/>

									<CustomTextField
										id="loaded"
										label="Загружены"
										width="175px"
										widthSm="100%"
										widthMd="130px"
										select
										value={statusVehicles}
										options={[
											{
												value: "Да",
												label: {
													main: "Да",
												},
											},
											{
												value: "Нет",
												label: {
													main: "Нет",
												},
											},
										]}
										onChange={(evt) =>
											setStatusVehicles(evt.target.value)
										}
									/>

									<DateFieldset>
										<VisuallyHidden as="legend">
											Дата
										</VisuallyHidden>
										<DateFakeLegend aria-hidden={true}>
											Дата
										</DateFakeLegend>

										<CustomDatePicker
											value={selectedDateFrom}
											onChange={(newValue) =>
												setSelectedDateFrom(newValue)
											}
											label="От"
											width="131px"
											order={1}
										/>

										<CustomDatePicker
											value={selectedDateTo}
											onChange={(newValue) =>
												setSelectedDateTo(newValue)
											}
											label="До"
											width="131px"
											order={3}
										/>
									</DateFieldset>

									<DateFieldset>
										<VisuallyHidden as="legend">
											Время
										</VisuallyHidden>
										<DateFakeLegend aria-hidden={true}>
											Время
										</DateFakeLegend>

										<CustomTimePicker
											value={selectedTimeFrom}
											onChange={(newValue) =>
												setSelectedTimeFrom(newValue)
											}
											label="От"
											width="91px"
											order={1}
										/>

										<CustomTimePicker
											value={selectedTimeTo}
											onChange={(newValue) =>
												setSelectedTimeTo(newValue)
											}
											label="До"
											width="91px"
											order={3}
										/>
									</DateFieldset>

									<IconButton
										type="submit"
										onClick={submitClickHandler}
										variant={ButtonVariant.Main}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<use xlinkHref="/img/sprite.svg#check-circle"></use>
										</svg>

										<span>Применить</span>
									</IconButton>

									<IconButton
										onClick={handleReset}
										type="reset"
										variant={ButtonVariant.Secondary}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<use xlinkHref="/img/sprite.svg#cross-circle"></use>
										</svg>

										<span>Сбросить</span>
									</IconButton>
								</WrapControlsForm>
							</StyledScreenControls>

							<CustomDialog
								open={isAddDocumentOpen}
								onClose={() => setIsAddDocumentOpen(false)}
								title="Добавить документ"
							>
								<TextField
									onChange={handleFileChange}
									name="upload-photo"
									type="file"
								/>
								<Button
									disabled={!file}
									type="submit"
									onClick={uploadFileHandler}
									variant={ButtonVariant.Main}
									margin="24px 0 0 0"
								>
									Загрузить
								</Button>
							</CustomDialog>
						</DesktopWrapper>

						<MarginMainGrid>
							{loading ? (
								<Loader />
							) : vehicleRender?.length ? (
								vehicleRender?.map((item, i) => (
									<DropdownCard
										key={item.docnum}
										open={open === `card-${i + 1}`}
										onChange={(isOpen) =>
											changeCardHandler(
												`card-${i + 1}`,
												isOpen
											)
										}
										tag={
											item.locationcar === "ЗТК"
												? VehicleStatus.Customs
												: item.locationcar == "СВХ"
												? VehicleStatus.Storage
												: item.locationcar == "ОП. СВХ"
												? VehicleStatus.OpenStorage
												: VehicleStatus.Departed
										}
										header={
											<>
												<HeaderTag
													icon={
														DropdownCardTagIcon.Truck
													}
												>
													{item.numbercar}
												</HeaderTag>
												<HeaderTag
													icon={
														DropdownCardTagIcon.Trailer
													}
												>
													{item.numbertrailer}
												</HeaderTag>
												<HeaderTag
													as="time"
													icon={
														DropdownCardTagIcon.Calendar
													}
													dateTime={createDate(
														item.arrivaldate
													)}
												>
													{createDate(
														item.arrivaldate
													)}
												</HeaderTag>
												<NumberLabel
													color={
														allVehiclesDocuments?.filter(
															(doc) => {
																return (
																	doc.owner_id ===
																	String(
																		item.id
																	)
																);
															}
														).length === 0
															? "#FFFFFF"
															: Color.DarkText
													}
													backgroundColor={
														allVehiclesDocuments?.filter(
															(doc) => {
																return (
																	doc.owner_id ===
																	String(
																		item.id
																	)
																);
															}
														).length === 0
															? Color.ButtonHover
															: Color.Green
													}
												>
													{
														allVehiclesDocuments?.filter(
															(doc) => {
																return (
																	doc.owner_id ===
																	String(
																		item.id
																	)
																);
															}
														).length
													}
												</NumberLabel>
											</>
										}
									>
										<>
											<StripedTable>
												<tbody>
													{sortedDocumentsByVehicles(
														allVehiclesDocuments,
														item.id
													)?.map((item) => (
														<tr key={item.id}>
															<td>
																<time
																	dateTime={createDate(
																		item.created_at
																	)}
																>
																	{createDate(
																		item.created_at
																	)}
																</time>
															</td>
															<td>
																<CustomLink
																	onClick={() =>
																		handleDownload(
																			item.link,
																			item.name
																		)
																	}
																>
																	{item.name}
																</CustomLink>
															</td>

															<td>
																<ActionButton
																	type="button"
																	title="Удалить"
																	onClick={() => {
																		setIsAcceptRemoveDocumentOpen(
																			true
																		);
																		setVehiclesIdToRemove(
																			item.id
																		);
																	}}
																>
																	<svg
																		width="16"
																		height="16"
																		viewBox="0 0 22 22"
																	>
																		<use xlinkHref="/img/sprite.svg#remove"></use>
																	</svg>
																</ActionButton>
															</td>
														</tr>
													))}
												</tbody>
											</StripedTable>
											<Button
												type="button"
												onClick={() => {
													setVehiclesId(item?.id);
													setIsAddDocumentOpen(true);
												}}
												variant={ButtonVariant.Main}
												margin="17px 0 0 12px"
											>
												Загрузить
											</Button>
										</>
									</DropdownCard>
								))
							) : (
								<>Список пуст</>
							)}
						</MarginMainGrid>

						<CustomDialog
							open={isEditLicenseOpen}
							onClose={() => setIsEditLicenseOpen(false)}
							title="Редактирование лицензии"
						>
							<GreyWrapper as="form">
								<CustomTextField
									id="new-license"
									label="Лицензия"
									width="400px"
									value={newLicense}
									onChange={(evt) =>
										setNewLicense(evt.target.value)
									}
									autoFocus
								/>

								<ControlsFlexWrapper>
									<Button
										type="submit"
										variant={ButtonVariant.Main}
										onClick={editLicenseClickHandler}
										disabled={!newLicense}
									>
										Редактировать
									</Button>
								</ControlsFlexWrapper>
							</GreyWrapper>
						</CustomDialog>

						<CustomDialog
							open={isAcceptRemoveDocumentOpen}
							onClose={() => setIsAcceptRemoveDocumentOpen(false)}
							title="Вы хотите удалить документ?"
						>
							<Button
								type="button"
								variant={ButtonVariant.Main}
								onClick={(evt) =>
									deleteDocument(evt, vehiclesIdToRemove)
								}
							>
								Удалить
							</Button>
						</CustomDialog>
					</MainWrapper>
				</MainContainer>
			</Main>

			<Footer />
		</>
	);
}

export default DocumentsVehicleScreen;
