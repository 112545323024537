import {State} from '../../../types/state';
import {NameSpace} from '../../root-reducer';
import { IEmployeesState } from '../employees';
import { ICompaniesState } from '../../companies/companies';

export const getEmployees = (state: State): IEmployeesState[] | null | undefined => state[NameSpace.Employees].employees;
export const getCompanyForEmployees = (state: State): ICompaniesState[] | null | undefined => state[NameSpace.Employees].companyForEmployees;
export const getSearchEmployeeName = (state: State): string => state[NameSpace.Employees].searchByEmployee;
export const getSearchEmployeeFromCompany = (state: State): string => state[NameSpace.Employees].searchByCompanyName;
export const getSearchEmployeeBySort = (state: State): string => state[NameSpace.Employees].sortBy;

