import {MouseEvent, useEffect, useState} from "react";
import useTitle from "../../hooks/use-title/use-title";
import {
	Account, AccountName, BurgerButton, FilterButton,
	HeaderContainer,
	IconButton as HeaderIconButton,
	License,
	LicenseNumber,
	LogoLink, StyledHeader
} from "../../components/header/style";
import {AppRoute, ButtonVariant, HeaderButtonIcon, Resolution} from "../../const";
import VisuallyHidden from "../../components/styled/visually-hidden";
import {Link, useNavigate} from "react-router-dom";
import {DesktopWrapper} from "../../components/styled/desktop-wrapper";
import {NavigationIcon, NavigationLink, StyledNavigation} from "../../components/navigation/style";
import Footer from "../../components/footer/footer";
import {MainWrapper} from "../../components/styled/main-wrapper";
import {Main} from "../../components/styled/main";
import {MainContainer} from "../../components/styled/main-container";
import {StyledScreenControls} from "../../components/styled/styled-screen-controls";
import {WrapControlsForm} from "../../components/styled/controls-form";
import {DateFakeLegend, DateFieldset} from "../../components/styled/date-fieldset";
import CustomDatePicker from "../../components/custom-date-picker/custom-date-picker";
import CustomTimePicker from "../../components/custom-time-picker/custom-time-picker";
import {IconButton} from "../../components/styled/icon-button";
import {BigStripedTable} from "../../components/styled/striped-table";
import {CustomLink} from "../../components/styled/custom-link";
import {Option} from "../../types/option";
import CustomAutocomplete from "../../components/custom-autocomplete/custom-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../store/user/selectors";
import { getLicense } from "../../store/general/selectors";
import axios from "axios";
import { IEmployeesState } from "../../store/employees/employees";
import { ICompaniesState } from "../../store/companies/companies";
import { IDocuments } from "../../types/state";
import { AppDispatch } from "../../store/store";
import { getAllDocumentsVehicles } from "../../store/vehicles/selector";
import { IVehiclesState, addDocumentsVehicles, decNewRequestsCountVehicles, incrementNewRequestsCountVehicles, setCurrentCompanyId, setCurrentCompanyName } from "../../store/vehicles/vehicles";
import { Loader } from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import {isEqual} from 'lodash';

function DocumentsEmployeeScreen(): JSX.Element {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();
	const allVehiclesDocuments = useSelector(getAllDocumentsVehicles);
	const userInfo = useSelector(getUserInfo);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		const fetchDocuments = async () => {
			const result = await axios.get("/spa/documents", {
				params: { for: "vechile" },
			});
			dispatch(addDocumentsVehicles(result.data.data));
			setLoading(false);
		};
		try {
			fetchDocuments();
		} catch (error) {
			toast("Что-то пошло не так. Перезагрузите страницу", { type: "error" });
		}
	}, []);

	const [companies, setCompanies] = useState<ICompaniesState[] | []>([]);
	const [user, setUser] = useState<IEmployeesState | null>(null);
	const [selectedTimeFrom, setSelectedTimeFrom] = useState<any>(null);
	const [selectedTimeTo, setSelectedTimeTo] = useState<any>(null);
	const [selectedDateFrom, setSelectedDateFrom] = useState<any>(null);
	const [selectedDateTo, setSelectedDateTo] = useState<any>(null);
	const [company, setCompany] = useState<Option | null>({
		value: '0',
		label: {
			main: 'Загрузка...'
		}
	});

	const employee = useSelector(getUserInfo);


	function filtredDocuments(documents: IDocuments[] | null | undefined):IDocuments[] | null | undefined {
		return documents?.filter(item => {
			if(selectedDateFrom !== null && selectedDateTo !== null) {
				return	new Date(item.created_at).getTime() >= new Date(selectedDateFrom).getTime() && new Date(item.created_at).getTime()  <= new Date(selectedDateTo).getTime();
			} else {
				return item
			}
		}).filter(item => {
			if(selectedTimeFrom !== null && selectedTimeTo !== null) {
				const currenttime = new Date(item.created_at).getHours() * 60 + new Date(item.created_at).getMinutes();
				return currenttime >= selectedTimeFrom.$H * 60 + selectedTimeFrom.$m && currenttime  <= selectedTimeTo.$H * 60 + selectedTimeTo.$m;
			} else {
				return item
			}
		});
	}

	// const VEHICLES__NAME = {
	// 	"arrivaldate": "Дата прибытия ТС",
	// 	"closingdatedelivery": "Дата закрытия доставки",
	// 	"dateappointmentinspection": "Дата назначения проведения досмотра",
	// 	"datedeparture": " Дата убытия ТС",
	// 	"docnum": "Номер документа",
	// 	"enddateinspectionfact": "Дата окончания досмотра",
	// 	"fitovetcontrol": "Информация о Фито-Вет-Контроле",
	// 	"inningsdt": "Дата подачи ДТ",
	// 	"locationcar": "Статус",
	// 	"notesguard": "Примечания охранника",
	// 	"numbercar": "Номер машины",
	// 	"numberdt": "Номер ДК",
	// 	"numbertrailer": "Номер прицепа",
	// 	"releasedt": "Дата выпуска ДТ",
	// 	"specialistinformation": "Информация о специалисте ТО",
	// 	"statusdt": "Статус ДТ",
	// 	"target": "Импорт/экспорт",
	// }

	// const MyClickableToast = (companyName : string) => (
	// 	<div
	// 		style={{ cursor: 'pointer' }}
	// 		onClick={() => {
	// 			console.log('test')
	// 		}}
	// 		>
	// 		{companyName}
	// 	</div>
	// );

	// const MyClickableInfoToast = (key : string, newValue: string, carName: string, companyName: string, companyId: string) => (
		
	// 	<div
	// 		style={{ cursor: 'pointer'}}
	// 		onClick={() => {
	// 			if(company?.label.main !== companyName) {
	// 				setCompany({
	// 					value: companyId,
	// 						label: {
	// 							main: companyName,
	// 						},
	// 				});
	// 			}
				
	// 			dispatch(setCurrentCompanyName(companyName));
	// 			dispatch(setCurrentCompanyId(companyId))

	// 			// setCurrentOpenCart(`card-${cardId}`);
	// 			// changeCardHandler(
	// 			// 	`card-${cardId}`,
	// 			// 	true
	// 			// );
	// 			navigate('/vehicles')
	// 		}}
	// 		>
	// 		<p>{companyName}</p>
	// 		<p style={{ fontSize: '14px', margin: '3px 0px' }}>Данные по ТС {carName} обновлены</p>
	// 		<p style={{ fontSize: '14px', margin: '5px 0px' }}>{VEHICLES__NAME[key as keyof typeof VEHICLES__NAME] + ': ' + newValue }</p>
	// 	</div>
	// );

	// const handleToastClose = () => {
	// 	dispatch(decNewRequestsCountVehicles())
	// };

	// const [currentVehicles, setCurrentVehicles] = useState<IVehiclesState[]>([])
	// const [oldVehicles, setOldVehicles] = useState<IVehiclesState[]>([])

	// useEffect(() => {

	// 	const  fetchAllVehicles = async (id: number | undefined) => {
	// 		const result = await axios.get(`/spa/vehicles?user=${id}`);

	// 		setCurrentVehicles(result.data.data);
	// 		const newRequestsCount = currentVehicles.length - oldVehicles?.length;

	// 		if(currentVehicles && newRequestsCount > 0) {
	// 			dispatch(incrementNewRequestsCountVehicles());
	// 			setOldVehicles(result.data.data);

	// 			const newVehicleIds = currentVehicles.map((vehicle) => vehicle.id);
	// 			const oldVehicleIds = oldVehicles?.map((vehicle) => vehicle.id) || [];
	// 			const newIds = newVehicleIds.filter((id) => !oldVehicleIds.includes(id));
	// 			for (let i = 1; i <= newRequestsCount; i++) {
	// 				toast(MyClickableToast(`Прибыла новая ТС: ${newIds[i - 1]}`), { type: "success", autoClose: false });
	// 			}
	// 		} else {
	// 			// Находим изменения в каждом товаре
	// 			const handleUpdatedVehicles = (currentVehicles: IVehiclesState[]) => {
	// 				// Находим изменения в каждом товаре
	// 				const changedVehicles = currentVehicles.filter((newVehicle) => {
	// 					const oldVehicle = oldVehicles?.find((v) => v.id === newVehicle.id);
	// 					return !isEqual(oldVehicle, newVehicle);
	// 				});
				
	// 				// Выводим изменения
	// 				changedVehicles.forEach((changedVehicle) => {
	// 					Object.keys(changedVehicle).forEach((key) => {
	// 						const oldValue = oldVehicles?.find((v) => v.id === changedVehicle.id)?.[key as keyof IVehiclesState];
	// 						const newValue = changedVehicle[key as keyof IVehiclesState];

	// 						if (!isEqual(oldValue, newValue)) {
	// 							toast(MyClickableInfoToast(key, newValue, changedVehicle.numbercar, changedVehicle.company_name, changedVehicle.company_id), { type: "success", autoClose: false, onClose: handleToastClose });
	// 							setOldVehicles(currentVehicles);
	// 							dispatch(incrementNewRequestsCountVehicles());
	// 						}
	// 					});
	// 				});
	// 			}
	// 			handleUpdatedVehicles(currentVehicles);
	// 		}
	// 	}


	// 	const intervalId = setInterval(() => fetchAllVehicles(userInfo?.id), 20000);
	// 	return () => {
	// 		clearInterval(intervalId);
	// 	};
	// }, [currentVehicles])

	const sortedDocumentsByName = (
		documents: IDocuments[] | null | undefined,
		company_id: string | undefined
	): IDocuments[] | null | undefined => {
		return documents?.filter((item) => {
			return item.owner_id === company_id;
		});
	};

	const newDocuments = sortedDocumentsByName(allVehiclesDocuments, company?.value);

	useEffect(() => {
		async function fetchUser(id: number | undefined) {
			const result = await axios.get(`/spa/users/${id}`).then((response) => {
				setUser(response.data.data);
			}).catch((error: any) => {
				if(error.response) {
					if(error.response.status === 401 || error.response.status === '401') {
						navigate(AppRoute.Login);
					}
				}
			});
		
		}
		if(employee) {
			fetchUser(employee?.id);
		}
	}, [])
	

	useEffect(() => {
		if(user) {
			user.companyIds.forEach(async (id, index) => {
				const response = await axios.get(`/spa/companies/${id}`);
				if(index === 0) {
					setCompany({
						value: String(response.data.data.id),
						label: {
							main: response.data.data.name
						}
					});
				}
				setCompanies((prevCompanies) => [...prevCompanies, response.data.data]);
			})
		}
	},[user])

	function handleReset(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		setSelectedDateFrom(null);
		setSelectedDateTo(null);
		setSelectedTimeFrom(null);
		setSelectedTimeTo(null);
	}

	const createDate = (data: string): string => {
		if (data) {
			const arrivalDate = new Date(data);
			const day = arrivalDate.getDate();
			const month = arrivalDate.getMonth() + 1;
			const year = arrivalDate.getFullYear();

			const formattedDate = `${day < 10 ? "0" + day : day}.${
				month < 10 ? "0" + month : month
			}.${year}`;

			const hours = arrivalDate.getHours();
			const minutes = arrivalDate.getMinutes();
			const formattedTime = `${hours < 10 ? "0" + hours : hours}:${
				minutes < 10 ? "0" + minutes : minutes
			}`;

			return formattedDate + " / " + formattedTime;
		} else {
			return "";
		}
	};

	const license = useSelector(getLicense);

	useTitle('Документы сотрудника | Терминал');

	return <>
		<StyledHeader>
			<HeaderContainer>
				<LogoLink to={AppRoute.Main} aria-label="На главную">
					<picture>
						<source media={`(max-width: ${Resolution.SM})`} srcSet="/img/logo-mobile.svg"/>

						<img src="/img/logo.svg" width={193} height={45} alt='Группа компаний "Терминал"'/>
					</picture>
				</LogoLink>

				<License>Лицензия <LicenseNumber>{license}</LicenseNumber></License>

				<CustomAutocomplete
					id="company"
					ariaLabel="Компания"
					alternateView
					options={companies.map(item => {
						return {
							value: String(item.id),
							label: {
								main: item.name
							}
						}
					})}
					value={company}
					onChange={(evt, newValue) => setCompany(newValue)}
				/>

				<Account>Сотрудник<AccountName>{userInfo?.name}</AccountName></Account>

				<HeaderIconButton as={Link} to={AppRoute.Logout} icon={HeaderButtonIcon.Logout} title="Выйти">
					<VisuallyHidden>Выйти</VisuallyHidden>
				</HeaderIconButton>

				<BurgerButton>
					<VisuallyHidden>Открыть меню</VisuallyHidden>
				</BurgerButton>

				<FilterButton>
					<VisuallyHidden>Фильтры</VisuallyHidden>
				</FilterButton>
			</HeaderContainer>
		</StyledHeader>

		<Main>
			<MainContainer>
				<DesktopWrapper>
					<StyledNavigation>
						<NavigationLink to={AppRoute.Vehicles}>
							<NavigationIcon width="20" height="20" viewBox="0 0 20 20">
								<use xlinkHref="/img/sprite.svg#note-pencil"></use>
							</NavigationIcon>

							Транспортные средства
						</NavigationLink>

						<NavigationLink to={AppRoute.DocumentsEmployee} $active>
							<NavigationIcon width="20" height="20" viewBox="0 0 20 20">
								<use xlinkHref="/img/sprite.svg#file-search"></use>
							</NavigationIcon>

							Документы
						</NavigationLink>
					</StyledNavigation>
				</DesktopWrapper>

				<MainWrapper>
					<VisuallyHidden as="h1">Документы сотрудника</VisuallyHidden>

					<DesktopWrapper>
						<StyledScreenControls>
							<VisuallyHidden as="h2">Управление</VisuallyHidden>

							<WrapControlsForm>
								<DateFieldset>
									<VisuallyHidden as="legend">Дата</VisuallyHidden>
									<DateFakeLegend aria-hidden={true}>Дата</DateFakeLegend>

									<CustomDatePicker value={selectedDateFrom} onChange={(newValue) => setSelectedDateFrom(newValue)} label="От" width="131px" order={1}/>

									<CustomDatePicker value={selectedDateTo} onChange={(newValue) => setSelectedDateTo(newValue)} label="До" width="131px" order={3}/>
								</DateFieldset>

								<DateFieldset>
									<VisuallyHidden as="legend">Время</VisuallyHidden>
									<DateFakeLegend aria-hidden={true}>Время</DateFakeLegend>

									<CustomTimePicker value={selectedTimeFrom} onChange={(newValue) => setSelectedTimeFrom(newValue)} label="От" width="91px" order={1}/>

									<CustomTimePicker value={selectedTimeTo} onChange={(newValue) => setSelectedTimeTo(newValue)} label="До" width="91px" order={3}/>
								</DateFieldset>

								{/* <IconButton type="submit" variant={ButtonVariant.Main}>
									<svg width="24" height="24" viewBox="0 0 24 24">
										<use xlinkHref="/img/sprite.svg#check-circle"></use>
									</svg>

									<span>Применить</span>
								</IconButton> */}

								<IconButton onClick={handleReset} type="reset" variant={ButtonVariant.Secondary}>
									<svg width="24" height="24" viewBox="0 0 24 24">
										<use xlinkHref="/img/sprite.svg#cross-circle"></use>
									</svg>

									<span>Сбросить</span>
								</IconButton>
							</WrapControlsForm>
						</StyledScreenControls>
					</DesktopWrapper>

					<BigStripedTable>
						<tbody>
							{loading ? <Loader /> : (
								filtredDocuments(newDocuments)?.length ? (
									filtredDocuments(newDocuments)?.map(doc => (
										<tr key={doc.id}>
											<td><time dateTime={createDate(doc.created_at)}>{createDate(doc.created_at)}</time></td>
											{/* <td><CustomLink onClick={() => handleDownload(doc.link, doc.name)}>{doc.name}</CustomLink></td>*/}
											<td><CustomLink href={doc.link} download={doc.name} target="_blank">{doc.name}</CustomLink></td>
										</tr>
									))
								) : <>Документов нет</>
							)}
						</tbody>
					</BigStripedTable>
				</MainWrapper>
			</MainContainer>
		</Main>

		<Footer/>
	</>
}

export default DocumentsEmployeeScreen;
