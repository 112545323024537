import {UserState} from '../../types/state';
import {LoginStatus} from '../../const';
import {createSlice} from '@reduxjs/toolkit';

const initialState: UserState = {
	loginStatus: LoginStatus.Unknown,
	userInfo: null
}

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		changeLoginStatus: (state, action) => {
			state.loginStatus = action.payload;
		},
		changeUserInfo: (state, action) => {
			state.userInfo = action.payload;
		},
		logout: (state) => {
			state.userInfo = null;
			state.loginStatus = LoginStatus.LoggedOut;
		},
		changeUserName: (state, action) => {
			if(state.userInfo) {
				state.userInfo.name = action.payload
			}
		},
	}
});

export const {changeLoginStatus, changeUserInfo, logout, changeUserName} = userSlice.actions;
export default userSlice.reducer;
