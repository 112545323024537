import styled from "styled-components";
import {Resolution} from "../../const";

import {
	Color,
	DEFAULT_TRANSITION,
	DropdownCardTagIcon,
} from "../../const";

type HeaderTagProps = {
	icon: DropdownCardTagIcon
}

type DropdownProps = {
	open: boolean
}

type CardProps = DropdownProps & {
	isDeparted?: boolean,
	borderColor?: string
}

type BodyProps = DropdownProps & {
	transitionDuration: number
}

function handleTagIcon(icon: DropdownCardTagIcon) {
	switch (icon) {
		case DropdownCardTagIcon.Truck:
			return 'url(/img/icon-truck.svg)';
		case DropdownCardTagIcon.Trailer:
			return 'url(/img/icon-trailer.svg)';
		case DropdownCardTagIcon.Calendar:
			return 'url(/img/icon-calendar.svg)';
		case DropdownCardTagIcon.Crosshair:
			return 'url(/img/icon-crosshair.svg)';
	}
}

function handleBorderColor(open: boolean, borderColor: string | undefined) {
	if (open) {
		return Color.Orange;
	}

	if (borderColor) {
		return borderColor;
	}

	return 'rgba(215, 214, 215, 0.64)';
}

// grid-column: ${({open}) => open ? 'span 1' : 'span 1'};
//   	grid-row: ${({open}) => open ? 'span 3' : 'span 1'};
export const Card = styled.article<CardProps>`
	min-height: 86px;
	width: calc(100% / 3 - 37px);
	@media (max-width: ${Resolution.LG}) {
		width: calc(100% / 2 - 15px);
	}
	@media (max-width: ${Resolution.MD}) {
		width: 100%;
	}
	
	border: 1px solid ${({open, borderColor}) => handleBorderColor(open, borderColor)};
	margin-bottom: 20px;

	position: relative;

	border-radius: 5px 5px 0px 0px;
	transition: border-color 200ms linear;
	background-color: ${({isDeparted}) => isDeparted ? 'rgba(83, 79, 87, 0.1)' : '#FFFFFF'};
`;
	// overflow: hidden;

export const Header = styled.header`
	display: flex;
	flex-wrap: wrap;
	gap: 20px 22px;
	padding: 12px 100px 12px 18px;

	background-color: inherit;
`;

export const HeaderTag = styled.p<HeaderTagProps>`
	margin: 0;
	padding-left: ${({icon}) => icon === DropdownCardTagIcon.Crosshair ? '0' : '26px'};
	padding-right: ${({icon}) => icon === DropdownCardTagIcon.Crosshair ? '26px' : '0'};

	position: relative;

	font-weight: 500;
	font-size: 14px;
	line-height: 21px;
	color: ${Color.DarkText};

	&::before {
		width: 20px;
		height: 20px;

		position: absolute;
		top: 50%;
		left: ${({icon}) => icon === DropdownCardTagIcon.Crosshair ? 'unset' : '0'};
		right: ${({icon}) => icon === DropdownCardTagIcon.Crosshair ? '0' : 'unset'};

		content: "";

		background: ${({icon}) => handleTagIcon(icon)} no-repeat center/100%;
		transform: translateY(-50%);
	}
`;

export const Body = styled.div<BodyProps>`
	max-height: ${({open}) => open ? '1000px' : '0'};	
	width: calc(100% + 2px);
	position: absolute;
	top:  ${({open}) => open ? '100%' : '70'};
	right::  ${({open}) => open ? '0' : '0'};
	z-index: ${({open}) => open ? '10' : '-100'};
	opacity: ${({open}) => open ? '1' : '0'};
	border: 1px solid ${Color.Orange};
	border-radius: 0px 0px 5px 5px;
	background-color: #fff;
	margin: 0px -1px;

	transition: all ${({transitionDuration}) => transitionDuration}ms linear;
	overflow: ${({open}) => open ? 'visible' : 'hidden'};
`;
// top:  ${({open}) => open ? '86px' : '70'};

// export const Body = styled.div<BodyProps>`
// 	max-height: ${({open}) => open ? '1000px' : '0'};

// 	transition: all ${({transitionDuration}) => transitionDuration}ms linear;
// 	overflow: hidden;

// 	&::after {
// 		width: 100%;
// 		height: 62px;
// 		display: block;

// 		content: "";
// 	}
// `;

export const ToggleButton = styled.button<DropdownProps>`
	padding: 0;
	width: 28px;
	height: 28px;
	border: none;

	position: absolute;
	right: 16px;
	bottom: 10px;

	border-radius: 50%;
	background: ${Color.Orange} url("/img/icon-arrow.svg") no-repeat center;
	transition: ${DEFAULT_TRANSITION};
	transform: ${({open}) => !open ? 'rotate(-180deg)' : 'none'};
	cursor: pointer;

	&:hover {
		background-color: ${Color.ButtonHover};
	}
`;
