import React, {ChangeEvent, MouseEvent, ReactNode, useEffect, useState} from 'react';
import {AppRoute, ButtonVariant, HeaderButtonIcon, Resolution} from '../../const';
import VisuallyHidden from '../styled/visually-hidden';
import {
	Account, AccountName,
	BurgerButton, FilterButton,
	HeaderContainer,
	IconButton,
	License,
	LicenseNumber,
	LogoLink,
	StyledHeader
} from './style';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getLicense} from '../../store/general/selectors';
import CustomTextField from '../custom-text-field/custom-text-field';
import Button from '../styled/button';
import CustomDialog from '../custom-dialog/custom-dialog';
import {AppDispatch} from '../../store/store';
import {updateLicense} from '../../store/general/general';
import {toast} from 'react-toastify';
import ScreenControlsCompanies from '../screen-controls-companies/screen-controls-companies';
import {GreyWrapper} from '../styled/grey-wrapper';
import {ControlsFlexWrapper} from '../styled/controls-flex-wrapper';
import ScreenControlsEmployees from '../screen-controls-employees/screen-controls-employees';
import {getUserInfo} from '../../store/user/selectors';
import axios from 'axios';
import { changeUserName } from '../../store/user/user';

type HeaderProps = {
	ScreenControls: typeof ScreenControlsCompanies | typeof ScreenControlsEmployees,
	navigation: ReactNode
}

function Header({ScreenControls, navigation}: HeaderProps): JSX.Element {
	const license = useSelector(getLicense);
	const userInfo = useSelector(getUserInfo);
	const [isEditLicenseOpen, setIsEditLicenseOpen] = useState(false);
	const [isError, setIsError] = useState(false);
	const [invalidPassword, setInvalidPassword] = useState<boolean | null>(null);
	const [invalidRepeatPassword, setInvalidRepeatPassword] = useState<boolean | null>(null);

	const [isValidate, setIsValidate] = useState(false);

	const [newLicense, setNewLicense] = useState(license);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isChangeNameOpen, setIsChangeNameOpen] = useState(false);
	const [newName, setNewName] = useState('');
	const [newPass, setNewPass] = useState('');
	const [repeatNewPass, setRepeatNewPass] = useState('');
	const [isFiltersOpen, setIsFiltersOpen] = useState(false);
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	async function refreshLicense() {
		try {
			const result = await axios.post('/spa/license/create',{
				license: newLicense
			});
				setNewLicense(result.data.data.license)
				dispatch(updateLicense(newLicense));
				setIsEditLicenseOpen(false);
				toast('Лицензия успешно обновлена', {type: 'success'});
		} catch (error) {
			toast('Что-то пошло не так. Перезагрузите страницу', {type: 'error'});
		}
	}

	function editLicenseClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		refreshLicense();
	}

	async function editProfileClickHandler(evt : MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		setIsValidate(true);


		if(invalidPassword === false && invalidRepeatPassword === false) {
			try {
				const result = await axios.patch(`/spa/users/${userInfo?.id}/update`, {
					name: `${newName}`,
					password: `${newPass}`,
					password_confirmation: `${repeatNewPass}`
				});
					setNewLicense(result.data.data.license)
					dispatch(updateLicense(newLicense));
					setIsEditLicenseOpen(false);
					toast('Профиль успешно обновлен', {type: 'success'});
					dispatch(changeUserName(newName));
					setIsChangeNameOpen(false)
			} catch (error) {
				setIsError(true);
				toast('Что-то пошло не так. Перезагрузите страницу', {type: 'error'});
			}
		} else {
			setIsError(true);
		}
		
		
	}

	const changeAdminName = () => {
		setIsChangeNameOpen(true);
	};

	useEffect(() => {
		async function fetchLicense() {
			const result = await axios.get("/spa/license").then((response) => {
				dispatch(updateLicense(response.data.data.license));
			}).catch((error: any) => {
				if (error.response) {
					if (
						error.response.status === 401
					) {
						navigate(AppRoute.Login);
					}
				}
			});
		}
		fetchLicense()
	}, []);

	return (
		<StyledHeader>
			<HeaderContainer>
				<LogoLink to={AppRoute.Main} aria-label="На главную">
					<picture>
						<source media={`(max-width: ${Resolution.SM})`} srcSet="/img/logo-mobile.svg"/>

						<img src="/img/logo.svg" width={193} height={45} alt='Группа компаний "Терминал"'/>
					</picture>
				</LogoLink>

				<License>Лицензия <LicenseNumber>{license}</LicenseNumber></License>

				<IconButton
					type="button"
					title="Редактировать"
					icon={HeaderButtonIcon.Edit}
					marginRight="auto"
					onClick={() => setIsEditLicenseOpen(true)}
				>
					<VisuallyHidden>Редактировать</VisuallyHidden>
				</IconButton>

				{/* <Account>Администратор <AccountName>{getNamePatronymic(userInfo?.name)}</AccountName></Account> */}
				<Account><span onClick={changeAdminName} style={{"float": 'left', "cursor": "pointer"}}>Редактировать</span>Администратор <AccountName>{userInfo?.name}</AccountName></Account>


				<IconButton as={Link} to={AppRoute.Logout} icon={HeaderButtonIcon.Logout} title="Выйти">
					<VisuallyHidden>Выйти</VisuallyHidden>
				</IconButton>

				<BurgerButton onClick={() => setIsMenuOpen(true)}>
					<VisuallyHidden>Открыть меню</VisuallyHidden>
				</BurgerButton>
			</HeaderContainer>

			<HeaderContainer>
				<FilterButton onClick={() => setIsFiltersOpen(true)}>
					<VisuallyHidden>Фильтры</VisuallyHidden>
				</FilterButton>
			</HeaderContainer>

			<CustomDialog
				open={isEditLicenseOpen}
				onClose={() => setIsEditLicenseOpen(false)}
				title="Редактирование лицензии"
			>
				<GreyWrapper as="form">
					<CustomTextField
						id="new-license"
						label="Лицензия"
						width="400px"
						value={newLicense}
						onChange={(evt) => setNewLicense(evt.target.value)}
						autoFocus
					/>

					<ControlsFlexWrapper>
						<Button
							type="submit"
							variant={ButtonVariant.Main}
							onClick={editLicenseClickHandler}
							disabled={!newLicense}
						>
							Редактировать
						</Button>
					</ControlsFlexWrapper>
				</GreyWrapper>
			</CustomDialog>

			<CustomDialog
					open={isChangeNameOpen}
					onClose={() => setIsChangeNameOpen(false)}
					title="Редактирование профиля"
				>
					<GreyWrapper as="form">
						<CustomTextField
							id="new-name"
							label="Новое Имя"
							width="400px"
							required
							marginBottom='20px'
							value={newName}
							onChange={(evt) => setNewName(evt.target.value)}
							autoFocus
						/>

						<CustomTextField
							id="new-pass"
							label="Новый пароль(необязательно)"
							width="400px"
							marginBottom='20px'
							value={newPass}
							onChange={(evt) => setNewPass(evt.target.value)}
							error={isError}
							helperText={`${isError ? '' : ''}`}
							validate={isValidate}
							setInvalid={setInvalidPassword}
						/>

						<CustomTextField
							id="new-repeat-pass"
							label="Повторите новый пароль(необязательно)"
							width="400px"
							value={repeatNewPass}
							secondValue={newPass}
							onChange={(evt) =>
								setRepeatNewPass(evt.target.value)
							}
							error={isError}
							helperText={`${isError ? '' : ''}`}
							validate={isValidate}
							setInvalid={setInvalidRepeatPassword}
							disabled={!newPass}
						/>

						<ControlsFlexWrapper>
							<Button
								type="submit"
								variant={ButtonVariant.Main}
								// onClick={editLicenseClickHandler}
								onClick={editProfileClickHandler}
								disabled={!newName || (!!newPass && !repeatNewPass)}
							>
								Редактировать
							</Button>
						</ControlsFlexWrapper>
					</GreyWrapper>
				</CustomDialog>
			<CustomDialog
				open={isMenuOpen}
				onClose={() => setIsMenuOpen(false)}
			>
				{navigation}
			</CustomDialog>

			<CustomDialog
				open={isFiltersOpen}
				onClose={() => setIsFiltersOpen(false)}
			>
				<ScreenControls closeDialog={() => setIsFiltersOpen(false)}/>
			</CustomDialog>
		</StyledHeader>
	);
}

export default Header;
