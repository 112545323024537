import styled from 'styled-components';
import {Color, DEFAULT_TRANSITION, HeaderButtonIcon, Resolution} from '../../const';
import Container from '../styled/container';
import {Link} from 'react-router-dom';

type ButtonProps = {
	icon: HeaderButtonIcon,
	marginRight?: string
}

type AccountProps = {
	marginLeft?: string
}

function handleIconType(icon: HeaderButtonIcon) {
	switch (icon) {
		case HeaderButtonIcon.Edit:
			return 'url(/img/icon-edit.svg)';
		case HeaderButtonIcon.Logout:
			return 'url(/img/icon-logout.svg)';
	}
}

export const StyledHeader = styled.header`
	width: 100%;

	background-color: ${Color.Orange};
`;

export const HeaderContainer = styled(Container)`
	display: flex;
	align-items: center;

	@media (max-width: ${Resolution.SM}) {
		flex-wrap: wrap;
	}
`;

export const LogoLink = styled(Link)`
	display: block;
	margin-right: 48px;
	padding: 12px 0;

	@media (max-width: ${Resolution.MD}) {
		margin-right: 10px;
	}

	@media (max-width: ${Resolution.SM}) {
		margin-right: 7px;
		padding: 2px 0;

		& img {
			width: 43px;
			height: 43px;
		}
	}
`;

export const License = styled.p`
	margin: 0 18px 12px 0;

	font-weight: 500;
	font-size: 8px;
	line-height: 14px;
	color: #FFFFFF;
	text-align: right;

	@media (max-width: ${Resolution.SM}) {
		margin-bottom: 0;
	}
`;

export const LicenseNumber = styled.span`
	display: block;
	max-width: 340px;

	font-size: 22px;
	line-height: 24px;
	font-weight: 600;
	white-space: nowrap;
	text-overflow: ellipsis;

	overflow: hidden;

	@media (max-width: ${Resolution.LG}) {
		font-size: 14px;
		line-height: 18px;
	}
`;

export const IconButton = styled.button<ButtonProps>`
	width: 42px;
	height: 42px;
	border: none;
	margin-right: ${props => props.marginRight};
	flex-shrink: 0;

	background-color: rgba(255, 255, 255, 0.28);
	background-image: ${({icon}) => handleIconType(icon)};
	background-repeat: no-repeat;
	background-position: center;
	border-radius: 50%;
	cursor: pointer;
	transition: ${DEFAULT_TRANSITION};

	&:hover {
		background-color: rgba(255, 255, 255, 0.5);
	}

	@media (max-width: ${Resolution.SM}) {
		display: none;
	}
`;

export const Account = styled.p<AccountProps>`
	margin: 0 47px 12px ${({marginLeft}) => marginLeft};
	padding-left: 60px;

	position: relative;

	font-weight: 500;
	font-size: 8px;
	line-height: 14px;
	color: #FFFFFF;
	text-align: right;

	&::before {
		width: 42px;
		height: 42px;

		position: absolute;
		top: calc(50% + 6px);
		left: 0;

		content: "";

		background: rgba(255, 255, 255, 0.28) url("/img/icon-person.svg") no-repeat center;
		border-radius: 50%;
		transform: translateY(-50%);
	}

	@media (max-width: ${Resolution.MD}) {
		display: none;
	}

	@media (max-width: ${Resolution.LG}) {
		margin-right: 16px;
		padding-left: 0;

		&::before {
			content: none;
		}
	}
`;

export const AccountName = styled.span`
	display: block;

	font-size: 22px;
	line-height: 24px;
	font-weight: 600;
	white-space: nowrap;

	@media (max-width: ${Resolution.LG}) {
		font-size: 14px;
		line-height: 18px;
	}
`;

export const BurgerButton = styled.button`
	padding: 0;
	margin-left: auto;
	width: 28px;
	height: 26px;
	border: none;
	display: none;

	background: transparent url("/img/icon-burger.svg") no-repeat center;

	@media (max-width: ${Resolution.SM}) {
		display: block;
	}
`;

export const FilterButton = styled.button`
	padding: 0;
	margin: 6px 0 14px auto;
	width: 44px;
	height: 42px;
	border: 1px solid #FFFFFF;
	display: none;

	background: #F68A59 url("/img/icon-filter.svg") no-repeat center;
	border-radius: 5px;

	@media (max-width: ${Resolution.SM}) {
		display: block;
		order: 5;
	}
`;
