import styled from 'styled-components';

export const TableList = styled.ul`
	padding: 0;
	margin: 0 0 19px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: fit-content;

	& li {
		display: flex;
		align-items: center;
		gap: 8px;

		font-size: 14px;
		line-height: 17px;
	}
`;
