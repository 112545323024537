import styled from 'styled-components';
import Button from './button';
import {Color, DEFAULT_TRANSITION, Resolution} from '../../const';

export const IconButton = styled(Button)`
	width: 100%;

	& svg {
		display: none;
	}

	@media (min-width: ${Resolution.SM}) {
		width: 53px;
		height: 44px;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;

		color: ${Color.DarkText};

		background-color: #E9E9E9;
		border-radius: 5px;
		transition: ${DEFAULT_TRANSITION};
		cursor: pointer;

		&[type="submit"] {
			color: #FFFFFF;

			background-color: ${Color.Orange};
		}

		&:hover {
			color: #FFFFFF;

			background-color: ${Color.DarkText};
		}

		& span {
			width: 1px;
			height: 1px;
			margin: 0;
			padding: 0;

			position: absolute;

			clip: rect(1px 1px 1px 1px);
			clip: rect(1px, 1px, 1px, 1px);
			overflow: hidden;
			white-space: nowrap;
		}

		& svg {
			display: block;
		}
	}
`;
