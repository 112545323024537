import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICompaniesState } from "../companies/companies";

export interface IManagersState {
    id: number,
    companyIds: number[],
    fullName: string,
    isBlocked: boolean,
    isChecked: boolean
}

export interface ManagersInitialState {
    managers: IManagersState[],
    companyForManager: ICompaniesState[],
    searchValueName: string,
    searchManagerByCompany: string,
    sortBy: string
}

const initialState: ManagersInitialState = {
    managers: [],
    companyForManager: [],
    searchValueName: '',
    searchManagerByCompany: '',
    sortBy: 'all'
}


const ManagersSlice = createSlice({
    name: 'Managers',
    initialState,
    reducers: {
        setAllManagers: (state, action) => {
            state.managers = action.payload
        },
        setAllCompanyForManager: (state, action) => {
            state.companyForManager = action.payload
        },
        addManager: (state, action: PayloadAction<IManagersState>) => {
            state.managers.push(action.payload);
        },
        removeManager: (state, action: PayloadAction<number>) => {
            state.managers = state.managers.filter(manager => manager.id !== action.payload);
        },
        addManagerToCompany: (state, action: PayloadAction<{managerId: number, companyId: number}>) => {
            const managerId = action.payload.managerId;
            const companyId = action.payload.companyId;

            const manager = state.managers.find((man) => man.id === managerId);
            if (manager) {
                manager.companyIds.push(companyId);
            }
        },
        removeManagerFromCompany: (state, action: PayloadAction<{managerId: number | undefined, companyId: number | undefined}>) => {
            const managerId = action.payload.managerId;
            const companyId = action.payload.companyId;

            const manager = state.managers.find((man) => man.id === managerId);

            if(manager) {
                manager.companyIds = manager.companyIds.filter((id) => id !== companyId);
            }            
        },
        blockManager: (state, action) => {
			const manager = state.managers?.slice().find((man) => man.id === action.payload);

			if (manager) {
				if (manager.isChecked) {
					state.managers?.filter((man) => man.isChecked).forEach((man) => man.isBlocked = true);
				} else {
					manager.isBlocked = true;
				}
			}
		},
		unlockManager: (state, action) => {
			const manager = state.managers?.slice().find((man) => man.id === action.payload);

			if (manager) {
				if (manager.isChecked) {
					state.managers?.filter((man) => man.isChecked).forEach((man) => man.isBlocked = false);
				} else {
					manager.isBlocked = false;
				}
			}
		},
        updateSearchManagerByName: (state, action: PayloadAction<string>) => {
        state.searchValueName = action.payload;
        },
        updateSortBy: (state, action: PayloadAction<string>) => {
            state.sortBy = action.payload;
            },
        updateSearchManagerByCompany: (state, action: PayloadAction<string>) => {
        state.searchManagerByCompany = action.payload;
        },
        resetSearchForManager: (state) => {
            state.searchValueName = '';
            state.searchManagerByCompany = '';
            state.sortBy = 'all'
        }
    }
})

export const {
    setAllCompanyForManager,
    updateSortBy,
    resetSearchForManager,
    updateSearchManagerByName,
    updateSearchManagerByCompany,
    setAllManagers,
    addManager,
    removeManager,
    blockManager,
    unlockManager,
    removeManagerFromCompany,
    addManagerToCompany
} = ManagersSlice.actions
export default ManagersSlice.reducer;