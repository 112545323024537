import {Copyright, FooterContainer, FooterLink, Intrid, IntridBold, StyledFooter} from './style';
import {FooterLinkIcon} from '../../const';

function Footer(): JSX.Element {
	return (
		<StyledFooter>
			<FooterContainer>
				<Copyright>© ГК Терминал, 2000 – 2023</Copyright>

				<FooterLink
					href="https://yandex.ru/maps/193/voronezh/house/kholmistaya_ulitsa_64/Z0AYdwVmSUcEQFtrfXp3eH5mYQ==/?ll=39.126023%2C51.669255&z=17.09"
					target="_blank"
					icon={FooterLinkIcon.Compass}
					order={2}
				>
					394038, г. Воронеж, ул. Холмистая, д. 64
				</FooterLink>

				<FooterLink href="tel:88005007353" icon={FooterLinkIcon.Phone} order={1}>8 800 500-73-53</FooterLink>
				<FooterLink href="mailto:info@rterminal.ru" icon={FooterLinkIcon.Email} order={3}>info@rterminal.ru</FooterLink>
				<Intrid href="https://intrid.ru/" target="_blank">made in <IntridBold>INTRID</IntridBold></Intrid>
			</FooterContainer>
		</StyledFooter>
	);
}

export default Footer;
