import styled from "styled-components";
import {Color} from "../../const";

export const StripedTable = styled.table`
	width: 100%;

	border-collapse: collapse;

	& td {
		padding: 4px 12px;

		font-weight: 500;
		font-size: 14px;
		line-height: 21px;
		color: ${Color.DarkText};
		vertical-align: top;
	}

	& tr:nth-of-type(odd) {
		background-color: #FFFFFF;
	}

	& tr:nth-of-type(even) {
		background-color: rgba(220, 220, 220, 0.42);
	}
`;

export const BigStripedTable = styled(StripedTable)`
	margin-top: 24px;

	& td {
		padding: 14px;
	}
`;
