import styled from 'styled-components';
import {Color, DEFAULT_TRANSITION} from '../../const';

export const AddButton = styled.button`
	width: 28px;
	height: 28px;
	border: none;
	padding: 0;

	background: ${Color.Orange} url("/img/icon-plus.svg") no-repeat center;
	border-radius: 50%;
	transition: ${DEFAULT_TRANSITION};
	cursor: pointer;

	&:hover {
		background-color: ${Color.DarkText};
	}
`;
