import styled from "styled-components";
import {Color} from "../../const";

export const DescriptionList = styled.dl`
	background-color: rgba(220, 220, 220, 0.42);
	border-radius: 4px;

	& dt {
		font-size: 14px;
		line-height: 21px;
		color: ${Color.DarkText};
	}

	& span {
		font-size: 14px;
		line-height: 21px;
		color: ${Color.DarkText};
		max-width: 200px
	}

	& dd {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		gap: 14px;

		font-size: 14px;
		line-height: 21px;
		color: ${Color.DarkText};
		font-weight: 600;
		text-align: right;
	}
`;

export const DescriptionWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 14px;
`;

export const DescriptionListWhite = styled(DescriptionList)`
	background-color: #FFFFFF;
`;
