import styled from 'styled-components';
import {Resolution} from '../../const';

export const ScreenTitle = styled.h1`
	margin: 8px 0 30px;

	font-weight: 600;
	font-size: 22px;
	line-height: 27px;

	@media (max-width: ${Resolution.SM}) {
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
	}
`;
