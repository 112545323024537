import styled from 'styled-components';
import {Color} from '../../const';

type ActionButtonProps = {
	marginLeft?: string,
	color?: string
}

export const ActionButton = styled.button<ActionButtonProps>`
	width: 16px;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	padding: 0;
	margin-left: ${props => props.marginLeft};

	color: ${props => props.color ? props.color: 'inherit'};

	background-color: transparent;
	cursor: pointer;

	&:hover {
		color: ${Color.Orange};
	}
`;
