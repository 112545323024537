import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { useEffect, useState, MouseEvent, ChangeEvent } from "react";
import useTitle from "../../hooks/use-title/use-title";
import {
	Account,
	AccountName,
	BurgerButton,
	FilterButton,
	HeaderContainer,
	IconButton as HeaderIconButton,
	License,
	LicenseNumber,
	LogoLink,
	StyledHeader,
} from "../../components/header/style";
import {
	AppRoute,
	ButtonVariant,
	VehicleStatus,
	DropdownCardTagIcon,
	HeaderButtonIcon,
	Resolution,
	VehicleStatusData,
	Color,
} from "../../const";
import VisuallyHidden from "../../components/styled/visually-hidden";
import CustomTextField from "../../components/custom-text-field/custom-text-field";
import { Link, useNavigate } from "react-router-dom";
import { DesktopWrapper } from "../../components/styled/desktop-wrapper";
import {
	NavigationIcon,
	NavigationLink,
	StyledNavigation,
} from "../../components/navigation/style";
import { Main } from "../../components/styled/main";
import { MainContainer } from "../../components/styled/main-container";
import { MainWrapper } from "../../components/styled/main-wrapper";
import { StyledScreenControls } from "../../components/styled/styled-screen-controls";
import { WrapControlsForm } from "../../components/styled/controls-form";
import {
	DateFakeLegend,
	DateFieldset,
} from "../../components/styled/date-fieldset";
import CustomDatePicker from "../../components/custom-date-picker/custom-date-picker";
import CustomTimePicker from "../../components/custom-time-picker/custom-time-picker";
import { IconButton } from "../../components/styled/icon-button";
import { MarginMainGrid } from "../../components/styled/main-grid";
import DropdownCard from "../../components/dropdown-card/dropdown-card";
import { HeaderTag } from "../../components/dropdown-card/style";
import Button from "../../components/styled/button";
import {
	DescriptionList,
	DescriptionListWhite,
	DescriptionWrapper,
} from "../../components/styled/description-list";
import {
	CardButtonContainer,
	CardLabel,
	CheckboxWrapper,
	Fieldset,
	FlexWrapper,
	FormWrapper,
	Legend,
	TitleLegend,
} from "./style";
import Footer from "../../components/footer/footer";
import { GreyWrapper } from "../../components/styled/grey-wrapper";
import { ControlsFlexWrapper } from "../../components/styled/controls-flex-wrapper";
import CustomDialog from "../../components/custom-dialog/custom-dialog";
import CustomAutocomplete from "../../components/custom-autocomplete/custom-autocomplete";
import { Option } from "../../types/option";
import CustomCheckbox from "../../components/custom-checkbox/custom-checkbox";
import { CustomLink } from "../../components/styled/custom-link";
import { StripedTable } from "../../components/styled/striped-table";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../store/user/selectors";
import axios, { AxiosError } from "axios";
import { IEmployeesState } from "../../store/employees/employees";
import { ICompaniesState } from "../../store/companies/companies";
import { AppDispatch } from "../../store/store";
import {
	IVehiclesState,
	addDocumentsVehicles,
	addOldVehicles,
	addVehicles,
	resetAllSearch,
	updateSearchByNumberCar,
	updateSearchBynumbertrailer,
	updateSort,
	incrementNewRequestsCountVehicles,
	decNewRequestsCountVehicles,
	setIndexCurrentOpenCart
} from "../../store/vehicles/vehicles";
import {
	getAllDocumentsVehicles,
	getCurrentCompanyId,
	getCurrentCompanyName,
	getIndexCurrentOpenCurd,
	getSearchNumberCar,
	getSearchNumberTrailer,
	getSearchStatus,
	getVechiles,
	newRequestsCountVehicles,
} from "../../store/vehicles/selector";
import { getLicense } from "../../store/general/selectors";
import { IDocuments } from "../../types/state";
import { Loader } from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import { updateLicense } from "../../store/general/general";
import { ActionButton } from "../../components/styled/action-button";
import { TextField } from "@mui/material";
import {includes, isEqual} from 'lodash';
import './vehicles.css'

const defaultCardData = {
	article: "",
	party: "",
	amount: "",
	measure: "",
	note: "",
	height: "",
};

function VehiclesScreen(): JSX.Element {
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	const allVehiclesDocuments = useSelector(getAllDocumentsVehicles);

	const [dispatchDate, setDispatchDate] = useState("");
	const [dispatchTime, setDispatchTime] = useState("");
	const [dispatchMark, setDispatchMark] = useState("");
	const [dispatchNumber, setDispatchNumber] = useState("");
	const [dispatchTrailer, setDispatchTrailer] = useState("");

	const [dispatchMover, setDispatchMover] = useState("");
	const [dispatchDriver, setDispatchDriver] = useState("");
	const [dispatchSeriyaPassport, setDispatchSeriyaPassport] = useState("");
	const [dispatchNumberPassport, setDispatchNumberPassport] = useState("");
	const [dispatchDatePassport, setDispatchDatePassport] = useState("");
	const [dispatchSectionPassport, setDispatchSectionPassport] = useState("");
	const [dispatchAddText, setDispatchAddText] = useState("");
	const [dispatchSealing, setDispatchSealing] = useState(false);
	const [amountGoods, setAmountGoods] = useState([1]);
	const [dispatchGoods, setDispatchGoods] = useState([defaultCardData]);
	const [tempDispatchGoods, setTempDispatchGoods] = useState([
		...dispatchGoods,
	]);

	const [itemValue, setItemValue] = useState("");
	const [amountValue, setAmountValue] = useState("");
	const [weightValue, setWeightValue] = useState("");
	const [addTextValue, setAddTextValue] = useState("");
	const [deliverDocument, setDeliverDocument] = useState("");

	const [currentNumberCar, setCurrentNumberCar] = useState("");
	const [currentTrailerCar, setCurrentTrailerCar] = useState("");
	const currentIndexOpenCard = useSelector(getIndexCurrentOpenCurd);

	const [currentOpenCart, setCurrentOpenCart] = useState(currentIndexOpenCard ? `card-${currentIndexOpenCard}` : '');
	const [numberDt, setNumberDt] = useState("");

	const [loading, setLoading] = useState(true);
	const [numberCar, setNumberCar] = useState("");
	const [numberTrailer, setNumberTrailer] = useState("");
	const [status, setStatus] = useState("");
	const [selectedTimeFrom, setSelectedTimeFrom] = useState<any>(null);
	const [selectedTimeTo, setSelectedTimeTo] = useState<any>(null);
	const [selectedDateFrom, setSelectedDateFrom] = useState<any>(null);
	const [selectedDateTo, setSelectedDateTo] = useState<any>(null);
	const [isSendOrderOpen, setIsSendOrderOpen] = useState(false);
	const [isInspectionOpen, setIsInspectionOpen] = useState(false);
	const [file, setFile] = useState<any>(null);

	const [isStorage, setIsStorage] = useState(false);
	const [isBailor, setIsBailor] = useState(false);
	const [docName, setDocName] = useState("");
	const [notificationDate, setNotificationDate] = useState<any>(null);
	const [addTextForView, setAddTextForView] = useState("");

	// const currentCompanyName = useSelector(getCurrentCompanyName);
	// const currentCompanyId = useSelector(getCurrentCompanyId);

	const [fullName, setFullName] = useState("");
	const [passportSeriya, setPassportSeriya] = useState("");
	const [passportNumber, setPassportNumber] = useState("");
	const [passportDate, setPassportDate] = useState<any>("");
	const [passportExtradition, setPassportExtradition] = useState("");
	const [isGoodDelivery1Open, setIsGoodDelivery1Open] = useState(false);
	const [isGoodFillingOpen, setIsGoodFillingOpen] = useState(false);
	const [isDownloadDocumentOpen, setIsDownloadDocumentOpen] = useState(false);
	const [isAddOrderOpen, setIsAddOrderOpen] = useState(false);
	const [open, setOpen] = useState<string | false>(false);
	const [vehicleId, setVehiclesId] = useState<string>("");
	const [user, setUser] = useState<IEmployeesState | null>(null);
	const [companies, setCompanies] = useState<ICompaniesState[] | []>([]);
	const [company, setCompany] = useState<Option | null>({
		value: "",
		label: {
			main: "Загрузка...",
		},
	});
	const userName = useSelector(getUserInfo);
	const userInfo = useSelector(getUserInfo);
	const vechilesCurrent = useSelector(getVechiles);
	const employee = useSelector(getUserInfo);
	const license = useSelector(getLicense);
	const searchNumberCar = useSelector(getSearchNumberCar);
	const searchNumberTrailer = useSelector(getSearchNumberTrailer);
	const searchStatus = useSelector(getSearchStatus);
	const newVehiclesCount = useSelector(newRequestsCountVehicles);

	function sortVechilesByDate(vechiles: IVehiclesState[] | null | undefined): IVehiclesState[] | null {
		if (vechiles) {
			const sortedOrderByDate = [...vechiles].sort((a: IVehiclesState, b: IVehiclesState) => {
			return new Date(b.arrivaldate).getTime() - new Date(a.arrivaldate).getTime();
		});
		return sortedOrderByDate;
		} else {
			return null;
		}
	}
	const vechiles = sortVechilesByDate(vechilesCurrent);

	const [currentVehicles, setCurrentVehicles] = useState<IVehiclesState[]>([])
	const [oldVehicles, setOldVehicles] = useState<IVehiclesState[]>([])

	const VEHICLES__NAME = {
		"arrivaldate": "Дата прибытия ТС",
		"closingdatedelivery": "Дата закрытия доставки",
		"dateappointmentinspection": "Дата назначения проведения досмотра",
		"datedeparture": " Дата убытия ТС",
		"docnum": "Номер документа",
		"enddateinspectionfact": "Дата окончания досмотра",
		"fitovetcontrol": "Информация о Фито-Вет-Контроле",
		"inningsdt": "Дата подачи ДТ",
		"locationcar": "Статус",
		"notesguard": "Примечания охранника",
		"numbercar": "Номер машины",
		"numberdt": "Номер ДК",
		"numbertrailer": "Номер прицепа",
		"releasedt": "Дата выпуска ДТ",
		"specialistinformation": "Информация о специалисте ТО",
		"statusdt": "Статус ДТ",
		"target": "Импорт/экспорт",
	}

	const MyClickableInfoToast = (key : string, newValue: string, carName: string, companyName: string, companyId: string, cardId: string) => (
		
		<div
			style={{ cursor: 'pointer'}}
			onClick={() => {
				if(company?.label.main !== companyName) {
					setCompany({
						value: companyId,
							label: {
								main: companyName,
							},
					});
				}
				
				dispatch(setIndexCurrentOpenCart(cardId))
				setCurrentOpenCart(`card-${cardId}`);
				changeCardHandler(
					`card-${cardId}`,
					true
				);
			}}
			>
			<p>{companyName}</p>
			<p style={{ fontSize: '14px', margin: '3px 0px' }}>Данные по ТС {carName} обновлены</p>
			<p style={{ fontSize: '14px', margin: '5px 0px' }}>{VEHICLES__NAME[key as keyof typeof VEHICLES__NAME] + ': ' + newValue }</p>
		</div>
	);

	const handleToastClose = () => {
		dispatch(decNewRequestsCountVehicles())
	};

	const MyClickableToast = (companyName : string, numbercar: string, companyId: string) => (
		<div
			style={{ cursor: 'pointer' }}
			onClick={() => {
				if(company?.label.main !== companyName) {
					setCompany({
						value: companyId,
							label: {
								main: companyName,
							},
					});
				}
			}}
			>
			<p>{companyName}</p>
			<p style={{ fontSize: '14px', margin: '3px 0px' }}>Прибыла новое ТС: {numbercar}</p>
		</div>
	);

	useEffect(() => {
		async function fetchLicense() {
			const result = await axios
				.get("/spa/license")
				.then((response) => {
					dispatch(updateLicense(response.data.data.license));
				})
				.catch((error: any) => {
					if (error.response) {
						if (error.response.status === 401) {
							navigate(AppRoute.Login);
						}
					}
				});
		}
		fetchLicense();
	}, []);

	const filterVehicles = (
		vechiles: IVehiclesState[] | null | undefined,
		searchNumberCar: string,
		searchNumberTrailer: string,
		selectedDateFrom: string,
		selectedDateTo: string,
		selectedTimeFrom: any,
		selectedTimeTo: any,
		searchStatus: string
	): IVehiclesState[] | undefined => {
		return vechiles
			?.filter((vehicle) => {
				if (searchNumberCar !== "") {
					return vehicle.numbercar
						.toLowerCase()
						.includes(searchNumberCar.toLowerCase());
				} else {
					return vehicle;
				}
			})
			.filter((item) => {
				if (searchNumberTrailer !== "") {
					return item.numbertrailer
						.toLowerCase()
						.includes(searchNumberTrailer.toLowerCase());
				} else {
					return item;
				}
			})
			.filter((item) => {
				if (searchStatus !== "") {
					return (
						item.locationcar === searchStatus + "а" ||
						item.locationcar === searchStatus
					);
				} else {
					return item;
				}
			})
			.filter((item) => {
				if (selectedDateFrom !== null && selectedDateTo !== null) {
					return (
						new Date(item.arrivaldate).getTime() >=
							new Date(selectedDateFrom).getTime() &&
						new Date(item.arrivaldate).getTime() <=
							new Date(selectedDateTo).getTime()
					);
				} else {
					return item;
				}
			})
			.filter((item) => {
				if (selectedTimeFrom !== null && selectedTimeTo !== null) {
					const currenttime =
						new Date(item.arrivaldate).getUTCHours() * 60 +
						new Date(item.arrivaldate).getMinutes();
					return (
						currenttime >=
							selectedTimeFrom.$H * 60 + selectedTimeFrom.$m &&
						currenttime <=
							selectedTimeTo.$H * 60 + selectedTimeTo.$m
					);
				} else {
					return item;
				}
			});
	};
	const filtredVehicles = filterVehicles(
		vechiles,
		searchNumberCar,
		searchNumberTrailer,
		selectedDateFrom,
		selectedDateTo,
		selectedTimeFrom,
		selectedTimeTo,
		searchStatus
	);

	const sortedDocumentsByVehicles = (
		documents: IDocuments[] | null | undefined,
		vehicles_id: string | undefined
	): IDocuments[] | null | undefined => {
		return documents?.filter((item) => {
			return item.owner_id === String(vehicles_id);
		});
	};

	const vehicleRender =
		searchNumberCar ||
		searchNumberTrailer ||
		selectedDateFrom ||
		selectedDateTo ||
		selectedTimeFrom ||
		selectedTimeTo ||
		searchStatus
			? filtredVehicles
			: vechiles;

	const handleUnauthorizedError = () => {
		navigate(AppRoute.Login);
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			const selectedFile = e.target.files[0];
			if (selectedFile && selectedFile.type === "application/pdf") {
				setFile(selectedFile);
			} else {
				toast("Для загрузки допускаются только файлы в формате pdf", {
					type: "warning",
				});
				e.target.value = "";
			}
		}
	};

	async function uploadFileHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();

		try {
			const companyId = company?.value ? company?.value : "";
			const formData = new FormData();
			formData.append("id", companyId);
			formData.append("type", "a");
			formData.append("file", file);
			const response = await axios.post("/spa/orders/upload", formData);
			setIsAddOrderOpen(false);
			toast("Заявка успешно отправлена", {
				type: "success",
			});
		} catch (error: any) {
			if(error.response.status === 422) {
				toast("Размер файла не должен превышать 5КБ", {
					type: "warning",
				});
			} else {
				toast("Что-то пошло не так. Перезагрузите страницу", {
				type: "error",
			});
			}
			
		}
	}

	const removeGood = (index: number) => {
		const updatedAmountGoods = [...amountGoods];
		updatedAmountGoods.splice(index, 1);
		setAmountGoods(updatedAmountGoods);

		const updatedDispatchGoods = [...dispatchGoods];
		updatedDispatchGoods.splice(index, 1);
		setDispatchGoods(updatedDispatchGoods);
	};

	useEffect(() => {
		async function fetchUser(id: number | undefined) {
			const result = await axios
				.get(`/spa/users/${id}`)
				.then((response) => {
					setUser(response.data.data);
				})
				.catch((error: AxiosError) => {
					if (error.response) {
						if (error.response.status === 401) {
							handleUnauthorizedError();
						}
					}
				});
		}
		if (employee) {
			fetchUser(employee?.id);
		}
	}, []);

	useEffect(() => {
		setLoading(true);
		async function fetchVehicles(id: string | undefined) {
			const result = await axios.get(`/spa/vehicles?company=${id}`);
			dispatch(addVehicles(result.data.data));
			setLoading(false);
		}

		if (company?.value !== "") {
			fetchVehicles(company?.value);
		}

		const intervalId = setInterval(() => fetchVehicles(company?.value), 10000);
		return () => {
			clearInterval(intervalId);
		};
	}, [company]);

	useEffect(() => {
		if (user) {
			user.companyIds.forEach(async (id, index: number | string) => {
				const response = await axios.get(`/spa/companies/${id}`);
				setCompanies((prevCompanies) => [
					...prevCompanies,
					response.data.data,
				]);
				if (index === 0) {
					setCompany({
						value: String(response.data.data.id),
						label: {
							main: response.data.data.name,
						},
					});
				}
			});
		}
	}, [user]);

	useEffect(() => {
		const fetchDocuments = async () => {
			const result = await axios.get("/spa/documents", {
				params: { for: "vechile" },
			});
			dispatch(addDocumentsVehicles(result.data.data));
		};
		try {
			fetchDocuments();
		} catch (error) {
			toast("Что-то пошло не так. Перезагрузите страницу", {
				type: "error",
			});
		}
	}, []);

	function handleReset(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		dispatch(resetAllSearch());
		setNumberCar("");
		setNumberTrailer("");
		setStatus("");
		setSelectedDateFrom(null);
		setSelectedDateTo(null);
		setSelectedTimeFrom(null);
		setSelectedTimeTo(null);
	}

	const vehicleStatusOptions = Object.values(VehicleStatusData).map(
		(value) => {
			return {
				value: value.content,
				label: {
					main: value.content,
				},
			};
		}
	);

	const addNewGood = () => {
		setAmountGoods((prevAmount) => [...prevAmount, prevAmount.length + 1]);
		setDispatchGoods((prevDispatchGoods) => [
			...prevDispatchGoods,
			defaultCardData,
		]);
	};
	const handleSaveChanges = () => {
		setDispatchGoods(tempDispatchGoods);
	};

	useEffect(() => {

		const  fetchAllVehicles = async (id: number | undefined) => {
			try {
				const result = await axios.get(`/spa/vehicles?user=${id}`);
				setOldVehicles(result.data.data)
				dispatch(addOldVehicles(result.data.data));

			} catch (error: any) {
				if (error.response && error.response.status === 401) {
					navigate(AppRoute.Login);
				}
			}

		}

		if(user) {
			fetchAllVehicles(user?.id);
		}
		
	},[user])

	useEffect(() => {

		const  fetchAllVehicles = async (id: number | undefined) => {
			const result = await axios.get(`/spa/vehicles?user=${id}`);

			setCurrentVehicles(result.data.data);
			const newRequestsCount = currentVehicles.length - oldVehicles?.length;

			if(currentVehicles && newRequestsCount > 0) {
				setOldVehicles(result.data.data);

				const newVehicleIds = currentVehicles.map((vehicle) => vehicle.id);
				const oldVehicleIds = oldVehicles?.map((vehicle) => vehicle.id) || [];
				const differentCar = newVehicleIds.filter((id) => !oldVehicleIds.includes(id));
				for (let i = 1; i <= newRequestsCount; i++) {
					const vechile = currentVehicles.filter(item => differentCar.includes(item.id));
					toast(MyClickableToast(vechile[i - 1].company_name, vechile[i - 1].numbercar, vechile[i - 1].company_id), { type: "success", autoClose: false, onClose: handleToastClose });
					dispatch(incrementNewRequestsCountVehicles());
				}
			} else {
				const handleUpdatedVehicles = (currentVehicles: IVehiclesState[]) => {
					const changedVehicles = currentVehicles.filter((newVehicle) => {
						const oldVehicle = oldVehicles?.find((v) => v.id === newVehicle.id);
						return !isEqual(oldVehicle, newVehicle);
					});
				
					changedVehicles.forEach((changedVehicle) => {
						Object.keys(changedVehicle).forEach((key) => {
							const oldValue = oldVehicles?.find((v) => v.id === changedVehicle.id)?.[key as keyof IVehiclesState];
							const newValue = changedVehicle[key as keyof IVehiclesState];

							if (!isEqual(oldValue, newValue)) {
								toast(MyClickableInfoToast(key, newValue, changedVehicle.numbercar, changedVehicle.company_name, changedVehicle.company_id, changedVehicle.id), { type: "success", autoClose: false, onClose: handleToastClose });
								setOldVehicles(currentVehicles);
								dispatch(incrementNewRequestsCountVehicles());
							}
						});
					});
				}
				handleUpdatedVehicles(currentVehicles);
			}
		}


		const intervalId = setInterval(() => fetchAllVehicles(userInfo?.id), 10000);
		return () => {
			clearInterval(intervalId);
		};
	}, [currentVehicles])

	const createDateForPassport = (data: string): string => {
		if (data) {
			const arrivalDate = new Date(data);
			const day = arrivalDate.getDate();
			const month = arrivalDate.getMonth() + 1;
			const year = arrivalDate.getFullYear();
			const formattedDate = `${day < 10 ? "0" + day : day}.${
				month < 10 ? "0" + month : month
			}.${year}`;
			return formattedDate;
		} else {
			return "";
		}
	};

	const createTimeForPassport = (time: string): string => {
		if (time) {
			const arrivalTime = new Date(time);

			const hours = arrivalTime.getUTCHours();
			const minutes = arrivalTime.getMinutes();
			const formattedTime = `${hours < 10 ? "0" + hours : hours}:${
				minutes < 10 ? "0" + minutes : minutes
			}`;
			return formattedTime;
		} else {
			return "";
		}
	};
	const createDate = (data: string): string => {
		if (data) {
			const arrivalDate = new Date(data);
			const day = arrivalDate.getDate();
			const month = arrivalDate.getMonth() + 1;
			const year = arrivalDate.getFullYear();

			const formattedDate = `${day < 10 ? "0" + day : day}.${
				month < 10 ? "0" + month : month
			}.${year}`;

			const hours = arrivalDate.getUTCHours();
			const minutes = arrivalDate.getMinutes();
			const formattedTime = `${hours < 10 ? "0" + hours : hours}:${
				minutes < 10 ? "0" + minutes : minutes
			}`;

			return formattedDate + " / " + formattedTime;
		} else {
			return "";
		}
	};

	pdfMake.vfs = pdfFonts.pdfMake.vfs;

	const docDefinition: any = {
		content: [
			{ text: "Приложение №2", alignment: "right" },
			{
				text: "Заявка \n на выгрузку товара на СВХ АО «Терминал Центр»",
				style: "title",
			},
			{ text: `${company?.label.main}`, style: "name" },
			{ text: "(наименование организации)", style: "nameDesc" },
			{ text: "_".repeat(95), style: "underline" },
			{
				columns: [
					{
						width: "auto",
						text: "в лице",
						style: "indent",
						marginTop: 35,
					},
					{ width: "auto", text: `${userName?.name}`, marginTop: 35 },
				],
			},
			{
				text: "_".repeat(88),
				style: "underline",
				alignment: "right",
				marginTop: -12,
			},
			{ text: "(Ф.И.О.)", style: "nameDesc" },
			{
				columns: [
					{
						width: "auto",
						text: "действующего на основании",
						style: "indent",
						marginTop: 20,
					},
					{
						width: "auto",
						text: `${deliverDocument}`,
						marginTop: 20,
					},
				],
			},
			{
				text: "(устава/приказа №___от______/доверенности №____от____)",
				style: "nameDesc",
				marginLeft: 180,
			},
			{ text: "_".repeat(65), style: "underline", alignment: "right" },
			{
				text: "просит выгрузить названный ниже товар на хранение на территории складского комплекса.",
				fontSize: 11,
				marginTop: 20,
			},
			{
				columns: [
					{
						width: 160,
						text: "Наименование товара",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${itemValue}`,
						marginLeft: 40,
						marginTop: 15,
						height: 60,
					},
				],
			},
			{ text: "_".repeat(60), marginTop: -10, alignment: "right" },
			{
				columns: [
					{
						width: 160,
						text: "Кол-во грузовых мест",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${amountValue}`,
						marginLeft: 42,
						marginTop: 15,
					},
				],
			},
			{ text: "_".repeat(60), marginTop: -10, alignment: "right" },
			{
				columns: [
					{
						width: 160,
						text: "Вес брутто, кг",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: '"auto',
						text: `${weightValue}`,
						marginLeft: 42,
						marginTop: 15,
					},
				],
			},
			{ text: "_".repeat(60), marginTop: -10, alignment: "right" },
			{
				columns: [
					{
						width: 160,
						text: "Государственный номер а/с",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${currentNumberCar} / ${currentTrailerCar}`,
						marginLeft: 42,
						marginTop: 15,
					},
				],
			},
			{ text: "_".repeat(60), marginTop: -10, alignment: "right" },
			{
				columns: [
					{
						width: 160,
						text: "Подпись, Ф.И.О.\n уполномоченного лица ",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${userName?.name}`,
						marginLeft: 42,
						marginTop: 15,
						alignment: "left",
					},
				],
			},
			{ text: "_".repeat(60), marginTop: -25, alignment: "right" },
			{ text: "", style: "nameDescTwo", marginTop: 1 },
			{
				columns: [
					{
						width: 160,
						text: "Дата",
						marginTop: 35,
					},
					{
						width: "auto",
						text: `${createDateForPassport(
							new Date().toISOString()
						)}`,
						marginLeft: 42,
						marginTop: 35,
					},
				],
			},
			{ text: "_".repeat(60), marginTop: -12, alignment: "right" },
			{
				columns: [
					{
						width: 160,
						text: "Особые отметки",
						marginTop: 15,
					},
					{
						width: "auto",
						text: `${addTextValue}`,
						marginLeft: 42,
						marginTop: 15,
					},
				],
			},
			{ text: "_".repeat(60), marginTop: -12, alignment: "right" },
			{ text: "Заявка согласована ", marginTop: 35 },
			{
				columns: [
					{
						width: 170,
						text: "Начальник отдела складской логистики",
					},
					{ width: 200, text: "", marginLeft: 32, alignment: "left" },
					{
						width: "auto",
						text: ``,
						marginLeft: 80,
					},
				],
			},
			{ text: "_".repeat(60), marginTop: -25, alignment: "right" },
		],
		styles: {
			title: {
				fontSize: 14,
				bold: true,
				alignment: "center",
				marginBottom: 20,
				marginTop: 55,
			},
			name: {
				fontSize: 14,
				alignment: "center",
			},
			nameDesc: {
				fontSize: 10,
				alignment: "center",
				marginBottom: 5,
			},
			nameDescTwo: {
				fontSize: 10,
				alignment: "center",
				marginRight: -160,
				marginTop: -17,
			},
			indent: {
				marginRight: 160,
			},
			titleDesc: {
				bold: false,
			},
			mainInfo: {
				alignment: "center",
				marginTop: -10,
				marginRight: -200,
			},
			underline: {
				marginTop: -28,
			},
		},
	};

	const docDefinitionForExtradition: any = {
		content: [
			{ text: "Приложение 4", alignment: "right" },
			{
				text: "Заявка \n на выдачу товара с СВХ АО «Терминал-Центр» \n лицу обладающему полномочиями в отношении товаров.",
				style: "title",
			},
			{
				columns: [
					{
						width: 160,
						text: "Дата и время погрузки",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${createDateForPassport(dispatchDate)} / ${createTimeForPassport(dispatchTime)}`,
						marginLeft: 40,
						marginTop: 15,
						height: 60,
					},
				],
			},
			{ text: "_".repeat(58), marginTop: -10, alignment: "right" },
			{
				columns: [
					{
						width: 170,
						text: "Наименование организации",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${company?.label.main}`,
						marginLeft: 30,
						marginTop: 15,
					},
				],
			},
			{ text: "_".repeat(58), marginTop: -10, alignment: "right" },
			{
				columns: [
					{
						width: 160,
						text: "№ ГТД",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${numberDt}`,
						marginLeft: 42,
						marginTop: 15,
					},
				],
			},
			{ text: "_".repeat(58), marginTop: -10, alignment: "right" },
			dispatchGoods.map((item, i: number) => {
				return [
					{text: `Товар ${i + 1}`, marginTop: 15, alignment: "left", bold: true},
					{
						columns: [
							{
								width: 160,
								text: "Наименование товара",
								marginTop: 5,
								style: "titleDesc",
							},
							{
								width: "auto",
								text: `${item.article}`,
								marginLeft: 42,
								marginTop: 5,
							},
						],
					},
					{ text: "_".repeat(58), marginTop: -10, alignment: "right" },
					{
						columns: [
							{
								width: 160,
								text: "Кол-во грузовых мест",
								marginTop: 5,
								style: "titleDesc",
							},
							{
								width: "auto",
								text: `${item.amount}`,
								marginLeft: 42,
								marginTop: 5,
							},
						],
					},
					{ text: "_".repeat(58), marginTop: -10, alignment: "right" },
					{
						columns: [
							{
								width: 160,
								text: "Ед. измерения",
								marginTop: 5,
								style: "titleDesc",
							},
							{
								width: "auto",
								text: `${item.measure}`,
								marginLeft: 42,
								marginTop: 5,
							},
						],
					},
					{ text: "_".repeat(58), marginTop: -10, alignment: "right" },
					{
						columns: [
							{
								width: 160,
								text: "Вес брутто, кг",
								marginTop: 5,
								style: "titleDesc",
							},
							{
								width: "auto",
								text: `${item.height}`,
								marginLeft: 42,
								marginTop: 5,
							},
						],
					},
					{ text: "_".repeat(58), marginTop: -10, alignment: "right" },
				{
					columns: [
						{
							width: 160,
							text: "Партия",
							marginTop: 5,
							style: "titleDesc",
						},
						{
							width: "auto",
							text: `${item.party}`,
							marginLeft: 42,
							marginTop: 5,
						},
					],
				},
				{ text: "_".repeat(58), marginTop: -10, alignment: "right" },
				{
					columns: [
						{
							width: 160,
							text: "Примечание",
							marginTop: 5,
							style: "titleDesc",
						},
						{
							width: "auto",
							text: item.note ? `${item.note}` : '',
							marginLeft: 42,
							marginTop: 5,
						},
					],
				},
				{ text: "_".repeat(58), marginTop: -10, alignment: "right" },
				]
			}),
			{
				columns: [
					{
						width: 170,
						text: "Наименование перевозчика",
						marginTop: 25,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${dispatchMover}`,
						marginLeft: 32,
						marginTop: 25,
					},
				],
			},
			{ text: "_".repeat(58), marginTop: -10, alignment: "right" },
			{
				columns: [
					{
						width: 180,
						text: "Марка, государственный номер а/с",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${dispatchMark} / ${dispatchNumber} / ${dispatchTrailer}`,
						marginLeft: 22,
						marginTop: 15,
					},
				],
			},
			{ text: "_".repeat(58), marginTop: -23, alignment: "right" },
			{
				columns: [
					{
						width: 160,
						text: "ФИО водителя а/с",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${dispatchDriver}`,
						marginLeft: 42,
						marginTop: 15,
					},
				],
			},
			{ text: "_".repeat(58), marginTop: -10, alignment: "right" },
			{
				columns: [
					{
						width: 180,
						text: "Документ удостоверяющий личность (№ паспорта, серия)",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${dispatchSeriyaPassport} / ${dispatchNumberPassport}`,
						marginLeft: 22,
						marginTop: 15,
					},
				],
			},
			{ text: "_".repeat(58), marginTop: -23, alignment: "right" },
			{
				columns: [
					{
						width: 160,
						text: "Подпись, Ф.И.О. уполномоченного лица",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${userName?.name}`,
						marginLeft: 42,
						marginTop: 15,
					},
				],
			},
			{ text: "_".repeat(58), marginTop: -23, alignment: "right" },
			{
				columns: [
					{
						width: 160,
						text: "Особые отметки",
						marginTop: 15,
						style: "titleDesc",
					},
					{
						width: "auto",
						text: `${dispatchAddText}`,
						marginLeft: 42,
						marginTop: 15,
					},
				],
			},
			dispatchSealing
				? {
						columns: [
							{
								width: 160,
								text: "Опломбирование авто",
								marginTop: 15,
							},
							{
								width: "auto",
								text: "Да",
								marginLeft: 42,
								marginTop: 15,
							},
						],
				}
				: {
						columns: [
							{
								width: 160,
								text: "Опломбирование авто",
								marginTop: 15,
							},
							{
								width: "auto",
								text: "Нет",
								marginLeft: 42,
								marginTop: 15,
							},
						],
				},
			{
				columns: [
					{
						width: 160,
						text: "Бухгалтер по реализации ",
						marginTop: 15,
						style: "titleDesc",
						bold: true,
					},
					{
						width: "auto",
						text: "",
						marginLeft: 42,
						marginTop: 15,
					},
				],
			},
			{
				text: "подпись                                                                   расшифровка",
				style: "nameDesc",
				marginLeft: 160,
			},
			{ text: "_".repeat(58),marginTop: -28,alignment: "right" },
			{
				columns: [
					{
						width: 160,
						text: "Начальник отдела складской логистики",
						marginTop: 15,
						style: "titleDesc",
						bold: true,
					},
					{
						width: "auto",
						text: "",
						marginLeft: 42,
						marginTop: 15,
					},
				],
			},
			{
				text: "подпись                                                                   расшифровка",
				style: "nameDesc",
				marginLeft: 160,
				marginTop: -15
			},
			{ text: "_".repeat(58),marginTop: -28,alignment: "right" },
		],
		styles: {
			title: {
				fontSize: 14,
				bold: true,
				alignment: "center",
				marginBottom: 20,
				marginTop: 50,
			},
			name: {
				fontSize: 14,
				alignment: "center",
			},
			nameDesc: {
				fontSize: 10,
				alignment: "center",
				marginBottom: 5,
			},
			nameDescTwo: {
				fontSize: 10,
				alignment: "center",
				marginRight: -160,
				marginTop: -17,
			},
			indent: {
				marginRight: 160,
			},
			titleDesc: {
				bold: false,
			},
			underline: {
				marginTop: -28,
			},
			mainInfo: {
				alignment: "center",
				marginTop: -10,
				marginRight: -200,
			},
		},
	};

	const docDefinitionForView: any = {
		content: [
			{
				text: "Заявка \n на совершение операции для проведения таможенного досмотра/осмотра»",
				style: "title",
			},
			{ text: `${company?.label.main}`, style: "name" },
			{
				text: "(наименование организации Поклажедателя)",
				style: "nameDesc",
			},
			{ text: "_".repeat(95), marginTop: -30 },
			{
				columns: [
					{
						width: "auto",
						text: "в лице",
						style: "indent",
						marginTop: 20,
					},
					{ width: "auto", text: `${userName?.name}`, marginTop: 18 },
				],
			},
			{ text: "(Ф.И.О.)", style: "nameDesc" },
			{ text: "_".repeat(88), style: "underline", alignment: "right" },
			{
				columns: [
					{
						width: "auto",
						text: "действующего на основании",
						style: "indent",
						marginTop: 20,
					},
					{ width: "auto", text: `${docName}`, marginTop: 18 },
				],
			},
			{
				text: "(устава, приказа, доверенности)",
				style: "nameDesc",
				marginLeft: 180,
			},
			{ text: "_".repeat(65), style: "underline", alignment: "right" },
			{
				text: `просит совершить операции с товаром необходимые для проведения таможенного досмотра/осмотра по ДТ ${numberDt}, уведомлению б/н от ${createDateForPassport(
					notificationDate
				)}. Оплату услуг гарантируем.`,
				marginTop: 20,
			},
			{
				columns: [
					{
						width: "auto",
						text: "Участие в таможенном \n досмотре / осмотре:",
						marginTop: 15,
						style: "titleDesc",
					},
					isStorage
						? {
								width: "auto",
								text: "√",
								fontSize: 30,
								marginLeft: 30,
								marginTop: 10,
						}
						: {},
				],
			},
			isStorage
				? {
						text: "Представитель СВХ АО \n «Терминал-Центр»",
						marginTop: 15,
				}
				: {},
			isStorage ?	{ text: "_".repeat(65), marginTop: -10, alignment: "right" } : {},
			isBailor
				? {
						text: "Представитель,  \n Поклажедателя \n присутствующий на  \n досмотре/осмотре",
						marginTop: 15,
				}
				: {},
			isBailor
				? {
						text: `${fullName}`,
						style: "mainInfo",
				}
				: {},
			isBailor
				? { text: "_".repeat(65), marginTop: -10, alignment: "right" }
				: {},
			isBailor
				? {
						text: `${passportSeriya} ${passportNumber} ${createDateForPassport(
							passportDate
						)}`,
						style: "mainInfo",
						marginTop: 5,
				}
				: {},
			isBailor
				? { text: "_".repeat(65), marginTop: -10, alignment: "right" }
				: {},
			isBailor
				? {
						text: `${passportExtradition}`,
						style: "mainInfo",
						marginTop: 5,
				}
				: {},
			isBailor
				? {
						text: "(Ф.И.О, серия и номер паспорта, дата выдачи, наименование \n органа, выдавшего паспорт)",
						style: "nameDesc",
						marginRight: -200,
				}
				: {},
			isBailor
				? { text: "_".repeat(65), marginTop: -43, alignment: "right" }
				: {},
			isBailor
				? {
						text: "Уполномоченный  \n представитель   \n Поклажедателя",
						marginTop: 15,
				}
				: {},
			isBailor
				? {
						text: `${userName?.name}`,
						alignment: "center",
				}
				: {},
			isBailor
				? {
						text: "(Ф.И.О)",
						style: "nameDesc",
						marginRight: -200,
				}
				: {},
			isBailor
				? { text: "_".repeat(65), marginTop: -31, alignment: "right" }
				: {},
			isBailor
				? {
						columns: [
							{
								width: "auto",
								text: "Дата ",
								marginRight: 115,
								marginTop: 15,
							},
							{
								width: "auto",
								text: `${createDateForPassport(
									new Date().toISOString()
								)}`,
								marginTop: 15,
								marginLeft: 20,
							},
						],
				}
				: {},
			isBailor
				? { text: "_".repeat(65), marginTop: -10, alignment: "right" }
				: {},
			isBailor
				? {
						columns: [
							{
								width: "auto",
								text: "Особые отметки",
								marginRight: 50,
								marginTop: 35,
							},
							{
								width: "auto",
								text: `${addTextForView}`,
								marginTop: 35,
								marginLeft: 20,
							},
						],
				}
				: {},
			isBailor
				? { text: "_".repeat(65), marginTop: -10, alignment: "right" }
				: {},
			{ text: "Заявка согласована ", marginTop: 25 },
			{
						columns: [
							{
								width: "auto",
								text: "Начальник отдела \n складской логистики",
								marginRight: 42,
								marginTop: 15,
							},
							{
								width: "auto",
								text: "",
								marginRight: 40,
								marginTop: 25,
							},
						],
				},
			{ text: "_".repeat(65), marginTop: -15, alignment: "right" },
		],
		styles: {
			title: {
				fontSize: 14,
				bold: true,
				alignment: "center",
				marginBottom: 30,
			},
			name: {
				fontSize: 14,
				alignment: "center",
			},
			nameDesc: {
				fontSize: 10,
				alignment: "center",
				marginTop: 2,
				marginBottom: 5,
			},
			indent: {
				marginRight: 30,
			},
			titleDesc: {
				bold: true,
			},
			mainInfo: {
				alignment: "center",
				marginTop: -10,
				marginRight: -200,
			},
			underline: {
				marginTop: -33,
			},
		},
	};

	const createPDF = async (evt: MouseEvent<HTMLButtonElement>) => {
		evt.preventDefault();
		const pdfDocGenerator = pdfMake.createPdf(docDefinition);
		try {
			const pdfBlob = await new Promise<Blob>((resolve) => {
				pdfDocGenerator.getBlob((blob: Blob) => {
					resolve(blob);
				});
			});
			const formData = new FormData();
			formData.append("id", vehicleId);
			formData.append("type", "v");
			formData.append("file", pdfBlob, "example.pdf");
			const response = await axios.post("/spa/orders/upload", formData);
			setIsSendOrderOpen(false);
			setDeliverDocument('');
			setItemValue('');
			setAmountValue('');
			setWeightValue('');
			setAddTextValue('');

			toast("Документ успешно отправлен", {
				type: "success",
			});
		} catch (error) {
			toast("Что-то пошло не так. Перезагрузите страницу", {
				type: "error",
			});
		}
	};

	const createPDFForView = async (evt: MouseEvent<HTMLButtonElement>) => {
		evt.preventDefault();
		const pdfDocGenerator = pdfMake.createPdf(docDefinitionForView);
		try {
			const pdfBlob = await new Promise<Blob>((resolve) => {
				pdfDocGenerator.getBlob((blob: Blob) => {
					resolve(blob);
				});
			});
			const formData = new FormData();
			formData.append("id", vehicleId);
			formData.append("type", "d");
			formData.append("file", pdfBlob, "example.pdf");
			const response = await axios.post("/spa/orders/upload", formData);
			// pdfDocGenerator.download("file.pdf");
			setIsInspectionOpen(false);
			setDocName('');
			setNotificationDate('');
			setIsStorage(false);
			setIsBailor(false);
			setFullName('');
			setPassportSeriya('');
			setPassportNumber('');
			setPassportDate('');
			setPassportExtradition('');
			setAddTextForView('');
			toast("Документ успешно отправлен", {
				type: "success",
			});
		} catch (error) {
			toast("Что-то пошло не так. Перезагрузите страницу", {
				type: "error",
			});
		}
	};

	const createPDFForExtradition = async (
		evt: MouseEvent<HTMLButtonElement>
	) => {
		evt.preventDefault();
		const pdfDocGenerator = pdfMake.createPdf(docDefinitionForExtradition);
		try {
			const pdfBlob = await new Promise<Blob>((resolve) => {
				pdfDocGenerator.getBlob((blob: Blob) => {
					resolve(blob);
				});
			});
			const formData = new FormData();
			formData.append("id", vehicleId);
			formData.append("type", "o");
			formData.append("file", pdfBlob, "example.pdf");
			const response = await axios.post("/spa/orders/upload", formData);
			setIsGoodDelivery1Open(false);
			setDispatchDate('');
			setDispatchTime('');
			setDispatchMark('');
			setDispatchNumber('');
			setDispatchTrailer('');
			setNumberDt('');
			setDispatchGoods([{
				article: "",
				party: "",
				amount: "",
				measure: "",
				note: "",
				height: "",
			}]);
			setAmountGoods([1]);
			setDispatchMover('');
			setDispatchDriver('');
			setDispatchSeriyaPassport('');
			setDispatchNumberPassport('');
			setDispatchDatePassport('');
			setDispatchSectionPassport('');
			setDispatchAddText('');
			setDispatchSealing(false);

			toast("Документ успешно отправлен", {
				type: "success",
			});
		} catch (error) {
			toast("Что-то пошло не так. Перезагрузите страницу", {
				type: "error",
			});
		}
	};

	function submitClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		dispatch(updateSearchByNumberCar(numberCar));
		dispatch(updateSearchBynumbertrailer(numberTrailer));
		dispatch(updateSort(status));
	}

	function changeCardHandler(card: string, isOpen: boolean) {
		setOpen(isOpen ? card : false);
	}

	const handleDownload = (link: string | undefined, name: string) => {
		// Создание ссылки на файл
		const fileUrl = `${link}`;
		const currentLink = document.createElement("a");
		currentLink.href = fileUrl;
		currentLink.download = `${name}`;

		currentLink.click();
	};

	useTitle(`${newVehiclesCount > 0 ? `(${newVehiclesCount})` : ''}` + ' ' + "Транспортные средства | Терминал");

	useEffect(() => {
		if (isStorage) {
			setIsBailor(false);
		}
	}, [isStorage]);

	useEffect(() => {
		if (isBailor) {
			setIsStorage(false);
		}
	}, [isBailor]);

	return (
		<>
			<StyledHeader>
				<HeaderContainer>
					<LogoLink to={AppRoute.Main} aria-label="На главную">
						<picture>
							<source
								media={`(max-width: ${Resolution.SM})`}
								srcSet="/img/logo-mobile.svg"
							/>

							<img
								src="/img/logo.svg"
								width={193}
								height={45}
								alt='Группа компаний "Терминал"'
							/>
						</picture>
					</LogoLink>

					<License>
						Лицензия <LicenseNumber>{license}</LicenseNumber>
					</License>

					<CustomAutocomplete
						id="company"
						ariaLabel="Компания"
						alternateView
						options={companies.map((item) => {
							return {
								value: String(item.id),
								label: {
									main: item.name,
								},
							};
						})}
						value={company}
						onChange={(evt, newValue) => {
							changeCardHandler(currentOpenCart, false);
							setCompany(newValue);
						}}
					/>

					<Button
						type="button"
						onClick={() => {
							setIsAddOrderOpen(true);
						}}
						variant={ButtonVariant.Secondary}
					>
						Загрузить Заявку
					</Button>

					<Account>
						Сотрудник
						<AccountName>{userName?.name}</AccountName>
					</Account>

					<HeaderIconButton
						as={Link}
						to={AppRoute.Logout}
						icon={HeaderButtonIcon.Logout}
						title="Выйти"
					>
						<VisuallyHidden>Выйти</VisuallyHidden>
					</HeaderIconButton>

					<BurgerButton>
						<VisuallyHidden>Открыть меню</VisuallyHidden>
					</BurgerButton>

					<FilterButton>
						<VisuallyHidden>Фильтры</VisuallyHidden>
					</FilterButton>
				</HeaderContainer>
			</StyledHeader>

			<Main>
				<MainContainer>
					<DesktopWrapper>
						<StyledNavigation>
							<NavigationLink to={AppRoute.Vehicles} $active>
								<NavigationIcon
									width="20"
									height="20"
									viewBox="0 0 20 20"
								>
									<use xlinkHref="/img/sprite.svg#note-pencil"></use>
								</NavigationIcon>
								Транспортные средства {newVehiclesCount > 0 && `(${newVehiclesCount})`}
							</NavigationLink>

							<NavigationLink to={AppRoute.DocumentsEmployee}>
								<NavigationIcon
									width="20"
									height="20"
									viewBox="0 0 20 20"
								>
									<use xlinkHref="/img/sprite.svg#file-search"></use>
								</NavigationIcon>
								Документы
							</NavigationLink>
						</StyledNavigation>
					</DesktopWrapper>

					<MainWrapper>
						<VisuallyHidden as="h1">
							Транспортные средства
						</VisuallyHidden>

						<DesktopWrapper>
							<StyledScreenControls>
								<VisuallyHidden as="h2">
									Управление
								</VisuallyHidden>

								<WrapControlsForm>
									<CustomTextField
										id="vehicle-number"
										label="Номер ТС"
										width="110px"
										widthSm="100%"
										widthMd="130px"
										marginTopSm="8px"
										value={numberCar}
										onChange={(e) =>
											setNumberCar(e.target.value)
										}
									/>

									<CustomTextField
										id="declaration-number"
										label="Номер ДТ"
										width="110px"
										widthSm="100%"
										widthMd="130px"
										marginTopSm="8px"
										value={numberTrailer}
										onChange={(e) =>
											setNumberTrailer(e.target.value)
										}
									/>

									<CustomTextField
										id="vehicle-status"
										label="Статус ТС"
										width="129px"
										widthSm="100%"
										widthMd="130px"
										marginTopSm="8px"
										value={status}
										select
										defaultValue=""
										options={vehicleStatusOptions}
										onChange={(e) =>
											setStatus(e.target.value)
										}
									/>
									<DateFieldset>
										<VisuallyHidden as="legend">
											Дата
										</VisuallyHidden>
										<DateFakeLegend aria-hidden={true}>
											Дата
										</DateFakeLegend>

										<CustomDatePicker
											onChange={(newValue) =>
												setSelectedDateFrom(newValue)
											}
											value={selectedDateFrom}
											label="От"
											width="131px"
											order={1}
										/>

										<CustomDatePicker
											onChange={(newValue) =>
												setSelectedDateTo(newValue)
											}
											value={selectedDateTo}
											label="До"
											width="131px"
											order={3}
										/>
									</DateFieldset>

									<DateFieldset>
										<VisuallyHidden as="legend">
											Время
										</VisuallyHidden>
										<DateFakeLegend aria-hidden={true}>
											Время
										</DateFakeLegend>

										<CustomTimePicker
											onChange={(newValue) =>
												setSelectedTimeFrom(newValue)
											}
											value={selectedTimeFrom}
											label="От"
											width="91px"
											order={1}
										/>

										<CustomTimePicker
											onChange={(newValue) =>
												setSelectedTimeTo(newValue)
											}
											value={selectedTimeTo}
											label="До"
											width="91px"
											order={3}
										/>
									</DateFieldset>

									<IconButton
										onClick={submitClickHandler}
										type="submit"
										variant={ButtonVariant.Main}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<use xlinkHref="/img/sprite.svg#check-circle"></use>
										</svg>

										<span>Применить</span>
									</IconButton>

									<IconButton
										onClick={handleReset}
										type="reset"
										variant={ButtonVariant.Secondary}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<use xlinkHref="/img/sprite.svg#cross-circle"></use>
										</svg>

										<span>Сбросить</span>
									</IconButton>
								</WrapControlsForm>
							</StyledScreenControls>
						</DesktopWrapper>

						<MarginMainGrid>
							{loading ? (
								<Loader />
							) : vehicleRender?.length ? (
								vehicleRender?.map((item, i) => (
									<DropdownCard
										key={item.docnum}
										// open={open === `card-${i + 1}`}
										open={open === `card-${item.id}`}
										onChange={(isOpen) => {
											// setCurrentOpenCart(`card-${i + 1}`);
											setCurrentOpenCart(`card-${item.id}`);
											setCurrentNumberCar(item.numbercar);
											setCurrentTrailerCar(
												item.numbertrailer
											);
											setVehiclesId(item.id);
											// changeCardHandler(
											// 	`card-${i + 1}`,
											// 	isOpen
											// );
											changeCardHandler(
												`card-${item.id}`,
												isOpen
											);
											setNumberDt(item.numberdt);
											setDispatchGoods([defaultCardData]);
											setAmountGoods([1]);
										}}
										tag={
											item.locationcar === "ЗТК"
												? VehicleStatus.Customs
												: item.locationcar == "СВХ"
												? VehicleStatus.Storage
												: item.locationcar == "ОП. СВХ"
												? VehicleStatus.OpenStorage
												: VehicleStatus.Departed
										}
										header={
											<>
												<HeaderTag
													icon={
														DropdownCardTagIcon.Truck
													}
												>
													{item.numbercar}
												</HeaderTag>
												<HeaderTag
													icon={
														DropdownCardTagIcon.Trailer
													}
												>
													{item.numbertrailer}
												</HeaderTag>
												<HeaderTag
													as="time"
													icon={
														DropdownCardTagIcon.Calendar
													}
													dateTime={createDate(
														item.arrivaldate
													)}
												>
													{createDate(
														item.arrivaldate
													)}
												</HeaderTag>
												<HeaderTag
													icon={
														DropdownCardTagIcon.Crosshair
													}
												>
													{item.target}
												</HeaderTag>
											</>
										}
									>
										{item.locationcar === "СВХ" ||
										item.locationcar === "ОП. СВХ" ||
										item.locationcar === "ЗТК" ? (
											<DescriptionList>
												<DescriptionWrapper>
													<dt>Примечание ОСЛ:</dt>
													<dd>{item.notesguard}</dd>
												</DescriptionWrapper>

												<DescriptionWrapper>
													<dt>Закрытие доставки:</dt>
													<dd>
														<time
															dateTime={createDate(
																item.closingdatedelivery
															)}
														>
															{createDate(
																item.closingdatedelivery
															)}
														</time>
													</dd>
												</DescriptionWrapper>

												<DescriptionWrapper>
													<dt>
														Фито / Вет. контроль:
													</dt>
													<dd>
														{item.fitovetcontrol}
													</dd>
												</DescriptionWrapper>
											</DescriptionList>
										) : (
											<></>
										)}

										{item.locationcar === "СВХ" ||
										item.locationcar === "ОП. СВХ" ||
										item.locationcar === "ЗТК" ? (
											<DescriptionListWhite>
												<DescriptionWrapper>
													<dt>Досмотр разрешен:</dt>
													<dd>
														<time
															dateTime={
																item.dateappointmentinspection
																	? createDate(
																			item.dateappointmentinspection
																	)
																	: ""
															}
														>
															{item.dateappointmentinspection
																? createDate(
																		item.dateappointmentinspection
																)
																: ""}
														</time>
													</dd>
												</DescriptionWrapper>
											</DescriptionListWhite>
										) : (
											<></>
										)}
										{item.locationcar === "ЗТК" ||
										item.locationcar === "СВХ" ||
										item.locationcar === "ОП. СВХ" ||
										item.locationcar === "Убыла" ? (
											<CardButtonContainer>
												<Button
													variant={ButtonVariant.Main}
													maxWidth="calc(50% - 7.5px)"
													margin={item.locationcar === "Убыла" ? '16px 0px 0px 0px': '0 0 0 0'}
													onClick={() =>
														setIsDownloadDocumentOpen(
															true
														)
													}
												>
													Документы
												</Button>

												{item.locationcar !== "Убыла" ? (
													<Button
													variant={ButtonVariant.Main}
													maxWidth="calc(50% - 7.5px)"
													margin="0px 0px 0px auto"
													onClick={() =>
														setIsInspectionOpen(
															true
														)
													}
													>
													Заявка на досмотр
												</Button>
												) : <></>}
											</CardButtonContainer>
										) : (
											<></>
										)}
										{/* : (
											<Button
												variant={ButtonVariant.Main}
												margin="0 0 0 16px"
												maxWidth="202px"
												onClick={() =>
													setIsInspectionOpen(true)
												}
											>
												Заявка на проведение
												досмотра/осмотра
											</Button>
										) */}
										{item.locationcar === "СВХ" ||
										item.locationcar === "ОП. СВХ" ||
										item.locationcar === "ЗТК" ||
										item.locationcar === "Убыла" ? (
											<>
												<DescriptionList>
													<DescriptionWrapper>
														<dt>Убытие:</dt>
														<dd>
															<time
																dateTime={
																	item.datedeparture
																		? createDate(
																			item.datedeparture
																		)
																		: ""
																}
															>
																{item.datedeparture
																	? createDate(
																		item.datedeparture
																	)
																	: ""}
															</time>
														</dd>
													</DescriptionWrapper>
												</DescriptionList>
												<CardButtonContainer>
													{/* <Button
														variant={
															ButtonVariant.Secondary
														}
														maxWidth="calc(50% - 7.5px)"
														onClick={() =>
															setIsDownloadDocumentOpen(
																true
															)
														}
													>
														Скачать документы с
														доставки
													</Button> */}
													{item.locationcar ===
														"СВХ" ||
													item.locationcar ===
														"ОП. СВХ" ||
													item.locationcar ===
														"ЗТК" ? (
														<>
															<Button
																variant={
																	ButtonVariant.Main
																}
																maxWidth="calc(50% - 7.5px)"
																onClick={() =>
																	setIsSendOrderOpen(
																		true
																	)
																}
															>
																Заявка на
																выгрузку
															</Button>

															<Button
																variant={
																	ButtonVariant.Main
																}
																maxWidth="calc(50% - 7.5px)"
																onClick={() =>
																	setIsGoodDelivery1Open(
																		true
																	)
																}
															>
																Заявка на
																отгрузку
															</Button>
														</>
													) : item.locationcar ===
													"Убыла" ? (
														<Button
															variant={
																ButtonVariant.Main
															}
															maxWidth="calc(50% - 7.5px)"
															onClick={() =>
																setIsGoodDelivery1Open(
																	true
																)
															}
														>
															Заявка на отгрузку
														</Button>
													) : (
														<></>
													)}
												</CardButtonContainer>
												<DescriptionList>
													<DescriptionWrapper>
														<span>
															№ДТ: {item.numberdt}
														</span>
														<dd>
															{item.statusdt && (
																<CardLabel
																	color={
																		item.statusdt ===
																			"В работе" ||
																		item.statusdt ===
																			"Подана"
																			? Color.DarkText
																			: item.statusdt ===
																			"Выдана"
																			? "rgb(83, 79, 87)"
																			: ""
																	}
																	backgroundColor={
																		item.statusdt ===
																		"В работе"
																			? Color.Yellow
																			: item.statusdt ===
																			"Подана"
																			? Color.Yellow
																			: item.statusdt ===
																			"Выдана"
																			? Color.Green
																			: ""
																	}
																>
																	<span>
																		ДТ:
																	</span>{" "}
																	{
																		item.statusdt
																	}
																</CardLabel>
															)}
														</dd>
													</DescriptionWrapper>

													<DescriptionWrapper>
														<dt>
															Специалист по ТО:
														</dt>
														<dd>
															{
																item.specialistinformation
															}
														</dd>
													</DescriptionWrapper>

													<DescriptionWrapper>
														{item.releasedt ? (
															<>
																<dt>
																	Дата
																	выпуска:{" "}
																</dt>
																<dd>
																	<time
																		dateTime={createDate(
																			item.releasedt
																		)}
																	>
																		{createDate(
																			item.releasedt
																		)}
																	</time>
																</dd>
															</>
														) : (
															<>
																<dt>
																	Подача:{" "}
																</dt>
																<dd>
																	<time
																		dateTime={createDate(
																			item.inningsdt
																		)}
																	>
																		{createDate(
																			item.inningsdt
																		)}
																	</time>
																</dd>
															</>
														)}
													</DescriptionWrapper>
												</DescriptionList>
											</>
										) : (
											<></>
										)}
									</DropdownCard>
								))
							) : (
								<>Список пуст</>
							)}
						</MarginMainGrid>
					</MainWrapper>
				</MainContainer>

				<CustomDialog
					open={isSendOrderOpen}
					onClose={() => setIsSendOrderOpen(false)}
					title="Информация о товаре"
				>
					<GreyWrapper as="form">
						<CustomTextField
							id="deliver-doc"
							label="Документ, на основании которого действует представитель поклажедателя"
							placeholder="Документ, на основании которого действует представитель поклажедателя"
							width="564px"
							widthSm="100%"
							value={deliverDocument}
							onChange={(evt) =>
								setDeliverDocument(evt.target.value)
							}
						/>

						<CustomTextField
							id="good-name"
							label="Товар"
							placeholder="Название товара"
							width="564px"
							widthSm="100%"
							marginTop="16px"
							value={itemValue}
							onChange={(evt) => setItemValue(evt.target.value)}
						/>

						<CustomTextField
							id="spots"
							label="Кол-во / наименование грузовых мест"
							placeholder="Кол-во / наименование грузовых мест"
							width="285px"
							widthSm="100%"
							marginTop="16px"
							value={amountValue}
							onChange={(evt) => setAmountValue(evt.target.value)}
						/>

						<CustomTextField
							id="weight"
							label="Вес брутто, кг"
							placeholder="Вес брутто, кг"
							width="258px"
							widthSm="100%"
							marginTop="16px"
							marginLeft="20px"
							marginLeftSm="0"
							value={weightValue}
							onChange={(evt) => setWeightValue(evt.target.value)}
						/>

						<CustomTextField
							id="information"
							label="Дополнительная информация"
							placeholder="Текст"
							width="564px"
							widthSm="100%"
							multiline
							rows={3}
							marginTop="16px"
							value={addTextValue}
							onChange={(evt) =>
								setAddTextValue(evt.target.value)
							}
						/>

						<ControlsFlexWrapper>
							{/* <Button type="button" variant={ButtonVariant.Secondary}>Сохранить черновик</Button> */}
							<Button
								type="submit"
								onClick={createPDF}
								variant={ButtonVariant.Main}
							>
								Отправить заявку
							</Button>
						</ControlsFlexWrapper>
					</GreyWrapper>
				</CustomDialog>

				<CustomDialog
					open={isInspectionOpen}
					onClose={() => setIsInspectionOpen(false)}
					title={`Заявка на проведение досмотра/осмотра`}
					// title={`Заявка № ЛК-д 2 от ${createDate(new Date().toISOString())} на проведение досмотра/осмотра`}
				>
					<FlexWrapper>
						<HeaderTag icon={DropdownCardTagIcon.Truck}>
							{currentNumberCar}
						</HeaderTag>
						<HeaderTag icon={DropdownCardTagIcon.Trailer}>
							{currentTrailerCar}
						</HeaderTag>
					</FlexWrapper>

					<GreyWrapper as="form">
						{/* <CustomTextField
								id="full-name-head"
								label="Ф.И.О руководителя"
								placeholder="Ф.И.О руководителя"
								width="564px"
								widthSm="100%"
								value={fullNameHead}
								onChange={(evt) => setFullNameHead(evt.target.value)}
								required
						/> */}
						{/* <CustomTextField
								id="post-head"
								label="Должность"
								placeholder="Должность"
								width="564px"
								widthSm="100%"
								marginTop="16px"
								value={postHead}
								onChange={(evt) => setPostHead(evt.target.value)}
								required
						/> */}

						<CustomTextField
							id="post-doc-name"
							label="Наименование документа, на основании которого действует представитель поклажедателя"
							placeholder="Наименование документа, на основании которого действует представитель поклажедателя"
							width="calc(100% / 2 - 11px)"
							widthSm="100%"
							marginTop="16px"
							value={docName}
							onChange={(evt) => setDocName(evt.target.value)}
							required
						/>
						<CustomDatePicker
							label="Уведомление б/н от"
							width="calc(100% / 2 - 11px)"
							widthSm="100%"
							marginTop="16px"
							marginLeftSm="0"
							marginLeft="16px"
							marginLeftLg="0"
							onChange={(newValue) =>
								setNotificationDate(newValue)
							}
							value={notificationDate}
							required
						/>
						<div>
							<CheckboxWrapper>
								<CustomCheckbox
									label="От СВХ АО “Терминал-Центр”"
									checked={isStorage}
									onChange={() => setIsStorage(!isStorage)}
								/>
							</CheckboxWrapper>

							{/* <Separator width="600" height="1" viewBox="0 0 600 1">
								<line
									x1="0"
									y1="0"
									x2="600"
									y2="0"
									stroke="#D9D9DA"
									strokeDasharray="8 6"
								/>
							</Separator> */}

							<CheckboxWrapper>
								<CustomCheckbox
									label="От поклажедателя"
									checked={isBailor}
									onChange={() => setIsBailor(!isBailor)}
									disabled={isStorage}
								/>
							</CheckboxWrapper>
						</div>

						<CustomTextField
							id="full-name"
							label="Ф.И.О"
							placeholder="Ф.И.О"
							width="564px"
							widthSm="100%"
							value={fullName}
							onChange={(evt) => setFullName(evt.target.value)}
							disabled={!isBailor}
							required
						/>

						<Fieldset>
							<Legend disabled={!isBailor}>Паспорт</Legend>

							<CustomTextField
								id="passport-series"
								label="Серия"
								placeholder="NNNNN"
								width="113px"
								widthSm="calc(50% - 2px)"
								disabled={!isBailor}
								onChange={(evt) =>
									setPassportSeriya(evt.target.value)
								}
								value={passportSeriya}
								required
							/>

							<CustomTextField
								id="passport-number"
								label="Номер"
								placeholder="NNNNN"
								width="113px"
								widthSm="calc(50% - 2px)"
								marginLeft="4px"
								disabled={!isBailor}
								onChange={(evt) =>
									setPassportNumber(evt.target.value)
								}
								value={passportNumber}
								required
							/>
						</Fieldset>

						<CustomDatePicker
							label="Дата выдачи"
							width="306px"
							widthSm="100%"
							marginTop="44px"
							marginLeft="28px"
							marginLeftSm="0"
							disabled={!isBailor}
							onChange={(newValue) => setPassportDate(newValue)}
							value={passportDate}
							required
						/>

						<CustomTextField
							id="issuing-authority"
							label="Орган выдачи"
							placeholder="Орган выдачи"
							width="564px"
							widthSm="100%"
							marginTop="16px"
							disabled={!isBailor}
							value={passportExtradition}
							onChange={(evt) =>
								setPassportExtradition(evt.target.value)
							}
							required
						/>

						{/* <CustomTextField
							id="bailor-fullname"
							label="Ф.И.О поклажедателя"
							placeholder="Ф.И.О поклажедателя"
							width="564px"
							widthSm="100%"
							marginTop="16px"
							value={bailorFullName}
							onChange={(evt) => setBailorFullName(evt.target.value)}
							disabled={!isBailor}
							required
						/> */}

						<CustomTextField
							id="add-text-veiw"
							label="Особые заметки"
							placeholder="Особые заметки"
							width="564px"
							widthSm="100%"
							marginTop="16px"
							value={addTextForView}
							onChange={(evt) =>
								setAddTextForView(evt.target.value)
							}
							disabled={!isBailor}
							required
						/>

						<ControlsFlexWrapper>
							<Button
								disabled={
									!isStorage &&
									!fullName &&
									!passportSeriya &&
									!passportNumber &&
									!passportDate &&
									!passportExtradition
								}
								onClick={(evt) => {
									createPDFForView(evt);
									setIsInspectionOpen(false);
								}}
								type="submit"
								variant={ButtonVariant.Main}
							>
								Отправить заявку
							</Button>
						</ControlsFlexWrapper>
					</GreyWrapper>
				</CustomDialog>

				<CustomDialog
					open={isGoodDelivery1Open}
					onClose={() => setIsGoodDelivery1Open(false)}
					title="Заявка на выдачу товара, находящегося на ответственном хранении АО «Терминал-Центр»"
					// title="Заявка № ЛК-ВОХ 20 от 24.11.2022 на выдачу товара, находящегося на ответственном хранении АО «Терминал-Центр»"
				>
					<FlexWrapper>
						<HeaderTag icon={DropdownCardTagIcon.Truck}>
							{currentNumberCar}
						</HeaderTag>
						<HeaderTag icon={DropdownCardTagIcon.Trailer}>
							{currentTrailerCar}
						</HeaderTag>
					</FlexWrapper>

					<FormWrapper>
						<GreyWrapper
							as="fieldset"
							width="calc(36% - 8px)"
							widthSm="100%"
						>
							<TitleLegend>Информация о транспорте</TitleLegend>

							<CustomDatePicker
								label="Дата погрузки"
								width="calc(50% - 8px)"
								widthLg="100%"
								marginTop="16px"
								onChange={(newValue) =>
									setDispatchDate(newValue)
								}
								value={dispatchDate}
							/>

							<CustomTimePicker
								label="Время погрузки"
								width="calc(50% - 8px)"
								widthLg="100%"
								marginTop="16px"
								marginLeft="16px"
								marginLeftLg="0"
								onChange={(newValue) =>
									setDispatchTime(newValue)
								}
								value={dispatchTime}
							/>

							<CustomTextField
								id="car-model"
								label="Марка а/м"
								placeholder="NNNNN"
								width="calc(100% / 3 - 11px)"
								widthLg="100%"
								marginTop="16px"
								onChange={(evt) =>
									setDispatchMark(evt.target.value)
								}
								value={dispatchMark}
							/>

							<CustomTextField
								id="car-number"
								label="Номер а/м"
								placeholder="NNNNN"
								width="calc(100% / 3 - 11px)"
								widthLg="100%"
								marginTop="16px"
								marginLeft="16px"
								marginLeftLg="0"
								onChange={(evt) =>
									setDispatchNumber(evt.target.value)
								}
								value={dispatchNumber}
							/>

							<CustomTextField
								id="trailer-number"
								label="Номер прицепа"
								placeholder="NNNNN"
								width="calc(100% / 3 - 11px)"
								widthLg="100%"
								marginTop="16px"
								marginLeft="16px"
								marginLeftLg="0"
								onChange={(evt) =>
									setDispatchTrailer(evt.target.value)
								}
								value={dispatchTrailer}
							/>

							<CustomTextField
								id="declaration-number"
								label="№ ДТ"
								placeholder="NNNNN"
								width="100%"
								marginTop="16px"
								onChange={(evt) =>
									setNumberDt(evt.target.value)
								}
								value={numberDt}
							/>
						</GreyWrapper>

						<GreyWrapper
							as="fieldset"
							width="calc(29% - 8px)"
							widthSm="100%"
						>
							<TitleLegend>Информация о товаре</TitleLegend>

							{/* <DialogText>Товар</DialogText> */}

							{/* <ButtonContainer> */}
								{/* <Button
									type="button"
									variant={ButtonVariant.Main}
									maxWidth="calc(50% - 8px)"
									maxWidthLg="100%"
								>
									Загрузить (заявку, ТСД)
								</Button> */}
							{/* </ButtonContainer> */}
							<Button
								type="button"
								variant={ButtonVariant.Main}
								maxWidth="100%"
								maxWidthLg="100%"
								margin="12px 0px 20px 0px"
								onClick={() => setIsGoodFillingOpen(true)}
							>
								Заполнить заявку
							</Button>
							

							{dispatchGoods?.map(item => (
								item.article && (
									<div key={item.article}>
										{`${item.article} - ${item.amount}`}
									</div>
								)
							))}
						</GreyWrapper>

						<GreyWrapper
							as="fieldset"
							width="calc(35% - 8px)"
							widthSm="100%"
						>
							<TitleLegend>Информация о водителе</TitleLegend>

							<CustomTextField
								id="executor"
								label="Перевозчик"
								width="100%"
								marginTop="16px"
								onChange={(evt) =>
									setDispatchMover(evt.target.value)
								}
								value={dispatchMover}
							/>

							<CustomTextField
								id="driver-full-name"
								label="Ф.И.О водителя"
								width="100%"
								marginTop="16px"
								onChange={(evt) =>
									setDispatchDriver(evt.target.value)
								}
								value={dispatchDriver}
							/>

							<Fieldset width="50%">
								<Legend>Паспорт</Legend>

								<CustomTextField
									id="passport-series-info"
									label="Серия"
									placeholder="NNNNN"
									width="calc(50% - 2px)"
									widthSm="calc(50% - 2px)"
									onChange={(evt) =>
										setDispatchSeriyaPassport(
											evt.target.value
										)
									}
									value={dispatchSeriyaPassport}
								/>

								<CustomTextField
									id="passport-number-info"
									label="Номер"
									placeholder="NNNNN"
									width="calc(50% - 2px)"
									widthSm="calc(50% - 2px)"
									marginLeft="4px"
									onChange={(evt) =>
										setDispatchNumberPassport(
											evt.target.value
										)
									}
									value={dispatchNumberPassport}
								/>
							</Fieldset>

							<CustomDatePicker
								label="Дата выдачи"
								width="calc(50% - 28px)"
								widthLg="100%"
								marginTop="44px"
								marginTopLg="16px"
								marginLeft="28px"
								marginLeftLg="0"
								onChange={(evt) =>
									setDispatchDatePassport(evt.target.value)
								}
								value={dispatchDatePassport}
							/>

							<CustomTextField
								id="issuing-authority-info"
								label="Орган выдачи"
								placeholder="Орган выдачи"
								width="100%"
								widthSm="100%"
								marginTop="16px"
								onChange={(evt) =>
									setDispatchSectionPassport(evt.target.value)
								}
								value={dispatchSectionPassport}
							/>

							<CustomTextField
								id="special-marks"
								label="Особые метки"
								placeholder="Текст"
								width="100%"
								widthSm="100%"
								marginTop="16px"
								multiline
								rows={2}
								onChange={(evt) =>
									setDispatchAddText(evt.target.value)
								}
								value={dispatchAddText}
							/>

							<CustomCheckbox
								label="Опломбировать грузовой отсек"
								onChange={() =>
									setDispatchSealing(!dispatchSealing)
								}
							/>
						</GreyWrapper>
					</FormWrapper>

					<ControlsFlexWrapper>
						{/* <Button type="button" variant={ButtonVariant.Secondary}>Сохранить черновик</Button> */}
						<Button
							onClick={createPDFForExtradition}
							type="submit"
							variant={ButtonVariant.Main}
						>
							Отправить заявку
						</Button>
					</ControlsFlexWrapper>
				</CustomDialog>

				<CustomDialog
					open={isGoodFillingOpen}
					onClose={() => setIsGoodFillingOpen(false)}
					title="Заполнение товара"
				>
					<FlexWrapper>
						<HeaderTag icon={DropdownCardTagIcon.Truck}>
							{currentNumberCar}
						</HeaderTag>
						<HeaderTag icon={DropdownCardTagIcon.Trailer}>
							{currentTrailerCar}
						</HeaderTag>
					</FlexWrapper>

					<FormWrapper>
						{amountGoods.map((good, index) => (
							<GreyWrapper
								key={good}
								as="fieldset"
								width="calc(100% / 3 - 8px)"
								widthSm="100%"
							>
								<div>
									<TitleLegend>Товар {good}</TitleLegend>
									<ActionButton
										type="button"
										title="Удалить"
										aria-label="Удалить"
										marginLeft="auto"
										color={Color.DarkText}
										onClick={() => removeGood(index)}
									>
										<svg
											width="10"
											height="10"
											viewBox="0 0 10 10"
										>
											<use xlinkHref="/img/sprite.svg#cross"></use>
										</svg>
									</ActionButton>
								</div>

								<CustomTextField
									id={`good-vendor-code-${good}`}
									label="Товар/артикул"
									placeholder="Бонсиланж 1051"
									width="calc(50% - 8px)"
									widthLg="100%"
									marginTop="16px"
									value={
										tempDispatchGoods[index]
											? tempDispatchGoods[index].article
											: ""
									}
									onChange={(e) => {
										const newTempData = [
											...tempDispatchGoods,
										];
										newTempData[index] = {
											...newTempData[index],
											article: e.target.value,
										};
										setTempDispatchGoods(newTempData);
									}}
								/>

								<CustomTextField
									id={`consignment-${good}`}
									label="Партия"
									placeholder="Партия от 12/10/2022"
									width="calc(50% - 8px)"
									widthLg="100%"
									marginTop="16px"
									marginLeft="16px"
									value={
										tempDispatchGoods[index]
											? tempDispatchGoods[index].party
											: ""
									}
									onChange={(e) => {
										const newTempData = [
											...tempDispatchGoods,
										];
										newTempData[index] = {
											...newTempData[index],
											party: e.target.value,
										};
										setTempDispatchGoods(newTempData);
									}}
								/>

								<CustomTextField
									id={`unit-${good}`}
									label="Ед. изм."
									width="calc(50% - 8px)"
									widthLg="100%"
									marginTop="16px"
									marginLeftLg="0"
									value={
										tempDispatchGoods[index]
											? tempDispatchGoods[index].measure
											: ""
									}
									onChange={(e) => {
										const newTempData = [
											...tempDispatchGoods,
										];
										newTempData[index] = {
											...newTempData[index],
											measure: e.target.value,
										};
										setTempDispatchGoods(newTempData);
									}}
								/>

								<CustomTextField
									id={`amount-${good}`}
									label="Кол-во"
									placeholder="25"
									width="calc(50% - 8px)"
									widthLg="100%"
									marginTop="16px"
									marginLeft="16px"
									value={
										tempDispatchGoods[index]
											? tempDispatchGoods[index].amount
											: ""
									}
									onChange={(e) => {
										const newTempData = [
											...tempDispatchGoods,
										];
										newTempData[index] = {
											...newTempData[index],
											amount: e.target.value,
										};
										setTempDispatchGoods(newTempData);
									}}
								/>

								<CustomTextField
									id={`weight-${good}`}
									label="Вес брутто, кг"
									placeholder="4"
									width="calc(50% - 8px)"
									widthLg="100%"
									marginTop="16px"
									marginLeftLg="0"
									value={
										tempDispatchGoods[index]
											? tempDispatchGoods[index].height
											: ""
									}
									onChange={(e) => {
										const newTempData = [
											...tempDispatchGoods,
										];
										newTempData[index] = {
											...newTempData[index],
											height: e.target.value,
										};
										setTempDispatchGoods(newTempData);
									}}
								/>

								<CustomTextField
									id={`note-${good}`}
									label="Примечание"
									placeholder="Накладная"
									width="calc(50% - 8px)"
									widthLg="100%"
									marginTop="16px"
									marginLeft="16px"
									marginLeftLg="0"
									value={
										tempDispatchGoods[index]
											? tempDispatchGoods[index].note
											: ""
									}
									onChange={(e) => {
										const newTempData = [
											...tempDispatchGoods,
										];
										newTempData[index] = {
											...newTempData[index],
											note: e.target.value,
										};
										setTempDispatchGoods(newTempData);
									}}
								/>
							</GreyWrapper>
						))}
					</FormWrapper>

					<ControlsFlexWrapper>
						<Button
							onClick={() => {
								setIsGoodFillingOpen(false);
								handleSaveChanges();
								toast("Товары сохранены", {
									type: "success",
								});
							}}
							type="submit"
							variant={ButtonVariant.Main}
						>
							Сохранить в заявку
						</Button>

						<Button
							onClick={addNewGood}
							type="submit"
							variant={ButtonVariant.Secondary}
						>
							Добавить товар
						</Button>
					</ControlsFlexWrapper>
				</CustomDialog>

				<CustomDialog
					open={isAddOrderOpen}
					onClose={() => setIsAddOrderOpen(false)}
					title="Загрузить заявку"
				>
					<CustomTextField
						id="order-name"
						label="Компания"
						width="400px"
						widthSm="100%"
						value={company?.label.main}
					/>

					<TextField
						style={{ margin: "16px 0px 0px 0px" }}
						onChange={handleFileChange}
						name="upload-pdf"
						type="file"
					/>
					<Button
						type="submit"
						onClick={uploadFileHandler}
						variant={ButtonVariant.Main}
						margin="24px 0 0 0"
						disabled={!file}
					>
						Загрузить
					</Button>
				</CustomDialog>
				<CustomDialog
					open={isDownloadDocumentOpen}
					onClose={() => setIsDownloadDocumentOpen(false)}
					title="Скачать документы с доставки"
				>
					<StripedTable>
						<tbody>
							{sortedDocumentsByVehicles(
								allVehiclesDocuments,
								vehicleId
							)?.length ? (
								sortedDocumentsByVehicles(
									allVehiclesDocuments,
									vehicleId
								)?.map((item) => (
									<tr key={item.id}>
										<td>
											<time
												dateTime={createDate(
													item.created_at
												)}
											>
												{createDate(item.created_at)}
											</time>
										</td>
										<td>
											<CustomLink
												onClick={() =>
													handleDownload(
														item.link,
														item.name
													)
												}
											>
												{item.name}
											</CustomLink>
										</td>
									</tr>
								))
							) : (
								<>Список пуст</>
							)}
						</tbody>
					</StripedTable>
				</CustomDialog>
			</Main>

			<Footer />
		</>
	);
}

export default VehiclesScreen;