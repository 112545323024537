import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {Color, DEFAULT_TRANSITION, Resolution} from '../../const';

type NavigationLinkProps = {
	$active?: boolean
}

export const StyledNavigation = styled.nav`
	display: flex;
	flex-direction: column;
	gap: 16px;
	width: 190px;

	@media(max-width: ${Resolution.SM}) {
		width: 100%;
	}
`;

export const NavigationLink = styled(Link)<NavigationLinkProps>`
	width: 100%;
	padding: 14px 8px 14px 43px;

	position: relative;

	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	color: ${props => props.$active ? '#FFFFFF' : Color.DarkText};
	text-decoration: none;

	border-radius: 5px;
	background-color: ${props => props.$active ? Color.Orange : 'transparent'};
	transition: ${DEFAULT_TRANSITION};

	&:hover {
		color: #FFFFFF;

		background-color: ${Color.Orange};
	}

	&:active {
		opacity: 0.4;
	}

	@media(max-width: ${Resolution.SM}) {
		padding: 23px 16px 23px 61px;

		font-size: 16px;
		line-height: 20px;
	}
`;

export const NavigationIcon = styled.svg`
	position: absolute;
	top: 50%;
	left: 8px;

	transform: translateY(-50%);

	@media(max-width: ${Resolution.SM}) {
		width: 30px;
		height: 30px;

		left: 16px;
	}
`;
