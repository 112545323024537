import styled from "styled-components";
import {Color} from "../../const";

export const DateFieldset = styled.fieldset`
	border: none;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;

	&::before {
		order: 2;
		width: 17px;
		height: 1px;
		margin: 0 12px;
		align-self: center;

		content: "";

		background-color: ${Color.Orange};
	}
`;

export const DateFakeLegend = styled.div`
	margin-right: 4px;

	font-size: 14px;
	line-height: 20px;
	color: ${Color.DarkText};
`;
