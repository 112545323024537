import { useDispatch, useSelector } from "react-redux";
import { StyledTable } from "../styled/styled-table";
import TableRowEmployees from "../table-row-employees/table-row-employees";
import {
	IEmployeesState,
	setAllCompanyForEmployees,
	setAllEmployees,
} from "../../store/employees/employees";
import axios from "axios";
import { useEffect, useState } from "react";
import { AppDispatch } from "../../store/store";
import {
	getCompanyForEmployees,
	getEmployees,
	getSearchEmployeeBySort,
	getSearchEmployeeFromCompany,
	getSearchEmployeeName,
} from "../../store/employees/selectors/selectors";
import { ICompaniesState } from "../../store/companies/companies";
import { Loader } from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "../../const";

function TableEmployees(): JSX.Element {
	const [sortedEmployee, setSortedEmployeer] = useState<
		IEmployeesState[] | null | undefined
	>([]);
	const dispatch = useDispatch<AppDispatch>();
	const employees = useSelector(getEmployees);
	const employeeCompanies = useSelector(getCompanyForEmployees);
	const searchEmployeByName = useSelector(getSearchEmployeeName);
	const searchEmployeeFromCompany = useSelector(getSearchEmployeeFromCompany);
	const searchBySortEmployer = useSelector(getSearchEmployeeBySort);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		setLoading(true);
		const fetchEmployees = async () => {
			const result = await axios.get("/spa/users?role=3");
			dispatch(setAllEmployees(result.data.data));
			setLoading(false);
		};
		async function fetchCompany() {
			const result = await axios.get(`/spa/companies`);
			dispatch(setAllCompanyForEmployees(result.data.data));
		}

		try {
			fetchEmployees();
			fetchCompany();
		} catch (error: any) {
			if (error.response && error.response.status === 401) {
				navigate(AppRoute.Login);
			}
		}
	}, []);

	useEffect(() => {
		if (searchBySortEmployer === "active") {
			const sorted = employees?.filter((item) => !item.isBlocked);
			setSortedEmployeer(sorted);
		} else if (searchBySortEmployer === "blocked") {
			const sorted = employees?.filter((item) => item.isBlocked);
			setSortedEmployeer(sorted);
		} else if (searchBySortEmployer === "all") {
			setSortedEmployeer(employees);
		}
	}, [searchBySortEmployer]);

	const filterEmployees = (
		sortedEmployee: IEmployeesState[] | null | undefined,
		searchEmployeByName: string,
		searchEmployeeFromCompany: string,
		companies: ICompaniesState[] | null | undefined
	): IEmployeesState[] | undefined => {
		const currectEmployee = sortedEmployee?.length
			? sortedEmployee
			: employees;

		return currectEmployee
			?.filter((item) => {
				if (searchEmployeByName !== "") {
					return item.fullName
						.toLowerCase()
						.includes(searchEmployeByName.toLowerCase());
				} else {
					return item;
				}
			})
			.filter((item) => {
				if (searchEmployeeFromCompany !== "") {
					const companyId = companies?.filter((company) => {
						return company.name
							.toLowerCase()
							.includes(searchEmployeeFromCompany.toLowerCase());
					});
					const matchesCompany = companyId?.every((company) =>
						item.companyIds.includes(company.id)
					);

					return matchesCompany;
				} else {
					return item;
				}
			});
	};
	const filteredEmploeeys = filterEmployees(
		employees,
		searchEmployeByName,
		searchEmployeeFromCompany,
		employeeCompanies
	);

	/*function checkboxChangeHandler(evt: React.ChangeEvent<HTMLInputElement>) {
		employees?.forEach((employee) => {
			dispatch(setIsChecked({
				id: employee.id,
				isChecked: evt.target.checked
			}));
		});
	}*/

	return (
		<>
			{loading ? (
				<Loader />
			) : employees ? (
				<StyledTable>
					<thead>
						<tr>
							{/* <th className="checkbox">
								<CustomCheckbox
									ariaLabel="Выбрать все"
									label="Выбрать все"
									checked={isAllChecked<Employee>(employees)}
									onChange={checkboxChangeHandler}
									indeterminate={isIndeterminate<Employee>(employees)}
									isLabelMobile
								/>
							</th> */}
							<th>
								<span className="medium-hidden">№ П/П</span>
							</th>
							<th>ФИО сотрудника</th>
							<th>Название компании</th>
							<th>
								<span className="medium-hidden">Действия</span>
							</th>
						</tr>
					</thead>

					<tbody>
						{searchEmployeByName || searchEmployeeFromCompany
							? filteredEmploeeys?.map(
									(employee: any, i: any) => (
										<TableRowEmployees
											employee={employee}
											index={i + 1}
											companies={employeeCompanies}
											key={employee.id}
										/>
									)
							)
							: sortedEmployee?.length
							? sortedEmployee?.map((employee: any, i: any) => (
									<TableRowEmployees
										employee={employee}
										index={i + 1}
										companies={employeeCompanies}
										key={employee.id}
									/>
							))
							: filteredEmploeeys?.map(
									(employee: any, i: any) => (
										<TableRowEmployees
											employee={employee}
											index={i + 1}
											companies={employeeCompanies}
											key={employee.id}
										/>
									)
							)}
					</tbody>
				</StyledTable>
			) : (
				<>Список пуст</>
			)}
		</>
	);
}

export default TableEmployees;
