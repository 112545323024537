import {Dialog, DialogContent, DialogTitle, Paper, PaperProps} from '@mui/material';
import React, {ReactNode, useCallback, useState} from 'react';
import {CloseButton, DialogHead, DraggableArea} from './style';
import {Color, muiTheme} from '../../const';
import Draggable from 'react-draggable';

type CustomDialogProps = {
	open: boolean,
	onClose: () => void,
	title?: string,
	children: ReactNode
}

function CustomDialog(props: CustomDialogProps): JSX.Element {
	const {open, onClose, title, children} = props;
	const [position, setPosition] = useState({x: 0, y: 0});
	const nodeRef = React.useRef(null);

	function PaperComponent(props: PaperProps) {
		return (
			<Draggable
				handle="#draggable"
				cancel={'[class*="MuiDialogContent-root"]'}
				onStop={(evt, data) => setPosition({x: data.x, y: data.y})}
				defaultPosition={position}
				nodeRef={nodeRef}
			>
				<Paper {...props} ref={nodeRef}/>
			</Draggable>
		);
	}

	return (
		<Dialog
			open={open}
			onClose={onClose}
			PaperComponent={useCallback(PaperComponent, [])}
			sx={{
				'& .MuiModal-backdrop': {
					backgroundColor: 'transparent'
				},
				'& .MuiDialog-paper': {
					margin: '121px 32px 32px',
					width: '100%',
					maxWidth: 'none',
					minWidth: '500px',

					[muiTheme.breakpoints.up('sm')]: {
						width: 'min-content',
						marginTop: '100px'
					}
				},
				'& .MuiDialog-container': {
					alignItems: 'flex-start'
				}
			}}
		>
			<DialogHead>
				<DraggableArea id="draggable"/>
				<CloseButton onClick={onClose}>
					<svg width="12" height="12" viewBox="0 0 10 10">
						<use xlinkHref="/img/sprite.svg#cross"></use>
					</svg>
				</CloseButton>
			</DialogHead>

			{!!title &&
				<DialogTitle sx={{
					padding: '0 28px 10px',
					fontWeight: '700',
					fontSize: '22px',
					lineHeight: '27px',
					color: Color.DarkText
				}}>
					{title}
				</DialogTitle>
			}

			<DialogContent sx={{
				padding: '0 16px 20px',
				maxHeight: '650px',
				overflow: 'unset'
			}}>
				{children}
			</DialogContent>
		</Dialog>
	);
}

export default CustomDialog;
