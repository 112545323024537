import styled from 'styled-components';
import {Resolution} from '../../const';

export const FilterForm = styled.form`
	margin-left: auto;

	@media (max-width: ${Resolution.SM}) {
		width: 100%;
	}
`;
