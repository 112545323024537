import styled from 'styled-components';
import Button from '../../components/styled/button';
import {Color, Resolution} from '../../const';

export const LoginMain = styled.main`
	background-color: ${Color.Orange};
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;
	flex-grow: 1;
	padding: 16px;
`;

export const LoginForm = styled.form`
	padding: 24px;
	border: 1px solid rgba(171, 167, 175, 0.64);

	background-color: #FFFFFF;
	border-radius: 5px;
`;

export const LoginTitle = styled.h1`
	margin-top: 0;
	font-size: 24px;
`;

export const LoginButton = styled(Button)`
	margin-top: 16px;
	min-width: 100px;
`;

export const LoginLogo = styled.img`
	width: 400px;

	background-color: ${Color.Orange};
	border-radius: 5px;

	@media(max-width: ${Resolution.SM}) {
		width: 300px;
		max-width: 90%;
	}
`;
