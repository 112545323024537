import {LoginButton, LoginForm, LoginLogo, LoginMain, LoginTitle} from './style';
import CustomTextField from '../../components/custom-text-field/custom-text-field';
import {AppRoute, ButtonVariant, LoginStatus, Role} from '../../const';
import Footer from '../../components/footer/footer';
import {useState, MouseEvent, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../store/store';
import {changeLoginStatus, changeUserInfo} from '../../store/user/user';
import {saveUserInfo} from '../../services/user-info';
import {saveToken} from '../../services/token';
import {useNavigate} from 'react-router-dom';
import useTitle from '../../hooks/use-title/use-title';
import axios from 'axios';
import { updateRole } from '../../store/general/general';

const ERROR_TEXT = 'Неверный логин или пароль';

export interface IUser {
	blocked: number,
	email: string,
	id: number,
	name: string,
	role: number,
	role_text: string
}

function LoginScreen(): JSX.Element {
	const [login, setLogin] = useState('');
	const [password, setPassword] = useState('');
	const [isError, setIsError] = useState(false);
	const [isValidate, setIsValidate] = useState(false);
	const [invalidLogin, setInvalidLogin] = useState<boolean | null>(null);
	const [invalidPassword, setInvalidPassword] = useState<boolean | null>(null);
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();

	
	useEffect(() => {
		axios.get('/sanctum/csrf-cookie')
	},[])

	async function submitLoginHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();

		setIsValidate(true);

		if (invalidLogin === false && invalidPassword === false) {	 
			try {
				const response = await axios.post('https://lk.rterminal.ru/signin', { email: login, password: password });
				if(response.data.success) {
					const {data} = await axios.get<IUser>('/spa/user');
					const userInfo = {
						name: data.name,
						role: data.role_text,
						id: data.id
					}
					dispatch(changeLoginStatus(LoginStatus.LoggedIn));
					dispatch(changeUserInfo(userInfo));
					dispatch(updateRole(data.role));
					localStorage.setItem('userRole', data.role.toString());
					saveToken(response.data.data.token);
					saveUserInfo(userInfo);
					const navigateTo = data.role_text === Role.Admin ? AppRoute.Main : data.role_text === Role.Manager ?  AppRoute.Orders : AppRoute.Vehicles
					navigate(navigateTo);
				}
			} catch (error) {
				setIsError(true);
			}
		} else {
			setIsError(true);
		}
	}

	useTitle('Вход | Терминал');

	return <>
		<LoginMain>
			<LoginLogo src="/img/logo.svg" width={400} height={93} alt='Группа компаний "Терминал"'/>

			<LoginForm>
				<LoginTitle>Вход</LoginTitle>

				<CustomTextField
					id="login"
					type="email"
					width="200px"
					widthSm="100%"
					label="Логин"
					value={login}
					onChange={(evt) => setLogin(evt.target.value)}
					required
					error={isError}
					helperText={`${isError ? ERROR_TEXT : ''}`}
					validate={isValidate}
					setInvalid={setInvalidLogin}
				/>

				<CustomTextField
					id="password"
					type="password"
					width="200px"
					widthSm="100%"
					label="Пароль"
					marginLeft="16px"
					marginLeftSm="0"
					marginTopSm="16px"
					value={password}
					onChange={(evt) => setPassword(evt.target.value)}
					required
					error={isError}
					helperText={`${isError ? ' ' : ''}`}
					validate={isValidate}
					setInvalid={setInvalidPassword}
				/>

				<LoginButton variant={ButtonVariant.Main} type="submit" onClick={submitLoginHandler}>
					Войти
				</LoginButton>
			</LoginForm>
		</LoginMain>

		<Footer/>
	</>
}

export default LoginScreen;
