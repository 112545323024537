import styled from 'styled-components';

export const Icon = styled.span`
	width: 18px;
	height: 18px;
	border: 1px solid #D9D1E0;

	background-color: #FFFFFF;
	border-radius: 4px;
`;

export const CheckedIcon = styled(Icon)`
	&::before {
		display: block;
		width: 16px;
		height: 16px;
		background: url("/img/icon-checkbox.svg") no-repeat center;
		content: "";
	}
`;

export const IndeterminateIcon = styled(Icon)`
	&::before {
		display: block;
		width: 16px;
		height: 16px;
		background: url("/img/icon-indeterminate.svg") no-repeat center;
		content: "";
	}
`;

