import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IDocuments } from "../../types/state";

export interface IVehiclesState {
    id: string,
    company_name: string,
    company_id: string,
    arrivaldate: string,
    closingdatedelivery: string,
    dateappointmentinspection: string,
    datedeparture: string,
    docnum: string,
    enddateinspectionfact: string,
    fitovetcontrol: string,
    inningsdt: string,
    locationcar: string,
    notesguard: string,
    numbercar: string,
    numberdt: string,
    numbertrailer: string,
    releasedt: string,
    specialistinformation: string,
    statusdt: string
    target: string,
}

export interface VehiclesInitialState {
    vehicles: IVehiclesState[] | [],
    oldVehicles: IVehiclesState[] | [],
    newVehiclesCount: number,
    documents: IDocuments[],
    searchByNumberCar: string,
    searchBynumberTrailer: string,
    sortBy: string,
    status: string,
    indexCurrentOpenCart: number | null,
    currentCompanyName: string,
    currentCompanyId: string
}

const initialState: VehiclesInitialState = {
    vehicles: [],
    oldVehicles: [],
    newVehiclesCount: 0,
    documents: [],
    searchByNumberCar: '',
    searchBynumberTrailer: '',
    sortBy: '',
    status: '',
    indexCurrentOpenCart: null,
    currentCompanyName: '',
    currentCompanyId: ''
}

const vehiclesSlice = createSlice({
    name: 'vehicles',
    initialState,
    reducers: {
        addVehicles: (state, action) => {
            state.vehicles = action.payload
        },
        addOldVehicles: (state, action) => {
            state.oldVehicles = action.payload
        },
        addDocumentForVehicles: (state, action: PayloadAction<IDocuments>) => {
            state.documents.push(action.payload);
        },
        removeDocumentFromVehicles: (state, action: PayloadAction<string>) => {
            state.documents = state.documents.filter(vehicles => String(vehicles.id) !== action.payload);
        },
        updateSearchByNumberCar: (state, action: PayloadAction<string>) => {
            state.searchByNumberCar = action.payload;
        },
        updateSearchBynumbertrailer: (state, action: PayloadAction<string>) => {
            state.searchBynumberTrailer = action.payload;
        },
        updateSort: (state, action: PayloadAction<string>) => {
            state.sortBy = action.payload;
        },
        updateStatus: (state, action: PayloadAction<string>) => {
            state.status = action.payload;
        },
        resetAllSearch: (state) => {
            state.searchByNumberCar = '';
            state.searchBynumberTrailer = '';
            state.sortBy = '';
            state.status = '';
        },
        addDocumentsVehicles: (state, action) => {
			state.documents = action.payload;
		},
        incrementNewRequestsCountVehicles: (state) => {
            state.newVehiclesCount += 1
			// const countToAdd = action.payload;
			// for (let i = 1; i <= countToAdd; i++) {
			// 	state.newVehiclesCount.push(i);
			// }
		},
        decNewRequestsCountVehicles: (state) => {
            state.newVehiclesCount -= 1
		},

        setIndexCurrentOpenCart: (state, action) => {
            state.indexCurrentOpenCart = action.payload
        },
        setCurrentCompanyName: (state, action) => {
            state.currentCompanyName = action.payload
        },
        setCurrentCompanyId: (state, action) => {
            state.currentCompanyId = action.payload
        }
    }
})

export const {
    removeDocumentFromVehicles,
    addDocumentForVehicles,
    updateStatus,
    addDocumentsVehicles,
    addVehicles,
    updateSearchByNumberCar,
    updateSearchBynumbertrailer,
    updateSort,
    resetAllSearch,
    addOldVehicles,
    incrementNewRequestsCountVehicles,
    decNewRequestsCountVehicles,
    setIndexCurrentOpenCart,
    setCurrentCompanyName,
    setCurrentCompanyId
} = vehiclesSlice.actions
export default vehiclesSlice.reducer;