import styled from "styled-components";

type LabelProps = {
	color: string,
	backgroundColor: string
}

export const Label = styled.p<LabelProps>`
	margin: 0;
	width: 66px;
	height: 33px;

	position: absolute;
	top: 6px;
	right: 16px;

	font-weight: 800;
	font-size: 14px;
	line-height: 33px;
	color: ${({color}) => color};
	text-align: center;

	border-radius: 5px;
	background-color: ${({backgroundColor}) => backgroundColor};
`;
