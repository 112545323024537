import styled from 'styled-components';

export const NotFoundMain = styled.main`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	flex-grow: 1;
	padding: 16px;
	gap: 100px;
`;

export const BigText = styled.p`
	margin: 0;

	font-size: 100px;
`;
