import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface IVehiclesState {
    data: any
}

export interface VehiclesInitialState {
    documents: IVehiclesState[] | [],
    numberCar: string,
    numberTrailer: string,
    statusDocument: string
}

const initialState: VehiclesInitialState = {
    documents: [],
    numberCar: '',
    numberTrailer: '',
    statusDocument: ''
}

const vehiclesDocumentsSlice = createSlice({
    name: 'vehiclesDocuments',
    initialState,
    reducers: {
        addDocuments: (state, action) => {
            state.documents = action.payload
        },
        updateSearchByNumberCar: (state, action: PayloadAction<string>) => {
            state.numberCar = action.payload;
        },
        updateSearchBynumbertrailer: (state, action: PayloadAction<string>) => {
            state.numberTrailer = action.payload;
        },
        updateStatus: (state, action: PayloadAction<string>) => {
            state.statusDocument = action.payload;
        },
        resetAllSearch: (state) => {
            state.numberCar = '';
            state.numberTrailer = '';
            state.statusDocument = '';
        }
    }
})

export const {
    addDocuments,
    updateSearchByNumberCar,
    updateSearchBynumbertrailer,
    updateStatus,
    resetAllSearch
} = vehiclesDocumentsSlice.actions
export default vehiclesDocumentsSlice.reducer;