import {ReactElement} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, useLocation} from 'react-router-dom';
import { getUserRole } from '../../store/general/selectors';

type PrivateRouteProps = {
	children: ReactElement
}

type RouteRoles = {
	[key: string]: number[];
}

const routeRoles: RouteRoles = {
	'/companies': [1],
	'/employees': [1],
	'/managers': [1],
	'/documents/company': [2],
	'/orders': [2],
	'/vehicles': [3],
	'/documents/employee': [3],
};

// function PrivateRoute({children}: PrivateRouteProps) {
// 	const isLoggedIn = useSelector(getLoggedInFlag);

// 	return isLoggedIn ? children : <Navigate to={AppRoute.Login} />;
// }

function PrivateRoute({children}: PrivateRouteProps) {
	const userRole = useSelector(getUserRole);
	const location = useLocation();
	const currentPath = location.pathname;

	if (routeRoles[currentPath] && !routeRoles[currentPath].includes(Number(userRole))) {
		return <Navigate to="/login" />;
	}

	return <>{children}</>;
}

export default PrivateRoute;
