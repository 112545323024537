import { createSlice } from "@reduxjs/toolkit";

export interface IPropsOrder {
	company_name: string,
	created_at: string,
	id: number,
	link?: string,
	locationcar: string,
	numbercar: string,
	numbertrailer: string,
	type: string,
}

export interface IOrder {
	orders: IPropsOrder[] | undefined | null
	companyName: string,
	numberCar: string,
	newRequestsCount: number[]
}

const initialState: IOrder = {
	orders: [],
	companyName: '',
	numberCar: '',
	newRequestsCount: [],
};

const orderSlice = createSlice({
	name: "order",
	initialState,
	reducers: {
		addOrders: (state, action) => {
			state.orders = action.payload;
		},
		changeOrderNameCompanyValue: (state, action) => {
			state.companyName = action.payload
		},
		changeOrderNumberCarValue: (state, action) => {
			state.numberCar = action.payload
		},
		resetOrderFilters: (state) => {
			state.companyName = '',
			state.numberCar = ''
		},
		incrementNewRequestsCount: (state, action) => {
			const countToAdd = action.payload;
			for (let i = 1; i <= countToAdd; i++) {
				state.newRequestsCount.push(i);
			}
		},

		removeRequestByIndex: (state, action) => {
			const indexToRemove = action.payload;
			state.newRequestsCount.splice(indexToRemove, 1);
		},
	}
});

export const {
	changeOrderNameCompanyValue,
	changeOrderNumberCarValue,
	resetOrderFilters,
	addOrders,
	incrementNewRequestsCount,
	removeRequestByIndex,
} = orderSlice.actions;
export default orderSlice.reducer;
