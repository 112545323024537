import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {Dayjs} from 'dayjs';
import {Color, muiTheme} from '../../const';

type CustomDatePickerProps = {
	value?: any,
	label: string,
	defaultValue?: Dayjs,
	width?: string,
	widthLg?: string,
	widthSm?: string,
	order?: number,
	marginTop?: string,
	marginTopLg?: string,
	marginLeft?: string,
	marginLeftLg?: string,
	marginLeftSm?: string,
	disabled?: boolean,
	required?: boolean,
	onChange?: (arg: any) => void
}

function CustomDatePicker(props: CustomDatePickerProps): JSX.Element {
	const {
		value,
		label,
		defaultValue,
		width,
		widthLg,
		widthSm,
		order,
		marginTop,
		marginTopLg,
		marginLeft,
		marginLeftLg,
		marginLeftSm,
		disabled,
		onChange
	} = props;

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ru">
			<DatePicker
				label={label}
				value={value}
				onChange={onChange}
				defaultValue={defaultValue}
				format="DD.MM.YYYY"
				disabled={disabled}
				sx={{
					backgroundColor: '#FFFFFF',
					width: width,
					order: order,
					marginTop: marginTop,
					marginLeft: marginLeft,
					'& .MuiInputBase-input': {
						padding: '12px 0 12px 12px',
						fontFamily: 'inherit',
						fontWeight: 'inherit',
						color: 'inherit',
						fontSize: '14px',
					},
					'& fieldset': {
						borderColor: '#DCDCDC'
					},
					'& .Mui-focused .MuiOutlinedInput-notchedOutline': {
						borderWidth: '1px',
						borderColor: Color.Orange
					},
					'& .Mui-focused.MuiFormLabel-root': {
						color: Color.Orange
					},
					'& .MuiFormLabel-root': {
						'&:not(.Mui-focused):not(.MuiFormLabel-filled)': {
							transform: 'translate(12px, 12px)',
						},
						'&.Mui-focused': {
							color: Color.Orange
						},
						fontFamily: 'inherit',
						fontWeight: 'inherit',
						fontSize: '14px'
					},
					'& .MuiFormLabel-root:not(.Mui-disabled)': {
						color: 'inherit',
					},
					'& .MuiInputBase-root': {
						paddingRight: '12px',
						fontFamily: 'inherit',
						fontWeight: 'inherit',
						fontSize: '14px'
					},
					'& .MuiInputBase-root:not(.Mui-disabled)': {
						color: 'inherit',
					},
					'& .MuiInputAdornment-root': {
						marginLeft: '0'
					},
					'& .MuiSvgIcon-root': {
						width: '20px',
						height: '20px',
						color: Color.Orange
					},
					'& .Mui-disabled .MuiSvgIcon-root': {
						color: 'rgba(0, 0, 0, 0.38)'
					},
					'& .Mui-disabled': {
						opacity: '0.4'
					},
					[muiTheme.breakpoints.down('lg')]: {
						width: widthLg,
						marginLeft: marginLeftLg,
						marginTop: marginTopLg
					},
					[muiTheme.breakpoints.down('sm')]: {
						width: widthSm,
						marginLeft: marginLeftSm
					}
				}}
			/>
		</LocalizationProvider>
	);
}

export default CustomDatePicker;
