import styled from 'styled-components';
import {Color} from '../../const';

export const DialogHead = styled.div`
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: right;
`;

export const CloseButton = styled.button`
	padding: 0;
	margin: 20px 20px 0 0;
	border: none;
	width: 16px;
	height: 16px;
	display: flex;
	justify-content: center;
	align-items: center;

	color: #ABA7AF;

	background-color: transparent;
	cursor: pointer;

	&:hover {
		color: ${Color.Orange};
	}
`;

export const DraggableArea = styled.div`
	flex-grow: 1;
	height: 100%;

	cursor: grab;

	&:active {
		cursor: grabbing;
	}
`;


