import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../store/store';
import {logout} from '../../store/user/user';
import {dropToken} from '../../services/token';
import {Navigate} from 'react-router-dom';
import {AppRoute} from '../../const';
import { useEffect } from 'react';
import { resetRole } from '../../store/general/general';

function Logout(): JSX.Element {
	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		dispatch(logout());
		dispatch(resetRole());
		dropToken();
	}, [])

	return (
		<Navigate to={AppRoute.Login}/>
	)
}

export default Logout;
