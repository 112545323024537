import React, {useEffect} from 'react';
import CompaniesScreen from '../../pages/companies-screen/companies-screen';
import {AppRoute} from '../../const';
import {Navigate, Route, Routes, useLocation} from 'react-router-dom';
import EmployeesScreen from '../../pages/employees-screen/employees-screen';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../store/store';
// import {clearFilters as clearCompanyFilters} from '../../store/companies/companies';
// import {clearFilters as clearEmployeeFilters} from '../../store/employees/employees';
// import {clearFilters as clearManagerFilters} from '../../store/managers/managers';
import ManagersScreen from '../../pages/managers-screen/managers-screen';
import LoginScreen from '../../pages/login-screen/login-screen';
import PrivateRoute from '../private-route/private-route';
import Logout from '../logout/logout';
import NotFoundScreen from '../../pages/not-found-screen/not-found-screen';
import OrdersScreen from '../../pages/orders-screen/orders-screen';
import DocumentsVehicleScreen from "../../pages/documents-vehicle-screen/documents-vehicle-screen";
import DocumentsCompanyScreen from "../../pages/documents-company-screen/documents-company-screen";
import VehiclesScreen from "../../pages/vehicles-screen/vehicles-screen";
import DocumentsEmployeeScreen from "../../pages/documents-employee-screen/documents-employee-screen";
import { resetSearchForEmployee, setAllCompanyForEmployees, setAllEmployees } from '../../store/employees/employees';
import { resetSearchCompany, setAllEmployeesForCompany, setAllcompanies } from '../../store/companies/companies';
import { resetSearchForManager } from '../../store/managers/manager';

function App(): JSX.Element {
	const dispatch = useDispatch<AppDispatch>();
	const location = useLocation();

	useEffect(() => {
		dispatch(resetSearchCompany());
		dispatch(resetSearchForEmployee());
		dispatch(resetSearchForManager())

		dispatch(setAllcompanies([]));
		dispatch(setAllEmployeesForCompany([]));
		dispatch(setAllEmployees([]));
		dispatch(setAllCompanyForEmployees([]));
	}, [location.key]);

	return (
		<Routes>
			<Route path="*" element={<NotFoundScreen/>}/>
			<Route path={AppRoute.Main} element={<Navigate to={AppRoute.Companies}/>}/>
			<Route path={AppRoute.Login} element={<LoginScreen/>}/>
			<Route path={AppRoute.Logout} element={<PrivateRoute><Logout/></PrivateRoute>}/>
			<Route path={AppRoute.Companies} element={<PrivateRoute><CompaniesScreen/></PrivateRoute>}/>
			<Route path={AppRoute.Employees} element={<PrivateRoute><EmployeesScreen/></PrivateRoute>}/>
			<Route path={AppRoute.Managers} element={<PrivateRoute><ManagersScreen/></PrivateRoute>}/>
			<Route path={AppRoute.Orders} element={<PrivateRoute><OrdersScreen/></PrivateRoute>}/>
			<Route path={AppRoute.Documents} element={<PrivateRoute><Navigate to={AppRoute.DocumentsCompany}/></PrivateRoute>}/>
			<Route path={AppRoute.DocumentsVehicle} element={<PrivateRoute><DocumentsVehicleScreen/></PrivateRoute>}/>
			<Route path={AppRoute.DocumentsCompany} element={<PrivateRoute><DocumentsCompanyScreen/></PrivateRoute>}/>
			<Route path={AppRoute.DocumentsEmployee} element={<PrivateRoute><DocumentsEmployeeScreen/></PrivateRoute>}/>
			<Route path={AppRoute.Vehicles} element={<PrivateRoute><VehiclesScreen/></PrivateRoute>}/>
		</Routes>
	);
}

export default App;
