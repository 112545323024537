import styled from "styled-components";
import {Color} from "../../const";

export const CustomLink = styled.a`
	border: none;
	padding: 0;

	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: ${Color.Orange};
	text-decoration: none;

	background-color: transparent;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}

	&:active {
		opacity: 0.6;
	}
`;
