import styled from 'styled-components';
import {Color, DEFAULT_TRANSITION, HeaderButtonIcon, Resolution} from '../../const';

export const InputManagerRoleHide = styled.input`
    opacity: 0;
	display: hidden;
    width: 1px;
    height: 1px;

	@media (max-width: ${Resolution.SM}) {
		flex-wrap: wrap;
	}
`;