import {UserInfo} from '../types/user-info';

const USER_INFO_KEY_NAME = 'terminal-user-info';

export const getUserInfo = (): UserInfo => {
	const userInfoJson = localStorage.getItem(USER_INFO_KEY_NAME);

	return userInfoJson ? JSON.parse(userInfoJson) : null;
};

export const saveUserInfo = (userInfo: UserInfo): void => {
	localStorage.setItem(USER_INFO_KEY_NAME, JSON.stringify(userInfo));
};

export const dropUserInfo = (): void => {
	localStorage.removeItem(USER_INFO_KEY_NAME);
};
