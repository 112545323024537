import VisuallyHidden from "../styled/visually-hidden";
import React, {ReactNode, useEffect, useState} from "react";
import {Body, Card, Header, ToggleButton} from "./style";
import {VehicleStatus, VehicleStatusData} from "../../const";
import {Label} from "../styled/label";

const TRANSITION_DURATION = 200;

type DropdownCardProps = {
	tag?: VehicleStatus,
	children: ReactNode,
	header: ReactNode,
	open?: boolean,
	onChange?: (isOpen: boolean) => void,
	borderColor?: string
}

function DropdownCard({tag, children, header, open: outerOpen, onChange, borderColor}: DropdownCardProps): JSX.Element {
	const [open, setOpen] = useState(false);
	const [transition, setTransition] = useState(false);

	function toggleClickHandler() {
		if (!transition) {
			setTransition(true);

			if (onChange) {
				onChange(!open);
			} else {
				setOpen(!open);
			}

			setTimeout(() => {
				setTransition(false);
			}, TRANSITION_DURATION);
		}
	}

	useEffect(() => {
		if (outerOpen !== undefined) {
			setOpen(outerOpen);
		}
	}, [outerOpen]);

	return (
		<>
			<Card open={open} isDeparted={tag === VehicleStatus.Departed} borderColor={borderColor}>
				<VisuallyHidden as="h2">Карточка сообщений</VisuallyHidden>

				<Header>
					{header}
				</Header>

				<Body open={open} transitionDuration={TRANSITION_DURATION}>
					{children}
				</Body>

				<ToggleButton type="button" onClick={toggleClickHandler} open={open}>
					<VisuallyHidden>Развернуть</VisuallyHidden>
				</ToggleButton>

				{tag &&
					<Label color={VehicleStatusData[tag].color} backgroundColor={VehicleStatusData[tag].backgroundColor}>
						{VehicleStatusData[tag].content}
					</Label>
				}
			</Card>
		</>
	);
}

export default DropdownCard;
