import {MouseEvent, useState} from 'react';
import VisuallyHidden from '../styled/visually-hidden';
import CustomTextField from '../custom-text-field/custom-text-field';
import {ButtonVariant, showOptions} from '../../const';
import {SelectChangeEvent} from '@mui/material';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '../../store/store';
import {StyledScreenControls} from '../styled/styled-screen-controls';
import {ControlsForm} from '../styled/controls-form';
import {IconButton} from '../styled/icon-button';
import {ControlsWrapper} from '../styled/controls-wrapper';
import {FilterForm} from '../styled/filter-form';
import { resetSearchCompany, updateSearchByEmployee, updateSearchByINN, updateSearchByName, updateSearchBySort } from '../../store/companies/companies';

type ScreenControlsCompaniesProps = {
	closeDialog?: () => void
}

function ScreenControlsCompanies({closeDialog}: ScreenControlsCompaniesProps): JSX.Element {
	// const filters = useSelector(getFilters);
	const [sortBy, setSortBy] = useState('all');
	const [innerNumber, setInnerNumber] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [employee, setEmployee] = useState('');
	const dispatch = useDispatch<AppDispatch>();

	function resetClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();

		setInnerNumber('');
		setCompanyName('');
		setEmployee('');
		dispatch(resetSearchCompany())
		dispatch(updateSearchBySort('all'));

		if (closeDialog) {
			closeDialog();
		}
	}

	function viewChangeHandler(evt: SelectChangeEvent) {
		dispatch(updateSearchBySort(evt.target.value));
		setSortBy(evt.target.value)
		if (closeDialog) {
			closeDialog();
		}
	}

	function submitClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();

		dispatch(updateSearchByINN(innerNumber));
		dispatch(updateSearchByName(companyName));
		dispatch(updateSearchByEmployee(employee));

		if (closeDialog) {
			closeDialog();
		}
	}

	return (
		<StyledScreenControls>
			<VisuallyHidden as="h2">Управление</VisuallyHidden>

			<ControlsForm>
				<CustomTextField
					id="inner-number"
					label="ИНН"
					value={innerNumber}
					width="175px"
					widthSm="100%"
					widthMd="130px"
					marginTopSm="8px"
					onChange={(evt) => {
						setInnerNumber(evt.target.value.replace(/[^0-9]/g, ''));
					}}
				/>

				<CustomTextField
					id="company-name"
					label="Название компании"
					value={companyName}
					width="175px"
					widthSm="100%"
					widthMd="130px"
					onChange={(evt) => {
						setCompanyName(evt.target.value);
					}}
				/>

				<CustomTextField
					id="employee"
					label="Сотрудник"
					value={employee}
					width="191px"
					widthSm="100%"
					widthMd="130px"
					onChange={(evt) => {
						setEmployee(evt.target.value);
					}}
				/>

				<IconButton type="submit" onClick={submitClickHandler} variant={ButtonVariant.Main}>
					<svg width="24" height="24" viewBox="0 0 24 24">
						<use xlinkHref="/img/sprite.svg#check-circle"></use>
					</svg>

					<span>Применить</span>
				</IconButton>

				<IconButton type="reset" onClick={resetClickHandler} variant={ButtonVariant.Secondary}>
					<svg width="24" height="24" viewBox="0 0 24 24">
						<use xlinkHref="/img/sprite.svg#cross-circle"></use>
					</svg>

					<span>Сбросить</span>
				</IconButton>
			</ControlsForm>

			<ControlsWrapper>
				{/* <ButtonControl icon={ControlButtonIcon.Add} onClick={() => setIsDialogOpen(true)}>
					Добавить
				</ButtonControl> */}

				{/* <ButtonControl icon={ControlButtonIcon.Excel} marginLeft="20px">Экспортировать в Excel</ButtonControl> */}

				<FilterForm>
					<CustomTextField
						id="view-option"
						ariaLabel="Отображение"
						select
						options={showOptions}
						value={sortBy}
						width="202px"
						widthSm="100%"
						onChange={viewChangeHandler}
					/>
				</FilterForm>
			</ControlsWrapper>

			{/* <CustomDialog
				open={isDialogOpen}
				onClose={() => setIsDialogOpen(false)}
				title="Добавить компанию"
			>
				<GreyWrapper as="form">
							<CustomAutocomplete
								id="companies-to-add"
								label="Компании"
								options={companyOptions}
								width="400px"
								widthSm="100%"
								value={companyToAdd}
								onChange={(evt, newValue) => setCompanyToAdd(newValue)}
							/>

							<ControlsFlexWrapper>
								<Button
									type="submit"
									variant={ButtonVariant.Main}
									onClick={addCompanyClickHandler}
									disabled={!companyToAdd}
								>
									Добавить
								</Button>
							</ControlsFlexWrapper>
				</GreyWrapper>
			</CustomDialog> */}
		</StyledScreenControls>
	);
}

export default ScreenControlsCompanies;
