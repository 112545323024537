import {StyledScreenControls} from '../styled/styled-screen-controls';
import VisuallyHidden from '../styled/visually-hidden';
import {MouseEvent, useState} from 'react';
import {ControlsForm} from '../styled/controls-form';
import {useDispatch} from 'react-redux';
import CustomTextField from '../custom-text-field/custom-text-field';
import {IconButton} from '../styled/icon-button';
import { ButtonVariant, ControlButtonIcon, showOptions} from '../../const';
import {AppDispatch} from '../../store/store';
import {ButtonControl} from '../styled/button-control';
import {FilterForm} from '../styled/filter-form';
import {ControlsWrapper} from '../styled/controls-wrapper';
import {SelectChangeEvent} from '@mui/material';
import {GreyWrapper} from '../styled/grey-wrapper';
import {ControlsFlexWrapper} from '../styled/controls-flex-wrapper';
import Button from '../styled/button';
import CustomDialog from '../custom-dialog/custom-dialog';
import {toast} from 'react-toastify';
import axios from 'axios';
import { InputManagerRoleHide } from './style';
import { addManager, resetSearchForManager, updateSearchManagerByCompany, updateSearchManagerByName, updateSortBy } from '../../store/managers/manager';

type ScreenControlsManagersProps = {
	closeDialog?: () => void
}

function ScreenControlsManagers({closeDialog}: ScreenControlsManagersProps): JSX.Element {
	const [sortBy, setSortBy] = useState('all');
	const [manager, setManager] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const role = 2;
	const dispatch = useDispatch<AppDispatch>();

	const handleSubmitManager = async (evt: MouseEvent<HTMLButtonElement>) => {
		evt.preventDefault();
		try {
			const response = await axios.post('/spa/users/register', { name, email, role });
			if(response.status === 200) {
				setIsDialogOpen(false);
				dispatch(addManager({
					id: response.data.data.id,
					companyIds: [],
					fullName: name,
					isBlocked: false,
					isChecked: false
				}))
				toast('Менеджер успешно добавлен', {type: 'success'});
				setEmail('');
				setName('');
				if (closeDialog) {
					closeDialog();
				}
			}
		} catch (error) {
			toast(`Ой, что-то пошло не так. Перезагрузите страницу`, {type: 'error'});
		}
	}

	function submitClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		dispatch(updateSearchManagerByName(manager));
		dispatch(updateSearchManagerByCompany(companyName));

		if (closeDialog) {
			closeDialog();
		}
	}

	function resetClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();

		setCompanyName('');
		setManager('');
		dispatch(resetSearchForManager());
		dispatch(updateSortBy('all'))

		if (closeDialog) {
			closeDialog();
		}
	}

	function viewChangeHandler(evt: SelectChangeEvent) {
		dispatch(updateSortBy(evt.target.value));
		setSortBy(evt.target.value)
		if (closeDialog) {
			closeDialog();
		}
	}

	return (
		<StyledScreenControls>
			<VisuallyHidden as="h2">Управление</VisuallyHidden>

			<ControlsForm>
				<CustomTextField
					id="manager"
					label="Менеджер"
					value={manager}
					width="175px"
					widthSm="100%"
					widthMd="130px"
					marginTopSm="8px"
					onChange={(evt) => {
						setManager(evt.target.value);
					}}
				/>

				<CustomTextField
					id="company-name"
					label="Название компании"
					value={companyName}
					width="175px"
					widthSm="100%"
					widthMd="130px"
					onChange={(evt) => {
						setCompanyName(evt.target.value);
					}}
				/>

				<IconButton type="submit" onClick={submitClickHandler} variant={ButtonVariant.Main}>
					<svg width="24" height="24" viewBox="0 0 24 24">
						<use xlinkHref="/img/sprite.svg#check-circle"></use>
					</svg>

					<span>Применить</span>
				</IconButton>

				<IconButton type="reset" onClick={resetClickHandler} variant={ButtonVariant.Secondary}>
					<svg width="24" height="24" viewBox="0 0 24 24">
						<use xlinkHref="/img/sprite.svg#cross-circle"></use>
					</svg>

					<span>Сбросить</span>
				</IconButton>
			</ControlsForm>

			<ControlsWrapper>
				<ButtonControl icon={ControlButtonIcon.Add} onClick={() => setIsDialogOpen(true)}>
					Добавить
				</ButtonControl>

				{/* <ButtonControl icon={ControlButtonIcon.Excel} marginLeft="20px">Экспортировать в Excel</ButtonControl> */}

				<FilterForm>
					<CustomTextField
						id="view-option"
						ariaLabel="Отображение"
						select
						options={showOptions}
						value={sortBy}
						width="202px"
						widthSm="100%"
						onChange={viewChangeHandler}
					/>
				</FilterForm>
			</ControlsWrapper>

			<CustomDialog
				open={isDialogOpen}
				onClose={() => setIsDialogOpen(false)}
				title="Добавить менеждера"
			>
				<GreyWrapper as="form">
							<CustomTextField
								id="inner-nameMan"
								label="Имя менеджера"
								value={name}
								width="275px"
								widthSm="100%"
								widthMd="130px"
								marginTopSm="8px"
								onChange={(evt) => {
									setName(evt.target.value);
								}}
							/>
							<CustomTextField
								id="inner-emailMan"
								label="Почта менеджера"
								value={email}
								width="275px"
								widthSm="100%"
								widthMd="130px"
								marginTop="14px"
								marginTopSm="8px"
								onChange={(evt) => {
									setEmail(evt.target.value);
								}}
							/>
							<InputManagerRoleHide type='number' placeholder='Введите имя менеджера' value={role}/>

							<ControlsFlexWrapper>
								<Button
									type="submit"
									variant={ButtonVariant.Main}
									onClick={handleSubmitManager}
									disabled={!name && !email}
								>
									Добавить
								</Button>
							</ControlsFlexWrapper>
				</GreyWrapper>
			</CustomDialog>
		</StyledScreenControls>
	);
}

export default ScreenControlsManagers;
