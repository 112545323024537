import styled from 'styled-components';
import {Resolution} from '../../const';

export const ControlsForm = styled.form`
	display: flex;
	gap: 15px;

	@media(max-width: ${Resolution.SM}) {
		flex-wrap: wrap;
	}

	@media(max-width: ${Resolution.MD}) {
		gap: 10px;
	}
`;

export const WrapControlsForm = styled(ControlsForm)`
	flex-wrap: wrap;
`;
