import React from 'react';
import styled from 'styled-components';
import {Resolution} from '../../const';

const Container = styled.div`
	width: 100%;
	height: 100%;
	padding-left: 32px;
	padding-right: 32px;
	margin-left: auto;
	margin-right: auto;

	@media (min-width: ${Resolution.XL}) {
		max-width: 1526px;
		padding-left: 10px;
		padding-right: 10px;
	}
`;

export default Container;
