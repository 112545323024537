import {IDocuments, State} from '../../../types/state';
import {NameSpace} from '../../root-reducer';
import {ICompaniesState} from '../companies';
import { IEmployeesState } from '../../employees/employees';

export const getCompanies = (state: State): ICompaniesState[] | null | undefined => state[NameSpace.Companies].companies;
export const getEmployeesForCompany = (state: State): IEmployeesState[] | null | undefined => state[NameSpace.Companies].employeesForCompany;
export const getCompanyDocuments = (state: State): IDocuments[] | null | undefined => state[NameSpace.Companies].companiesDocuments;
export const getSearchValueINN = (state: State): string => state[NameSpace.Companies].searchByINN;
export const getSearchValueName = (state: State): string => state[NameSpace.Companies].searchByName;
export const getSearchValueEmployee = (state: State): string => state[NameSpace.Companies].searchByEmployee;
export const getSearchValueSort = (state: State): string => state[NameSpace.Companies].sortBy;

