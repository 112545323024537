import styled from 'styled-components';
import {Color, ControlButtonIcon, DEFAULT_TRANSITION, Resolution} from '../../const';

type ButtonProps = {
	icon: ControlButtonIcon,
	marginLeft?: string
}

function handleIconType(icon: ControlButtonIcon) {
	switch (icon) {
		case ControlButtonIcon.Add:
			return 'url(/img/icon-folder-plus.svg)';
		case ControlButtonIcon.Excel:
			return 'url(/img/icon-file.svg)';
	}
}

export const ButtonControl = styled.button<ButtonProps>`
	padding: 0 0 0 32px;
	border: none;
	margin-left: ${props => props.marginLeft};

	position: relative;

	font-weight: 600;
	font-size: 14px;
	line-height: 17px;

	background-color: transparent;
	cursor: pointer;
	transition: ${DEFAULT_TRANSITION};

	&:hover {
		color: ${Color.Orange};
	}

	&::before {
		width: 25px;
		height: 25px;

		position: absolute;
		top: 50%;
		left: 0;

		content: "";

		transform: translateY(-50%);
		background-image: ${({icon}) => handleIconType(icon)};
		background-repeat: no-repeat;
		background-position: center;
	}

	@media(max-width: ${Resolution.SM}) {
		margin: 16px 8px 16px;
	}
`;
