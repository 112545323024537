import styled from 'styled-components';
import {Color, Resolution} from '../../const';

export const StyledTable = styled.table`
	width: 100%;
	margin-top: 24px;

	border-collapse: collapse;

	& tbody tr {
		border-top: 1px solid #DADADA;
	}

	& td:not(.checkbox),
	& th:not(.checkbox) {
		padding: 14px 12px 14px 0;

		font-size: 14px;
		line-height: 17px;
		color: ${Color.DarkText};
	}

	& th {
		font-weight: 600;
		text-align: left;
	}

	& td,
	& th {
		vertical-align: top;
	}

	& td.checkbox,
	& th.checkbox {
		padding-top: 4px;
	}

	& tr.blocked td {
		color: #ABA7AF;
	}

	@media (max-width: ${Resolution.SM}) {
		display: block;

		& tr {
			display: flex;
			flex-wrap: wrap;
		}

		& tbody tr {
			border: 1px solid #DADADA;
			padding: 16px;
			margin-bottom: 16px;

			border-radius: 5px;
		}

		& th:not(.checkbox) {
			display: none;
		}

		& td {
			display: block;

			&.table-name {
				width: 100%;
			}

			&.table-employees {
				width: 100%;
			}

			&.table-actions {
				width: 100%;
			}
		}
	}

	@media (max-width: ${Resolution.LG}) {
		& .medium-hidden {
			width: 1px;
			height: 1px;
			margin: 0;
			padding: 0;

			position: absolute;

			clip: rect(1px 1px 1px 1px);
			clip: rect(1px, 1px, 1px, 1px);
			overflow: hidden;
			white-space: nowrap;
		}
	}
`;
