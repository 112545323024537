import {createTheme} from '@mui/material';

export enum AppRoute {
	Main = '/',
	Companies = '/companies',
	Employees = '/employees',
	Managers = '/managers',
	Login = '/login',
	Logout = '/logout',
	Orders = '/orders',
	Documents = '/documents',
	DocumentsVehicle = '/documents/vehicle',
	DocumentsCompany = '/documents/company',
	DocumentsEmployee = '/documents/employee',
	Vehicles = '/vehicles'
}

export enum Role {
	Admin = 'ADMIN',
	Employee = 'EMPLOYEE',
	Manager = 'MANAGER'
}

export enum LoginStatus {
	LoggedIn = 'LOGGED_IN',
	LoggedOut = 'LOGGED_OUT',
	Unknown = 'UNKNOWN'
}

export enum Color {
	Orange = '#F25C19',
	DarkText = '#1A141F',
	ButtonHover = '#534F57',
	Yellow = '#F29819',
	Green = 'rgba(143, 235, 128, 0.75)'
}

export const DEFAULT_TRANSITION = 'all 200ms linear';

export enum ControlButtonIcon {
	Add = 'add',
	Excel = 'excel'
}

export enum HeaderButtonIcon {
	Edit = 'edit',
	Logout = 'logout'
}

export enum OptionShow {
	All = 'all',
	Active = 'active',
	Blocked = 'blocked'
}

export const showOptions = [
	{
		value: OptionShow.All,
		label: {
			main: 'Показать все'
		}
	},
	{
		value: OptionShow.Active,
		label: {
			main: 'Показать активные'
		}
	},
	{
		value: OptionShow.Blocked,
		label: {
			main: 'Заблокированные'
		}
	}
];

export enum FooterLinkIcon {
	Compass = 'compass',
	Phone = 'phone',
	Email = 'email'
}

export enum ButtonVariant {
	Main = 'main',
	Secondary = 'secondary',
	Contained = 'contained'
}

export const Resolution = {
	SM: '800px',
	MD: '1000px',
	LG: '1280px',
	XL: '1550px'
}

export const muiTheme = createTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: parseInt(Resolution.SM),
			md: parseInt(Resolution.MD),
			lg: parseInt(Resolution.LG),
			xl: parseInt(Resolution.XL),
		},
	},
});

export enum DropdownCardTagIcon {
	Truck = 'truck',
	Trailer = 'trailer',
	Calendar = 'calendar',
	Crosshair = 'crosshair'
}

export enum VehicleStatus {
	Customs = 'CUSTOMS',
	Storage = 'STORAGE',
	OpenStorage = 'OPEN_STORAGE',
	Departed = 'DEPARTED'
}

export const VehicleStatusData = {
	[VehicleStatus.Customs]: {
		content: 'ЗТК',
		backgroundColor: Color.Green,
		color: Color.DarkText
	},
	[VehicleStatus.Storage]: {
		content: 'СВХ',
		backgroundColor: Color.Yellow,
		color: Color.DarkText
	},
	[VehicleStatus.OpenStorage]: {
		content: 'ОП. СВХ',
		backgroundColor: Color.Yellow,
		color: Color.DarkText
	},
	[VehicleStatus.Departed]: {
		content: 'Убыл',
		backgroundColor: Color.ButtonHover,
		color: '#FFFFFF'
	}
}
