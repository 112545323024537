import { useDispatch, useSelector } from "react-redux";
import TableRowCompanies from "../table-row-companies/table-row-companies";
import { StyledTable } from "../styled/styled-table";
import {
	getCompanies, getEmployeesForCompany, getSearchValueEmployee, getSearchValueINN, getSearchValueName, getSearchValueSort,
} from "../../store/companies/selectors/selectors";
import {
	ICompaniesState,
	setAllEmployeesForCompany,
	setAllcompanies,
} from "../../store/companies/companies";
import { IEmployeesState } from "../../store/employees/employees";
import { useEffect, useState } from "react";
import axios from "axios";
import { AppDispatch } from "../../store/store";
import { Loader } from "../Loader/Loader";
import { AppRoute } from "../../const";
import { useNavigate } from "react-router-dom";

function TableCompanies(): JSX.Element {
	const [sortedCompanies, setSortedCompanies] = useState<
		ICompaniesState[] | null | undefined
	>([]);
	const dispatch = useDispatch<AppDispatch>();
	const companyEmployees = useSelector(getEmployeesForCompany);
	const companies = useSelector(getCompanies);
	const searchINN = useSelector(getSearchValueINN);
	const searchName = useSelector(getSearchValueName);
	const searchEmployee = useSelector(getSearchValueEmployee);
	const searchSortBy = useSelector(getSearchValueSort);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		const fetchEmployees = async () => {
			const result = await axios.get('/spa/users?role=3');
			if(result.status === 401) {
				navigate(AppRoute.Login);
			}
			dispatch(setAllEmployeesForCompany(result.data.data))
		}
		try {
			fetchEmployees()
		} catch (error: any) {
			if(error.response && error.response.status === 401) {
				navigate(AppRoute.Login)
			}
		}
	}, [])

	useEffect(() => {
		if (searchSortBy === "active") {
			const sorted = companies?.filter((item) => !item.isBlocked);
			setSortedCompanies(sorted);
		} else if (searchSortBy === "blocked") {
			const sorted = companies?.filter((item) => item.isBlocked);
			setSortedCompanies(sorted);
		} else if (searchSortBy === "all") {
			setSortedCompanies(companies);
		}
	}, [searchSortBy]);

	useEffect(() => {
		setLoading(true);
		const fetchCompanies = async () => {
			const result = await axios.get("/spa/companies");
			dispatch(setAllcompanies(result.data.data));
			setLoading(false);
		};
		try {
			fetchCompanies();
		} catch (error: any) {
			if (error.response && error.response.status === 401) {
				navigate(AppRoute.Login);
			}
		}
	}, []);

	const filterCompanies = (
		sortedCompanies: ICompaniesState[] | null | undefined,
		searchByINN: string,
		searchByName: string,
		searchByEmployee: string,
		employees: IEmployeesState[] | null | undefined
	): ICompaniesState[] | undefined => {
		const currentCompany = sortedCompanies?.length
			? sortedCompanies
			: companies;

		return currentCompany?.filter(item => {
			if(searchByINN !== '') {
				return item.innerNumber.includes(searchByINN)
			} else {
				return item
			}
		}).filter(item => {
			if(searchByName !== '') {
				return item.name.toLowerCase().includes(searchByName.toLowerCase())
			} else {
				return item
			}
		}).filter(item => {
			if(searchByEmployee !== '') {
				const employeeyId = employees?.filter((emp) => {
					return emp.fullName
						.toLowerCase()
						.includes(searchByEmployee.toLowerCase());
				});
				const matchesEmployee = employeeyId?.every((emp) =>
					item.employeeIds.includes(emp.id)
				);
				return matchesEmployee
			} else {
				return item.employeeIds
			}
		})

	};

	return (
		<>
			{sortedCompanies && (
				<StyledTable>
					<thead>
						<tr>
							{/* <th className="checkbox">
					<CustomCheckbox
						ariaLabel="Выбрать все"
						label="Выбрать все"
						checked={isAllChecked<Company>(companies)}
						onChange={checkboxChangeHandler}
						indeterminate={isIndeterminate<Company>(companies)}
						isLabelMobile
					/>
				</th>  */}
							<th>
								<span className="medium-hidden">№ П/П</span>
							</th>
							<th>ИНН</th>
							<th>Название компании</th>
							<th>Сотрудники</th>
							<th>
								<span className="medium-hidden">Действия</span>
							</th>
						</tr>
					</thead>

					<tbody>
						{loading ? (
							<Loader />
						) : searchINN || searchName || searchEmployee ? (
							filterCompanies(
								sortedCompanies,
								searchINN,
								searchName,
								searchEmployee,
								companyEmployees
							)?.map((company, i) => (
								<TableRowCompanies
									company={company}
									index={i + 1}
									employees={companyEmployees}
									key={company.id}
								/>
							))
						) : sortedCompanies?.length ? (
							sortedCompanies?.map((company, i) => (
								<TableRowCompanies
									company={company}
									index={i + 1}
									employees={companyEmployees}
									key={company.id}
								/>
							))
						) : (
							filterCompanies(
								companies,
								searchINN,
								searchName,
								searchEmployee,
								companyEmployees
							)?.map((company, i) => (
								<TableRowCompanies
									company={company}
									index={i + 1}
									employees={companyEmployees}
									key={company.id}
								/>
							))
						)}
					</tbody>
				</StyledTable>
			)}
		</>
	);
}

export default TableCompanies;