import styled from 'styled-components';
import {ButtonVariant, Color, DEFAULT_TRANSITION, Resolution} from '../../const';

type ButtonProps = {
	variant: ButtonVariant,
	margin?: string,
	maxWidth?: string,
	maxWidthLg?: string
}

function handleButtonVariant(variant: ButtonVariant) {
	switch (variant) {
		case ButtonVariant.Main:
			return {
				backgroundColor: {
					common: Color.Orange,
					hover: Color.ButtonHover
				},
				color: {
					common: '#FFFFFF',
					hover: '#FFFFFF'
				}
			};
		case ButtonVariant.Secondary:
			return {
				backgroundColor: {
					common: '#E9E9E9',
					hover: Color.ButtonHover
				},
				color: {
					common: Color.DarkText,
					hover: '#FFFFFF'
				}
			};
	}
}

const Button = styled.button<ButtonProps>`
	padding: 4px 13px;
	border: none;
	min-height: 44px;
	max-width: ${({maxWidth}) => maxWidth};
	display: flex;
	justify-content: center;
	align-items: center;
	margin: ${({margin}) => margin};

	color: ${({variant}) => handleButtonVariant(variant)?.color.common};
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	font-family: inherit;
	text-decoration: none;

	border-radius: 5px;
	transition: ${DEFAULT_TRANSITION};
	background-color: ${({variant}) => handleButtonVariant(variant)?.backgroundColor.common};
	cursor: pointer;

	&:hover {
		color: ${({variant}) => handleButtonVariant(variant)?.color.hover};

		background-color: ${({variant}) => handleButtonVariant(variant)?.backgroundColor.hover};
	}

	&:disabled {
		opacity: 0.4;
		cursor: auto;

		&:hover {
			color: ${({variant}) => handleButtonVariant(variant)?.color.common};

			background-color: ${({variant}) => handleButtonVariant(variant)?.backgroundColor.common};
		}
	}

	@media (max-width: ${Resolution.LG}) {
		max-width: ${({maxWidthLg}) => maxWidthLg};
	}
`;

export default Button;
