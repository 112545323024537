import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ICompaniesState } from "../companies/companies";

export interface IEmployeesState {
    id: number,
    companyIds: number[],
    fullName: string,
    isBlocked: boolean,
    isChecked: boolean
}

export interface EmployeesInitialState {
    employees: IEmployeesState[],
    companyForEmployees: ICompaniesState[],
    searchByCompanyName: string,
    searchByEmployee: string,
    sortBy: string
}

const initialState: EmployeesInitialState = {
    employees: [],
    companyForEmployees: [],
    searchByCompanyName: '',
    searchByEmployee: '',
    sortBy: 'all'
}


const employeesSlice = createSlice({
    name: 'employees',
    initialState,
    reducers: {
        setAllEmployees: (state, action) => {
            state.employees = action.payload
        },
        setAllCompanyForEmployees: (state, action) => {
            state.companyForEmployees = action.payload
        },
        addEmployee: (state, action: PayloadAction<IEmployeesState>) => {
            state.employees.push(action.payload);
        },
        removeEmployee: (state, action: PayloadAction<number>) => {
            state.employees = state.employees.filter(employee => employee.id !== action.payload);
        },
		updateEmployee: (state, action) => {
			const {employeeId, newFullName} = action.payload;
			const employee = state.employees?.slice().find((employee) => employee.id === employeeId);

			if (employee) {
				employee.fullName = newFullName;
			}
		},
        addCompanyToEmployee: (state, action: PayloadAction<{employeeId: number, companyId: number}>) => {
            const employeeId = action.payload.employeeId;
            const companyId = action.payload.companyId;

            const employee = state.employees.find((emp) => emp.id === employeeId);
            if (employee) {
                employee.companyIds.push(companyId);
            }
        },
        removeCompanyFromEmployee: (state, action: PayloadAction<{employeeId: number | undefined, companyId: number | undefined}>) => {
            const employeeId = action.payload.employeeId;
            const companyId = action.payload.companyId;

            const employee = state.employees.find((emp) => emp.id === employeeId);

            if(employee) {
                employee.companyIds = employee.companyIds.filter((id) => id !== companyId);
            }            
        },
        blockEmployee: (state, action) => {
			const employee = state.employees?.slice().find((emp) => emp.id === action.payload);

			if (employee) {
				if (employee.isChecked) {
					state.employees?.filter((emp) => emp.isChecked).forEach((emp) => emp.isBlocked = true);
				} else {
					employee.isBlocked = true;
				}
			}
		},
		unlockEmployee: (state, action) => {
			const employee = state.employees?.slice().find((emp) => emp.id === action.payload);

			if (employee) {
				if (employee.isChecked) {
					state.employees?.filter((emp) => emp.isChecked).forEach((emp) => emp.isBlocked = false);
				} else {
					employee.isBlocked = false;
				}
			}
		},
        updateSearchByCompany: (state, action: PayloadAction<string>) => {
        state.searchByCompanyName = action.payload;
        },
        updateSearchByEmployeeName: (state, action: PayloadAction<string>) => {
        state.searchByEmployee = action.payload;
        },
        updateSearchBySort: (state, action: PayloadAction<string>) => {
            state.sortBy = action.payload;
            },
        resetSearchForEmployee: (state) => {
            state.searchByCompanyName = '';
            state.searchByEmployee = '';
            state.sortBy = 'all'
        },
    }
})

export const {
    setAllCompanyForEmployees,
    updateEmployee,
    updateSearchBySort,
    updateSearchByCompany,
    updateSearchByEmployeeName,
    setAllEmployees,
    addEmployee,
    removeEmployee,
    addCompanyToEmployee,
    removeCompanyFromEmployee,
    blockEmployee,
    unlockEmployee,
    resetSearchForEmployee
} = employeesSlice.actions
export default employeesSlice.reducer;