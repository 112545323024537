type TypeWithId = {
	id: number
}

type TypeWithCheckedFlag = {
	isChecked: boolean
}

export function getSurnameWithInitials(fullName: string | undefined) {
	if (fullName) {
		const fullNameComponents = fullName.split(' ');

		let surnameWithInitials = fullNameComponents[0];

		if (fullNameComponents[1]) {
			surnameWithInitials += ` ${fullNameComponents[1].charAt(0)}.`;
		}

		if (fullNameComponents[2]) {
			surnameWithInitials += ` ${fullNameComponents[2].charAt(0)}.`;
		}

		return surnameWithInitials;
	}

	return undefined;
}

export function getNamePatronymic(fullName: string | undefined) {
	if (fullName) {
		const fullNameComponents = fullName.split(' ');

		return `${fullNameComponents[1]} ${fullNameComponents[2] ? fullNameComponents[2] : fullNameComponents[0]}`;
	}

	return undefined;
}

export function getElementsByIds<T extends TypeWithId>(ids: number[], elements: T[] | null | undefined) {
	return ids.map((id) => elements?.find((element) => element.id === id));
}

export function isContains<T>(where: T[], what: T[] | null | undefined) {
	if (!where.length) {
		return true;
	}

	if (what) {
		for (let i = 0; i < what.length; i++) {
			if (where.indexOf(what[i]) + 1) {
				return true;
			}
		}
	}

	return false;
}

export function isAllChecked<T extends TypeWithCheckedFlag>(elements: T[]) {
	if (elements) {
		for (let i = 0; i < elements.length; i++) {
			if (!elements[i].isChecked) {
				return false;
			}
		}
	}

	return true;
}

export function isIndeterminate<T extends TypeWithCheckedFlag>(elements: T[]) {
	if (elements && elements.length > 1) {
		const initValue = elements[0].isChecked;

		for (let i = 1; i < elements.length; i++) {
			if (initValue !== elements[i].isChecked) {
				return true;
			}
		}
	}

	return false;
}
