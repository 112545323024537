import {BigText, NotFoundMain} from './style';
import Button from '../../components/styled/button';
import {AppRoute, ButtonVariant} from '../../const';
import {Link} from 'react-router-dom';
import Footer from '../../components/footer/footer';
import useTitle from "../../hooks/use-title/use-title";

function NotFoundScreen(): JSX.Element {
	useTitle('Страница не найдена | Терминал');

	return <>
		<NotFoundMain>
			<BigText>404</BigText>

			<h1>Страница не найдена</h1>

			<Button as={Link} to={AppRoute.Main} variant={ButtonVariant.Main}>На главную</Button>
		</NotFoundMain>

		<Footer/>
	</>
}

export default NotFoundScreen;
