import { useDispatch, useSelector } from "react-redux";
import { StyledTable } from "../styled/styled-table";
import { useEffect, useState } from "react";
import TableRowManagers from "../table-row-managers/table-row-managers";
import axios from "axios";
import { AppDispatch } from "../../store/store";
import {
	IManagersState,
	setAllCompanyForManager,
	setAllManagers,
} from "../../store/managers/manager";
import {
	getCompanyForManager,
	getManagerSortBy,
	getManagers,
	getSearchValueManager,
	getSearchValueManagerByCompany,
} from "../../store/managers/selectors/selectors";
import {
	ICompaniesState,
} from "../../store/companies/companies";
import { Loader } from "../Loader/Loader";

function TableManagers(): JSX.Element {
	const dispatch = useDispatch<AppDispatch>();
	const [sortedManager, setSortedManager] = useState<
		IManagersState[] | null | undefined
	>([]);
	const [loading, setLoading] = useState(true);

	const managerSortBy = useSelector(getManagerSortBy);
	const companies = useSelector(getCompanyForManager);
	const managers = useSelector(getManagers);
	const searchManager = useSelector(getSearchValueManager);
	const searchManagerToCompany = useSelector(getSearchValueManagerByCompany);

	useEffect(() => {
		if (managerSortBy === "active") {
			const sorted = managers?.filter((item) => !item.isBlocked);
			setSortedManager(sorted);
		} else if (managerSortBy === "blocked") {
			const sorted = managers?.filter((item) => item.isBlocked);
			setSortedManager(sorted);
		} else if (managerSortBy === "all") {
			setSortedManager(managers);
		}
	}, [managerSortBy, managers]);

	useEffect(() => {
		setLoading(true);
		const fetchManagers = async () => {
			const result = await axios.get("/spa/users?role=2");
			dispatch(setAllManagers(result.data.data));
			setLoading(false);
		};
		fetchManagers();
	}, []);

	useEffect(() => {
		async function fetchCompany() {
			const result = await axios.get(`/spa/companies`);
			dispatch(setAllCompanyForManager(result.data.data));
		}
		if (!companies?.length) {
			fetchCompany();
		}
	}, []);

	const filterManagers = (
		sortedManager: IManagersState[] | null | undefined,
		searchManager: string,
		searchManagerToCompany: string,
		companies: ICompaniesState[] | null | undefined
	): IManagersState[] | undefined => {
		const currentManager = sortedManager?.length ? sortedManager : managers;

		return currentManager?.filter(item => {
			if(searchManager !== '') {
				return item.fullName.toLowerCase().includes(searchManager.toLowerCase())
			} else {
				return item
			}
		}).filter(item => {
			if(searchManagerToCompany !== '') {
				const companyId = companies?.filter((company) => {
				return company.name
					.toLowerCase()
					.includes(searchManagerToCompany.toLowerCase());
			});
			const matchesEmployee = companyId?.every((company) =>
				item.companyIds.includes(company.id)
			);
			return matchesEmployee
			} else {
				return item
			}
			
		})
	};
	const filteredManagers = filterManagers(
		sortedManager,
		searchManager,
		searchManagerToCompany,
		companies
	);

	/*function checkboxChangeHandler(evt: React.ChangeEvent<HTMLInputElement>) {
		managers?.forEach((manager) => {
			dispatch(setIsChecked({
				id: manager.id,
				isChecked: evt.target.checked
			}));
		});
	}*/

	return (
		<>
			{loading ? (
				<Loader />
			) : managers && managers?.length ? (
				<StyledTable>
					<thead>
						<tr>
							{/* <th className="checkbox">
									<CustomCheckbox
										ariaLabel="Выбрать все"
										label="Выбрать все"
										checked={isAllChecked<Manager>(managers)}
										onChange={checkboxChangeHandler}
										indeterminate={isIndeterminate<Manager>(managers)}
										isLabelMobile
									/>
								</th> */}

							<th>
								<span className="medium-hidden">№ П/П</span>
							</th>
							<th>ФИО менеджера</th>
							<th>Количество компаний</th>
							<th>
								<span className="medium-hidden">Действия</span>
							</th>
						</tr>
					</thead>

					<tbody>
						{searchManager || searchManagerToCompany
							? filteredManagers?.map((manager: any, i: any) => (
									<TableRowManagers
										manager={manager}
										index={i + 1}
										companies={companies}
										key={manager.id}
									/>
						))
							: sortedManager?.length
							? sortedManager?.map((manager: any, i: any) => (
									<TableRowManagers
										manager={manager}
										index={i + 1}
										companies={companies}
										key={manager.id}
									/>
						))
							: filteredManagers?.map((manager: any, i: any) => (
									<TableRowManagers
										manager={manager}
										index={i + 1}
										companies={companies}
										key={manager.id}
									/>
						))}
					</tbody>
				</StyledTable>
			) : (
				<>Список пуст.</>
			)}
			{/* {managers?.data.length ? (
				<StyledTable>
					<thead>
					<tr>
						<th className="checkbox">
							<CustomCheckbox
								ariaLabel="Выбрать все"
								label="Выбрать все"
								checked={isAllChecked<Manager>(managers)}
								onChange={checkboxChangeHandler}
								indeterminate={isIndeterminate<Manager>(managers)}
								isLabelMobile
							/>
						</th>

						<th><span className="medium-hidden">№ П/П</span></th>
						<th>ФИО менеджера</th>
						<th>Количество компаний</th>
						<th><span className="medium-hidden">Действия</span></th>
					</tr>
					</thead>

					<tbody>
					{managers?.data.map((manager: any, i: any) => (
						<TableRowManagers manager={manager} index={i + 1} companies={manager.companyIds} key={manager.id}/>
					))}
					</tbody>
				</StyledTable>
			) : (
				<p>Нет доступных менеджеров</p>
			)} */}
		</>
	);
}

export default TableManagers;
