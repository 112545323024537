import {Checkbox, FormControlLabel} from '@mui/material';
import React, {ChangeEvent} from 'react';
import {CheckedIcon, Icon, IndeterminateIcon} from './style';
import {muiTheme} from '../../const';

type CustomCheckboxProps = {
	ariaLabel?: string,
	label?: string,
	isLabelMobile?: boolean
	checked?: boolean,
	onChange?: (evt: ChangeEvent<HTMLInputElement>) => void,
	indeterminate?: boolean
	disabled?: boolean
}

function CustomCheckbox(props: CustomCheckboxProps): JSX.Element {
	const {ariaLabel, label, isLabelMobile, checked, onChange, indeterminate, disabled} = props;

	return (
		<FormControlLabel
			control={
				<Checkbox
					inputProps={{'aria-label': ariaLabel}}
					icon={<Icon/>}
					checkedIcon={<CheckedIcon/>}
					indeterminateIcon={<IndeterminateIcon/>}
					checked={checked}
					onChange={onChange}
					indeterminate={indeterminate}
				/>
			}
			label={label}
			sx={{
				marginRight: '0',
				'& .MuiFormControlLabel-label': {
					fontFamily: 'inherit',
					fontSize: '14px',
					fontWeight: '500',
					[muiTheme.breakpoints.up('sm')]: {
						display: isLabelMobile ? 'none' : 'block'
					}
				}
			}}
			disabled={disabled}
		/>
	);
}

export default CustomCheckbox;
