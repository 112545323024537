import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import useTitle from "../../hooks/use-title/use-title";
import {
	Account,
	AccountName,
	BurgerButton,
	FilterButton,
	HeaderContainer,
	IconButton as HeaderIconButton,
	License,
	LicenseNumber,
	LogoLink,
	StyledHeader,
} from "../../components/header/style";
import {
	AppRoute,
	ButtonVariant,
	HeaderButtonIcon,
	Resolution,
} from "../../const";
import VisuallyHidden from "../../components/styled/visually-hidden";
import { Link, useNavigate } from "react-router-dom";
import { DesktopWrapper } from "../../components/styled/desktop-wrapper";
import {
	NavigationIcon,
	NavigationLink,
	StyledNavigation,
} from "../../components/navigation/style";
import { ButtonContainer } from "../../components/styled/button-container";
import Button from "../../components/styled/button";
import Footer from "../../components/footer/footer";
import { MainWrapper } from "../../components/styled/main-wrapper";
import { Main } from "../../components/styled/main";
import { MainContainer } from "../../components/styled/main-container";
import { StyledScreenControls } from "../../components/styled/styled-screen-controls";
import { WrapControlsForm } from "../../components/styled/controls-form";
import {
	DateFakeLegend,
	DateFieldset,
} from "../../components/styled/date-fieldset";
import CustomDatePicker from "../../components/custom-date-picker/custom-date-picker";
import CustomTimePicker from "../../components/custom-time-picker/custom-time-picker";
import { IconButton } from "../../components/styled/icon-button";
import { BigStripedTable } from "../../components/styled/striped-table";
import { CustomLink } from "../../components/styled/custom-link";
import { ActionButton } from "../../components/styled/action-button";
import { Option } from "../../types/option";
import CustomAutocomplete from "../../components/custom-autocomplete/custom-autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getLicense } from "../../store/general/selectors";
import { IEmployeesState } from "../../store/employees/employees";
import axios from "axios";
import { getUserInfo } from "../../services/user-info";
import {
	ICompaniesState,
	addDocumentForCompany,
	removeDocumentFromCompany,
	setAllDocuments,
} from "../../store/companies/companies";
import CustomDialog from "../../components/custom-dialog/custom-dialog";
import { TextField } from "@mui/material";
import { toast } from "react-toastify";
import { IDocuments } from "../../types/state";
import { getCompanyDocuments } from "../../store/companies/selectors/selectors";
import { AppDispatch } from "../../store/store";
import { Loader } from "../../components/Loader/Loader";
import { updateLicense } from "../../store/general/general";
import { GreyWrapper } from "../../components/styled/grey-wrapper";
import CustomTextField from "../../components/custom-text-field/custom-text-field";
import { ControlsFlexWrapper } from "../../components/styled/controls-flex-wrapper";
import { IPropsOrder, addOrders, incrementNewRequestsCount } from "../../store/orders/orders";
import { newRequestsCountOrders } from "../../store/orders/selector";

function DocumentsCompanyScreen(): JSX.Element {
	const dispatch = useDispatch<AppDispatch>();
	const employee = useSelector(getUserInfo);
	const allDocuments = useSelector(getCompanyDocuments);
	const navigate = useNavigate();
	const userInfo = useSelector(getUserInfo);
	const [loading, setLoading] = useState(true);
	const license = useSelector(getLicense);

	const [isAcceptRemoveDocumentOpen, setIsAcceptRemoveDocumentOpen] =
		useState(false);
	const [isAddDocumentOpen, setAddDocumentOpen] = useState(false);
	const [isEditLicenseOpen, setIsEditLicenseOpen] = useState(false);
	const [newLicense, setNewLicense] = useState(license);
	const [selectedTimeFrom, setSelectedTimeFrom] = useState<any>(null);
	const [selectedTimeTo, setSelectedTimeTo] = useState<any>(null);
	const [selectedDateFrom, setSelectedDateFrom] = useState<any>(null);
	const [selectedDateTo, setSelectedDateTo] = useState<any>(null);
	const [companyId, setCompanyId] = useState<string>("");
	const [user, setUser] = useState<IEmployeesState | null>(null);
	const [file, setFile] = useState<any>(null);
	const [companies, setCompanies] = useState<ICompaniesState[] | []>([]);
	const [company, setCompany] = useState<Option | null>({
		value: "0",
		label: {
			main: "Загрузка...",
		},
	});
	const [companyToAddFile, setCompanyToAddFile] = useState<Option | null>({
		value: "",
		label: {
			main: "Список компаний",
		},
	});
	
	const newRequestsCounts = useSelector(newRequestsCountOrders);
	const [currentOrders, setCurrentOrders] = useState<IPropsOrder[]>([]);
	const [oldOrders, setOldOrders] = useState<IPropsOrder[]>([]);

	const MyClickableToast = (companyName : string) => (
		<div
			style={{ cursor: 'pointer' }}
			onClick={() => {
				navigate('/orders');
			}}
			>
			У вас новая заявка. {companyName}
		</div>
	);

	useEffect(() => {
		setLoading(true);
		const fetchOrders = async () => {
			try {
				const result = await axios.get("/spa/orders");
				setOldOrders(result.data.data)
				dispatch(addOrders(result.data.data));
				setLoading(false);

				} catch (error: any) {
				if (error.response && error.response.status === 401) {
						navigate(AppRoute.Login);
					}
				}
		}

		fetchOrders();
	}, [])

	useEffect(() => {

		const fetchOrders = async () => {
			try {
				const result = await axios.get("/spa/orders");
				setCurrentOrders(result.data.data);

				const newRequestsCount = currentOrders.length - oldOrders?.length;

				if(currentOrders && newRequestsCount > 0) {
					dispatch(incrementNewRequestsCount(newRequestsCount));
					dispatch(addOrders(result.data.data));
					setOldOrders(result.data.data);
					for (let i = 1; i <= newRequestsCount; i++) {
						toast(MyClickableToast(currentOrders[currentOrders.length - i].company_name), { type: "success", autoClose: false });
					}
				}

				} catch (error: any) {
				if (error.response && error.response.status === 401) {
						navigate(AppRoute.Login);
					}
				}
		}
		const intervalId = setInterval(fetchOrders, 10000);

		return () => {
		clearInterval(intervalId);
		};
	}, [currentOrders])

	function sortDocumentByDate(orders: IDocuments[] | null | undefined): IDocuments[] | null {
		if (orders) {
			const sortedOrderByDate = [...orders].sort((a: IDocuments, b: IDocuments) => {
			return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
		});
		return sortedOrderByDate;
		} else {
			return null;
		}
	}
	const sortedDocumentsDate = sortDocumentByDate(allDocuments);

	const handleUnauthorizedError = () => {
		navigate(AppRoute.Login);
	};

	useEffect(() => {
		async function fetchLicense() {
			const result = await axios.get("/spa/license");
			if (result.status === 401) {
				handleUnauthorizedError();
			} else {
				dispatch(updateLicense(result.data.data.license));
			}
		}
		try {
			fetchLicense();
		} catch (error: any) {
			if (error.response && error.response.status === 401) {
				navigate(AppRoute.Login);
			}
		}
	}, []);

	async function refreshLicense() {
		try {
			const result = await axios.post("/spa/license/create", {
				license: newLicense,
			});
			setNewLicense(result.data.data.license);
			dispatch(updateLicense(newLicense));
			setIsEditLicenseOpen(false);
			toast("Лицензия успешно обновлена", { type: "success" });
		} catch (error) {
			toast("Что-то пошло не так. Перезагрузите страницу", {
				type: "error",
			});
		}
	}

	useEffect(() => {
		async function fetchUser(id: number | undefined) {
			const result = await axios
				.get(`/spa/users/${id}`)
				.then((response) => {
					setUser(response.data.data);
				})
				.catch((error: any) => {
					if (error.response) {
						if (
							error.response.status === 401
						) {
							navigate(AppRoute.Login);
						}
					}
				});
		}
		if (employee) {
			fetchUser(employee?.id);
		}
	}, []);

	useEffect(() => {
		if (user) {
			user.companyIds.forEach(async (id, index) => {
				const response = await axios.get(`/spa/companies/${id}`);
				if (index === 0) {
					setCompany({
						value: String(response.data.data.id),
						label: {
							main: response.data.data.name,
						},
					});
				}
				setCompanies((prevCompanies) => [
					...prevCompanies,
					response.data.data,
				]);
			});
		}
	}, [user]);

	useEffect(() => {
		setLoading(true);
		const fetchDocuments = async () => {
			const result = await axios.get("/spa/documents", {
				params: { for: "company" },
			});
			dispatch(setAllDocuments(result.data.data));
			setLoading(false);
		};
		try {
			fetchDocuments();
		} catch (error) {
			toast("Что-то пошло не так. Перезагрузите страницу", {
				type: "error",
			});
		}
	}, []);

	function filtredDocuments(
		sortedDocumentsDate: IDocuments[] | null | undefined
	): IDocuments[] | null | undefined {
		return sortedDocumentsDate
			?.filter((item) => {
				if (selectedDateFrom !== null && selectedDateTo !== null) {
					return (
						new Date(item.created_at).getTime() >=
							new Date(selectedDateFrom).getTime() &&
						new Date(item.created_at).getTime() <=
							new Date(selectedDateTo).getTime()
					);
				} else {
					return item;
				}
			})
			.filter((item) => {
				if (selectedTimeFrom !== null && selectedTimeTo !== null) {
					const currenttime =
						new Date(item.created_at).getHours() * 60 +
						new Date(item.created_at).getMinutes();
					return (
						currenttime >=
							selectedTimeFrom.$H * 60 + selectedTimeFrom.$m &&
						currenttime <=
							selectedTimeTo.$H * 60 + selectedTimeTo.$m
					);
				} else {
					return item;
				}
			});
	}

	function editLicenseClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		refreshLicense();
	}

	const sortedDocumentsByName = (
		documents: IDocuments[] | null | undefined,
		company_id: string | undefined
	): IDocuments[] | null | undefined => {
		return documents?.filter((item) => {
			return item.owner_id === company_id;
		});
	};

	const newDocuments = sortedDocumentsByName(sortedDocumentsDate, company?.value);

	const createDate = (data: string): string => {
		if (data) {
			const arrivalDate = new Date(data);
			const day = arrivalDate.getDate();
			const month = arrivalDate.getMonth() + 1;
			const year = arrivalDate.getFullYear();

			const formattedDate = `${day < 10 ? "0" + day : day}.${
				month < 10 ? "0" + month : month
			}.${year}`;

			const hours = arrivalDate.getHours();
			const minutes = arrivalDate.getMinutes();
			const formattedTime = `${hours < 10 ? "0" + hours : hours}:${
				minutes < 10 ? "0" + minutes : minutes
			}`;

			return formattedDate + " / " + formattedTime;
		} else {
			return "";
		}
	};

	const deleteDocument = async (
		evt: MouseEvent<HTMLButtonElement>,
		documentID: string
	) => {
		evt.preventDefault();
		try {
			const result = await axios.delete(`/spa/documents/${documentID}`);
			dispatch(removeDocumentFromCompany(documentID));
			setIsAcceptRemoveDocumentOpen(false);
			toast("Документ успешно удален", { type: "success" });
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	};

	const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
		if(e.target.files) {
			const selectedFile = e.target.files[0];
			if(selectedFile && selectedFile.type === 'application/pdf') {
				setFile(selectedFile);
			} else {
				toast("Для загрузки допускаются только файлы в формате pdf", {
					type: "warning",
				});
				e.target.value = '';
			}
		}
	};

	function handleReset(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		setSelectedDateFrom(null);
		setSelectedDateTo(null);
		setSelectedTimeFrom(null);
		setSelectedTimeTo(null);
	}

	async function uploadFileHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();
		try {
			const formData = new FormData();
			formData.append("for", "company");
			formData.append("id", companyToAddFile?.value || "");
			formData.append("file", file);

			const response = await axios.post(
				"/spa/documents/upload",
				formData
			);
			dispatch(
				addDocumentForCompany({
					created_at: new Date().toISOString(),
					name: file.name,
					owner_id: companyToAddFile?.value || "",
				} as IDocuments)
			);
			toast("Документ успешно добавлен", { type: "success" });
			setAddDocumentOpen(false);
		} catch (error) {
			toast("Что-то пошло не так, перезагрузите страницу", {
				type: "error",
			});
		}
	}

	useTitle("Документы компаний | Терминал");

	return (
		<>
			<StyledHeader>
				<HeaderContainer>
					<LogoLink to={AppRoute.Main} aria-label="На главную">
						<picture>
							<source
								media={`(max-width: ${Resolution.SM})`}
								srcSet="/img/logo-mobile.svg"
							/>

							<img
								src="/img/logo.svg"
								width={193}
								height={45}
								alt='Группа компаний "Терминал"'
							/>
						</picture>
					</LogoLink>

					<License>
						Лицензия <LicenseNumber>{license}</LicenseNumber>
					</License>

					<HeaderIconButton
						type="button"
						title="Редактировать"
						icon={HeaderButtonIcon.Edit}
						marginRight="auto"
						onClick={() => setIsEditLicenseOpen(true)}
					>
						<VisuallyHidden>Редактировать</VisuallyHidden>
					</HeaderIconButton>

					<Account marginLeft="auto">
						Менеджер <AccountName>{userInfo?.name}</AccountName>
					</Account>

					<HeaderIconButton
						as={Link}
						to={AppRoute.Logout}
						icon={HeaderButtonIcon.Logout}
						title="Выйти"
					>
						<VisuallyHidden>Выйти</VisuallyHidden>
					</HeaderIconButton>

					<BurgerButton>
						<VisuallyHidden>Открыть меню</VisuallyHidden>
					</BurgerButton>

					<FilterButton>
						<VisuallyHidden>Фильтры</VisuallyHidden>
					</FilterButton>
				</HeaderContainer>
			</StyledHeader>

			<Main>
				<MainContainer>
					<DesktopWrapper>
						<StyledNavigation>
							<NavigationLink to={AppRoute.Orders}>
								<NavigationIcon
									width="20"
									height="22"
									viewBox="0 0 20 22"
								>
									<use xlinkHref="/img/sprite.svg#chalkboard-teacher"></use>
								</NavigationIcon>
								Заявки {newRequestsCounts.length > 0 && `(${newRequestsCounts.length})`}
							</NavigationLink>

							<NavigationLink to={AppRoute.Documents} $active>
								<NavigationIcon
									width="19"
									height="20"
									viewBox="0 0 19 20"
								>
									<use xlinkHref="/img/sprite.svg#download"></use>
								</NavigationIcon>
								Документы
							</NavigationLink>
						</StyledNavigation>
					</DesktopWrapper>

					<MainWrapper>
						<VisuallyHidden as="h1">
							Документы компаний
						</VisuallyHidden>

						<ButtonContainer>
							<CustomAutocomplete
								id="company"
								label="Компания"
								width="250px"
								options={companies?.map((item) => {
									return {
										value: String(item.id),
										label: {
											main: item.name,
										},
									};
								})}
								value={company}
								onChange={(evt, newValue) =>
									setCompany(newValue)
								}
							/>

							<Button
								as={Link}
								to={AppRoute.DocumentsCompany}
								variant={ButtonVariant.Main}
							>
								Документы компаний
							</Button>

							<Button
								as={Link}
								to={AppRoute.DocumentsVehicle}
								variant={ButtonVariant.Secondary}
							>
								Документы по ТС
							</Button>
						</ButtonContainer>

						<DesktopWrapper>
							<StyledScreenControls>
								<VisuallyHidden as="h2">
									Управление
								</VisuallyHidden>

								<WrapControlsForm>
									<DateFieldset>
										<VisuallyHidden as="legend">
											Дата
										</VisuallyHidden>
										<DateFakeLegend aria-hidden={true}>
											Дата
										</DateFakeLegend>

										<CustomDatePicker
											value={selectedDateFrom}
											onChange={(newValue) =>
												setSelectedDateFrom(newValue)
											}
											label="От"
											width="131px"
											order={1}
										/>

										<CustomDatePicker
											value={selectedDateTo}
											onChange={(newValue) =>
												setSelectedDateTo(newValue)
											}
											label="До"
											width="131px"
											order={3}
										/>
									</DateFieldset>

									<DateFieldset>
										<VisuallyHidden as="legend">
											Время
										</VisuallyHidden>
										<DateFakeLegend aria-hidden={true}>
											Время
										</DateFakeLegend>

										<CustomTimePicker
											value={selectedTimeFrom}
											onChange={(newValue) =>
												setSelectedTimeFrom(newValue)
											}
											label="От"
											width="91px"
											order={1}
										/>

										<CustomTimePicker
											value={selectedTimeTo}
											onChange={(newValue) =>
												setSelectedTimeTo(newValue)
											}
											label="До"
											width="91px"
											order={3}
										/>
									</DateFieldset>

									{/* <IconButton type="submit" variant={ButtonVariant.Main}>
									<svg width="24" height="24" viewBox="0 0 24 24">
										<use xlinkHref="/img/sprite.svg#check-circle"></use>
									</svg>

									<span>Применить</span>
								</IconButton> */}

									<IconButton
										onClick={handleReset}
										type="reset"
										variant={ButtonVariant.Secondary}
									>
										<svg
											width="24"
											height="24"
											viewBox="0 0 24 24"
										>
											<use xlinkHref="/img/sprite.svg#cross-circle"></use>
										</svg>

										<span>Сбросить</span>
									</IconButton>
								</WrapControlsForm>
							</StyledScreenControls>
						</DesktopWrapper>

						<BigStripedTable>
							{loading ? (
								<Loader />
							) : filtredDocuments(newDocuments)?.length ? (
								filtredDocuments(newDocuments)?.map((item) => (
									<tr key={item.id}>
										<td>
											<time
												dateTime={createDate(
													String(item.created_at)
												)}
											>
												{createDate(
													String(item.created_at)
												)}
											</time>
										</td>
										<td>
											<CustomLink
												href={item.link}
												download={item.name}
											>
												{item.name}
											</CustomLink>
										</td>
										<td>{company?.label.main}</td>

										<td>
											<ActionButton
												type="button"
												title="Удалить"
												onClick={() => {
													setCompanyId(item.id);
													setIsAcceptRemoveDocumentOpen(
														true
													);
												}}
											>
												<svg
													width="16"
													height="16"
													viewBox="0 0 22 22"
												>
													<use xlinkHref="/img/sprite.svg#remove"></use>
												</svg>
											</ActionButton>
										</td>
									</tr>
								))
							) : (
								<>Документов нет</>
							)}
						</BigStripedTable>

						<Button
							type="button"
							onClick={() => setAddDocumentOpen(true)}
							variant={ButtonVariant.Main}
							margin="24px 0 0 0"
						>
							Загрузить
						</Button>

						{/* <CustomDialog
						open={isAcceptRemoveDocumentOpen}
						onClose={() => setIsAcceptRemoveDocumentOpen(false)}
						title={`Вы хотите удалить сотрудника?`}
					>
						<Button
							type="button"
							variant={ButtonVariant.Main}
							onClick={(evt) => deleteDocument(evt, id)}
						>
							Удалить
						</Button>
					</CustomDialog> */}

						<CustomDialog
							open={isAcceptRemoveDocumentOpen}
							onClose={() => setIsAcceptRemoveDocumentOpen(false)}
							title="Вы хотите удалить документ?"
						>
							<Button
								type="button"
								variant={ButtonVariant.Main}
								onClick={(evt) =>
									deleteDocument(evt, companyId)
								}
							>
								Удалить
							</Button>
						</CustomDialog>

						<CustomDialog
							open={isAddDocumentOpen}
							onClose={() => setAddDocumentOpen(false)}
							title="Добавить документ"
						>
							<CustomAutocomplete
								id="doc-option"
								label="Документ"
								options={companies?.map((item) => {
									return {
										value: String(item.id),
										label: {
											main: item.name,
										},
									};
								})}
								value={companyToAddFile}
								width="337px"
								widthSm="100%"
								marginTop="18px"
								onChange={(evt, newValue) =>
									setCompanyToAddFile(newValue)
								}
							/>

							<TextField
								style={{ margin: "16px 0px 0px 0px" }}
								onChange={handleFileChange}
								name="upload-photo"
								type="file"
							/>
							<Button
								type="submit"
								onClick={uploadFileHandler}
								variant={ButtonVariant.Main}
								margin="24px 0 0 0"
								disabled={!companyToAddFile?.value}
							>
								Загрузить
							</Button>
						</CustomDialog>
						<CustomDialog
							open={isEditLicenseOpen}
							onClose={() => setIsEditLicenseOpen(false)}
							title="Редактирование лицензии"
						>
							<GreyWrapper as="form">
								<CustomTextField
									id="new-license"
									label="Лицензия"
									width="400px"
									value={newLicense}
									onChange={(evt) =>
										setNewLicense(evt.target.value)
									}
									autoFocus
								/>

								<ControlsFlexWrapper>
									<Button
										type="submit"
										variant={ButtonVariant.Main}
										onClick={editLicenseClickHandler}
										disabled={!newLicense}
									>
										Редактировать
									</Button>
								</ControlsFlexWrapper>
							</GreyWrapper>
						</CustomDialog>
					</MainWrapper>
				</MainContainer>
			</Main>

			<Footer />
		</>
	);
}

export default DocumentsCompanyScreen;
