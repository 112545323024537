import {useDispatch} from 'react-redux';
import {MouseEvent, useState} from 'react';
import {AppDispatch} from '../../store/store';
import {ButtonVariant, ControlButtonIcon, showOptions} from '../../const';
import {SelectChangeEvent} from '@mui/material';
import {addEmployee, resetSearchForEmployee, updateSearchByEmployeeName, updateSearchByCompany, updateSearchBySort} from '../../store/employees/employees';
import {toast} from 'react-toastify';
import VisuallyHidden from '../styled/visually-hidden';
import CustomTextField from '../custom-text-field/custom-text-field';
import CustomDialog from '../custom-dialog/custom-dialog';
import Button from '../styled/button';
import {StyledScreenControls} from '../styled/styled-screen-controls';
import {ControlsForm} from '../styled/controls-form';
import {ControlsWrapper} from '../styled/controls-wrapper';
import {ButtonControl} from '../styled/button-control';
import {IconButton} from '../styled/icon-button';
import {GreyWrapper} from '../styled/grey-wrapper';
import {ControlsFlexWrapper} from '../styled/controls-flex-wrapper';
import {FilterForm} from '../styled/filter-form';
import {InputEmployeeRoleHide} from './style'
import axios from 'axios';

type ScreenControlsEmployeesProps = {
	closeDialog?: () => void
}

function ScreenControlsEmployees({closeDialog}: ScreenControlsEmployeesProps): JSX.Element {
	const [sortBy, setSortBy] = useState('all');
	const [companyName, setCompanyName] = useState('');
	const [employee, setEmployee] = useState('');
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const role = 3;
	const dispatch = useDispatch<AppDispatch>();

	async function registerUser() {
		try {
			const response = await axios.post('/spa/users/register', { name, email, role });
			dispatch(addEmployee({
				id: response.data.data.id,
				companyIds: [],
				fullName: name,
				isBlocked: false,
				isChecked: false
			}));
			// setEmployeeToAdd(null);
			setIsDialogOpen(false);
			toast('Сотрудник успешно добавлен', {type: 'success'});
			setEmail('');
			setName('');
			if (closeDialog) {
				closeDialog();
			}
		} catch (error: any) {
			if(error.response.status === 422) {
				toast(`Такой пользователь уже существует`, {type: 'error'});
			} else {
				toast(`Ой, что-то пошло не так. Перезагрузите страницу`, {type: 'error'});
				setIsDialogOpen(false);
			}
		}
	}

	const handleSumbitEmployee =  (evt: MouseEvent<HTMLButtonElement>) => {
		evt.preventDefault();
		registerUser()
	}

	function resetClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();

		setCompanyName('');
		setEmployee('');

		dispatch(resetSearchForEmployee());
		dispatch(updateSearchBySort('all'));

		if (closeDialog) {
			closeDialog();
		}
	}

	function viewChangeHandler(evt: SelectChangeEvent) {
		dispatch(updateSearchBySort(evt.target.value));
		setSortBy(evt.target.value)
		if (closeDialog) {
			closeDialog();
		}
	}

	function submitClickHandler(evt: MouseEvent<HTMLButtonElement>) {
		evt.preventDefault();

		dispatch(updateSearchByEmployeeName(employee));
		dispatch(updateSearchByCompany(companyName));

		if (closeDialog) {
			closeDialog();
		}
	}

	return (
		<StyledScreenControls>
			<VisuallyHidden as="h2">Управление</VisuallyHidden>

			<ControlsForm>
				<CustomTextField
					id="employee"
					label="Сотрудник"
					value={employee}
					width="175px"
					widthSm="100%"
					widthMd="130px"
					marginTopSm="8px"
					onChange={(evt) => {
						setEmployee(evt.target.value);
					}}
				/>

				<CustomTextField
					id="company-name"
					label="Название компании"
					value={companyName}
					width="175px"
					widthSm="100%"
					widthMd="130px"
					onChange={(evt) => {
						setCompanyName(evt.target.value);
					}}
				/>

				<IconButton type="submit" onClick={submitClickHandler} variant={ButtonVariant.Main}>
					<svg width="24" height="24" viewBox="0 0 24 24">
						<use xlinkHref="/img/sprite.svg#check-circle"></use>
					</svg>

					<span>Применить</span>
				</IconButton>

				<IconButton type="reset" onClick={resetClickHandler} variant={ButtonVariant.Secondary}>
					<svg width="24" height="24" viewBox="0 0 24 24">
						<use xlinkHref="/img/sprite.svg#cross-circle"></use>
					</svg>

					<span>Сбросить</span>
				</IconButton>
			</ControlsForm>

			<ControlsWrapper>
				<ButtonControl icon={ControlButtonIcon.Add} onClick={() => setIsDialogOpen(true)}>
					Добавить
				</ButtonControl>

				{/* <ButtonControl icon={ControlButtonIcon.Excel} marginLeft="20px">Экспортировать в Excel</ButtonControl> */}

				<FilterForm>
					<CustomTextField
						id="view-option"
						ariaLabel="Отображение"
						select
						options={showOptions}
						value={sortBy}
						width="202px"
						widthSm="100%"
						onChange={viewChangeHandler}
					/>
				</FilterForm>
			</ControlsWrapper>

			<CustomDialog
				open={isDialogOpen}
				onClose={() => setIsDialogOpen(false)}
				title="Добавить сотрудника"
			>
				<GreyWrapper as="form">
							{/* <CustomAutocomplete
								id="employees-to-add"
								label="Сотрудник"
								options={employeeOptions}
								width="400px"
								widthSm="100%"
								value={employeeToAdd}
								onChange={(evt, newValue) => {
									setEmployeeToAdd(newValue);
									const user = employees.filter(item => {
										return	item.fullName === newValue?.label.main
									})
									setEmail(user[0].email)
								}}
							/> */}

							{/* <InputEmployeeName type='text' onInput={(e: any) => setName(e.tager.value)} placeholder='Введите имя сотрудика'/>
							<input type='text' onInput={(e: any) => setEmail(e.tager.value)} placeholder='Введите почту сотрудика'/>
							<input type='text' placeholder='Введите имя сотрудика' value={role}/> */}
							<CustomTextField
								id="inner-nameEmp"
								label="Имя сотрудика"
								value={name}
								width="275px"
								widthSm="100%"
								widthMd="130px"
								marginTopSm="8px"
								onChange={(evt) => {
									setName(evt.target.value);
								}}
							/>
							<CustomTextField
								id="inner-emailEmp"
								label="Почта сотрудника"
								value={email}
								width="275px"
								widthSm="100%"
								widthMd="130px"
								marginTop="14px"
								marginTopSm="8px"
								onChange={(evt) => {
									setEmail(evt.target.value);
								}}
							/>
							<InputEmployeeRoleHide type='number' placeholder='Введите имя сотрудика' value={role}/>
							{/* <CustomAutocomplete
								id="employees-email-to-add"
								label="Почта"
								options={[]}
								width="400px"
								widthSm="100%"
								marginTop="16px"
								value={{label: {main: `${email}`}, value: '1'}}
							/> */}

							{/* <CustomTextField
								id="email-filed"
								label="Почта"
								value={email}
								width="262px"
								widthSm="100%"
								marginTop="22px"
								marginTopSm="8px"
							/> */}

							<ControlsFlexWrapper>
								<Button
									type="submit"
									variant={ButtonVariant.Main}
									onClick={handleSumbitEmployee}
									disabled={!name && !email}
								>
									Добавить
								</Button>
								{/* <Button
									type="submit"
									variant={ButtonVariant.Main}
									onClick={addEmployeeClickHandler}
									disabled={!e}
								>
									Добавить
								</Button> */}
							</ControlsFlexWrapper>
				</GreyWrapper>
			</CustomDialog>
		</StyledScreenControls>
	);
}

export default ScreenControlsEmployees;
