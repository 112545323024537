import React from 'react';
import styled from 'styled-components';

const VisuallyHidden = styled.span`
	width: 1px;
	height: 1px;
	margin: 0;
	padding: 0;

	position: absolute;

	clip: rect(1px 1px 1px 1px);
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
	white-space: nowrap;
`;

export default VisuallyHidden;
