import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/app/app';
import {createGlobalStyle} from 'styled-components';
import {BrowserRouter} from 'react-router-dom';
import {Color, LoginStatus} from './const';
import {Provider} from 'react-redux';
import {store} from './store/store';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {changeLoginStatus, changeUserInfo} from './store/user/user';
import {getToken} from './services/token';
import {getUserInfo} from './services/user-info';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);

const GlobalStyle = createGlobalStyle`
	@font-face {
		font-family: "Montserrat";
		src: local("Montserrat Medium"),
		url("fonts/montserrat-medium.woff2") format("woff2"),
		url("fonts/montserrat-medium.woff") format("woff");
		font-weight: 500;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: "Montserrat";
		src: local("Montserrat SemiBold"),
		url("fonts/montserrat-semibold.woff2") format("woff2"),
		url("fonts/montserrat-semibold.woff") format("woff");
		font-weight: 600;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: "Montserrat";
		src: local("Montserrat ExtraBold"),
		url("fonts/montserrat-extrabold.woff2") format("woff2"),
		url("fonts/montserrat-extrabold.woff") format("woff");
		font-weight: 800;
		font-style: normal;
		font-display: swap;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	html {
		box-sizing: border-box;
	}

	body {
		margin: 0;
		min-height: 1vh;

		font-family: "Montserrat", "Arial", sans-serif;
		font-weight: 500;
		color: ${Color.DarkText};

		background-color: #FFFFFF;
	}

	#root {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
	}

	*,
	*::before,
	*::after {
		box-sizing: inherit;
	}
`;

store.dispatch(changeLoginStatus(getToken() ? LoginStatus.LoggedIn : LoginStatus.LoggedOut));
store.dispatch(changeUserInfo(getUserInfo()));

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<GlobalStyle/>
				<ToastContainer/>
				<App/>
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
);
