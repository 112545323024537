import styled from 'styled-components';
import Container from '../styled/container';
import {Color, FooterLinkIcon, Resolution} from '../../const';

type FooterLinkProps = {
	icon: FooterLinkIcon,
	order?: number
}

function handleIconType(icon: FooterLinkIcon) {
	switch (icon) {
		case FooterLinkIcon.Compass:
			return 'url(/img/icon-compass.svg)';
		case FooterLinkIcon.Phone:
			return 'url(/img/icon-phone.svg)';
		case FooterLinkIcon.Email:
			return 'url(/img/icon-email.svg)';
	}
}

export const StyledFooter = styled.footer`
	width: 100%;
	padding: 26px 0 20px;
	margin-top: auto;

	background-color: #231F20;
`;

export const FooterContainer = styled(Container)`
	display: flex;
	flex-wrap: wrap;
	gap: 65px;
	align-items: center;
	justify-content: center;

	@media (max-width: ${Resolution.SM}) {
		gap: 36px 22px;
	}
`;

export const Copyright = styled.p`
	margin: 0;

	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #FFFFFF;

	@media (max-width: ${Resolution.SM}) {
		order: 5;
	}
`;

export const FooterLink = styled.a<FooterLinkProps>`
	padding-left: 30px;

	position: relative;

	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #FFFFFF;
	text-decoration: none;

	&::before {
		width: 24px;
		height: 24px;

		position: absolute;
		top: 50%;
		left: 0;

		content: "";

		transform: translateY(-55%);
		background-image: ${({icon}) => handleIconType(icon)};
		background-repeat: no-repeat;
		background-position: center;
	}

	&:hover {
		color: ${Color.Orange};
	}

	&:active {
		opacity: 0.6;
	}

	@media (max-width: ${Resolution.SM}) {
		order: ${({order}) => order};
		padding-left: 27px;
	}
`;

export const Intrid = styled.a`
	display: block;
	padding: 0 9px 0 25px;
	height: 26px;
	border: 1px solid ${Color.Orange};

	position: relative;

	font-size: 10pt;
	font-family: Arial, sans-serif;
	line-height: 24px;
	color: #231F20;
	text-decoration: none;

	background-color: ${Color.Orange};
	border-radius: 20px;
	transition: all 200ms linear;

	&:hover {
		background-color: #231F20;
		color: ${Color.Orange};

		&::before {
			background-color: ${Color.Orange};
		}

		&::after {
			background-color: #231F20;
		}
	}

	&::before {
		width: 16px;
		height: 16px;

		position: absolute;
		top: 50%;
		left: 4px;

		content: "";

		background-color: #231F20;
		border-radius: 50%;
		transition: all 200ms linear;
		transform: translateY(-50%);
	}

	&::after {
		width: 6px;
		height: 6px;

		position: absolute;
		top: 50%;
		left: 9px;

		content: "";

		background-color: ${Color.Orange};
		border-radius: 50%;
		transition: all 200ms linear;
		transform: translateY(-50%);
	}

	@media (max-width: ${Resolution.SM}) {
		order: 4
	}
`;

export const IntridBold = styled.span`
	font-weight: 700;
`;
