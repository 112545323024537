import Header from '../../components/header/header';
import ScreenControlsManagers from '../../components/screen-controls-managers/screen-controls-managers';
import {AppRoute} from '../../const';
import Navigation from '../../components/navigation/navigation';
import {DesktopWrapper} from '../../components/styled/desktop-wrapper';
import {ScreenTitle} from '../../components/styled/screen-title';
import {Main} from '../../components/styled/main';
import {MainContainer} from '../../components/styled/main-container';
import {MainWrapper} from '../../components/styled/main-wrapper';
import TableManagers from '../../components/table-managers/table-managers';
import Footer from '../../components/footer/footer';
import useTitle from '../../hooks/use-title/use-title';

function ManagersScreen(): JSX.Element {
	useTitle('Менеджеры | Терминал');
	
	return <>
		<Header ScreenControls={ScreenControlsManagers} navigation={<Navigation currentRoute={AppRoute.Managers}/>}/>

		<Main>
			<MainContainer>
				<DesktopWrapper>
					<Navigation currentRoute={AppRoute.Managers}/>
				</DesktopWrapper>

				<MainWrapper>
					<ScreenTitle>Менеджеры</ScreenTitle>

					<DesktopWrapper>
						<ScreenControlsManagers/>
					</DesktopWrapper>

					<TableManagers/>
				</MainWrapper>
			</MainContainer>
		</Main>

		<Footer/>
	</>
}

export default ManagersScreen;
