import styled from "styled-components";

export const MainGrid = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 15px;
	min-height: 700px;
	padding-bottom: 555px;
	position: relative;

	flex-wrap: wrap;
    align-content: flex-start;
`;

export const MarginMainGrid = styled(MainGrid)`
	margin-top: 24px;
`;

export const MainGridColumn = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 15px;
`;

// export const MainGridColumn = styled.div`
// 	display: flex;
// 	flex-wrap: wrap;
// 	gap: 15px;
// `;

// export const MainGridColumn = styled.div`
// 	width: calc(100% / 3 - 34px);

// 	@media (max-width: ${Resolution.LG}) {
// 		width: calc(100% / 2 - 25px);
// 	}

// 	@media (max-width: ${Resolution.MD}) {
// 		width: 100%;
// 	}
// `;
